import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {RoleDTO, RolesApi} from "../../typescript-fetch-client-generated";
import {useHistory} from "react-router-dom";
import {genericErrorCallback} from "../util/util";

const useStyles = defaultStyles();

let roleControllerAPI = new RolesApi(config);

export default function RolesTable() {
    const classes = useStyles();

    const [rows, setRows] = React.useState<Array<RoleDTO>>(new Array<RoleDTO>())
    const [isLoading, setIsLoading] = React.useState(true);
    const history = useHistory();

    React.useEffect(fetchData, [])

    function fetchData() {
        roleControllerAPI.getAllRoles().then(
            r => {
                setRows(r);
                setIsLoading(false);
            }
        ).catch(genericErrorCallback)
    }


    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <TableContainer
                    component={Paper}
                >
                    <Table aria-label="simple table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead}>Id</TableCell>
                                <TableCell className={classes.tableHead}>Descrição</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map(row => (
                                <TableRow onClick={() => history.push(`/roles/${row.id}/`)} key={row.id}
                                          className={classes.clickable}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}
