import React from 'react';
import {defaultStyles} from '../../styles';
import logoLight from '../../Logos/logo_nova_light.png';
import {Grid, Hidden, Typography} from '@material-ui/core';
import {Link} from "react-router-dom";
import {useTheme} from "@material-ui/core/styles";
import logoDark from "../../Logos/logo_nova_dark.png";

const useStyles = defaultStyles();


export default function Header() {
    const classes = useStyles();

    return <div>
        <Grid container>
            <Hidden xsDown>
                <Grid item xs={4} lg={1}><img src={useTheme().palette.type === 'dark' ? logoDark : logoLight}
                                              alt={"FCT logo"} className={classes.logo}/></Grid>
                <Grid item xs={8} lg={10}>
                    <Link to={"/"} className={classes.undercoverLink}>
                        <Typography className={classes.mainTitle}>
                            Plataforma de Vigilâncias
                        </Typography>
                    </Link>
                </Grid>
            </Hidden>
        </Grid>
    </div>;
}






































