import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {defaultStyles} from "../../styles";
import {BackSubmitButtons} from "../util/Buttons";
import {ChangePasswordDTO, UsersApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";
import {PageContext} from "../contexts/PageContext";

const useStyles = defaultStyles();


export default function ChangePasswordPage() {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const usersAPI = new UsersApi(config);

    const [oldPass, setOldPass] = React.useState(String);
    const [newPass1, setNewPass1] = React.useState(String);
    const [newPass2, setNewPass2] = React.useState(String);
    const [passDoesNotMatch, setPassDoesNotMatch] = React.useState(false);
    const [oldPassInvalid, setOldPassInvalid] = React.useState(false);
    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(updateContext);

    function updateContext(){
        pageContext[1]("Mudar de Password");
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (!validate()) return;

        const data: ChangePasswordDTO = {
            oldPassword: oldPass,
            newPassword: newPass1
        }

        usersAPI.changePassword(data)
            .then(_ => successCallback())
            .catch(e => {
                if (e.status === 409) {
                    setOldPassInvalid(true);
                } else {
                    errorCallback(e);
                }

            });

    }

    function successCallback() {
        resetForm();
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    function validate() {
        setOldPassInvalid(false);

        if (newPass1 === newPass2) {
            setPassDoesNotMatch(false);
            return true;
        } else {
            setPassDoesNotMatch(true);
            return false;
        }
    }

    function resetForm() {
        setNewPass1("");
        setNewPass2("");
        setOldPass("");
    }

    return (
        <div className={classes.paper}>
            <Typography>
                Altera a password local ao sistema. Se não tem password local, pode obter uma selecionando o botão
                "Esqueceu-se da sua password?" na página de login.
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>

                <TextField
                    variant="outlined"
                    margin="normal"
                    value={oldPass}
                    required
                    fullWidth
                    label="Password atual"
                    type="password"
                    autoComplete="off"
                    onChange={e => setOldPass(e.target.value)}
                />
                <Typography className={classes.errorMessage}>{oldPassInvalid ? "Invalid password" : ""}</Typography>

                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Nova password"
                    type="password"
                    autoComplete="off"
                    onChange={e => setNewPass1(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Nova password"
                    type="password"
                    autoComplete="off"
                    onChange={e => setNewPass2(e.target.value)}
                />
                <Typography
                    className={classes.errorMessage}>{passDoesNotMatch ? "Passwords do not match" : ""}</Typography>

                <BackSubmitButtons/>

            </form>
        </div>
    );
}
