import React, {useState} from 'react';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import VigilanceDetailedCardComponent from "./components/vigilances/VigilanceDetailedCard";
import Home from "./components/home/Home";
import EvaluationDetailedCard from "./components/evaluations/EvaluationDetailedCard";
import EvaluationsPage from "./components/evaluations/EvaluationsPage";
import NewEvaluationComponent from "./components/evaluations/NewEvaluationComponent";
import ProfilePage from "./components/users/ProfilePage";
import LoginPage from "./components/auth/LoginPage";
import RolesPage from "./components/roles/RolesPage";
import RoleDetailedCard from "./components/roles/RoleDetailedCard";
import CapabilitiesPage from "./components/roles/CapabilitiesPage";
import Nav from "./components/navbar/Nav";
import DepartmentsPage from "./components/departments/DepartmentsPage";
import RejectVigilanceForm from "./components/vigilances/RejectVigilanceForm";
import SwapVigilanceForm from "./components/vigilances/SwapVigilanceForm";
import SubjectsPage from "./components/subjects/SubjectsPage";
import MyVigilancesPage from "./components/vigilances/MyVigilancesPage";
import DepartmentScoreTable from "./components/departments/DepartmentScoreTable";
import DepartmentVigilancesPage from "./components/vigilances/DepartmentVigilancesPage";
import MyProfilePage from "./components/users/MyProfilePage";
import RejectionCardList from "./components/vigilances/RejectionCardList";
import AddUserPage from "./components/departments/AddUserPage";
import RejectionDetailedCard from "./components/vigilances/RejectionDetailedCard";
import AdminPage from "./components/admin/AdminPage";
import AddSubjectPage from "./components/departments/AddSubjectPage";
import UsersPage from "./components/users/UsersPage";
import ChangePasswordPage from "./components/users/ChangePasswordPage";
import ResetPasswordPage from "./components/auth/ResetPasswordPage";
import ChangePasswordAfterReset from "./components/auth/ChangePasswordAfterResetPage";
import {GlobalApi, UserExtendedDTO, UsersApi, YearSemesterDTO} from "./typescript-fetch-client-generated";
import {config} from "./configuration";
import {CssBaseline, Divider, LinearProgress, Paper} from "@material-ui/core";
import {SnackbarProvider} from "notistack";
import Footer from "./components/footer/Footer";
import AddDepartmentPage from "./components/departments/AddDepartmentPage";
import SubjectDetailedCard from "./components/subjects/SubjectDetailedCard";
import {AppContextProvider} from "./components/contexts/AppContext";
import Header from "./components/header/Header";
import OldSemestersPage from "./components/old_semesters/OldSemestersPage";
import DepartmentLogsPage from "./components/logs/DepartmentLogsPage";
import AllLogsPage from "./components/logs/AllLogsPage";
import {PageContextProvider} from "./components/contexts/PageContext";
import SettingsPage from "./components/options/SettingsPage";
import CustomThemeProvider from "./components/util/CustomThemeProvider";
import {defaultStyles} from "./styles";
import {NotifyUpdateContextProvider} from "./components/contexts/NotifyUpdateContext";
import DepartmentDetail from "./components/departments/DepartmentDetail";
import {QUERY_PARAM_FROM} from "./components/util/util";
import MyEvaluationsPage from "./components/evaluations/MyEvaluationsPage";
import MySubjectsPage from "./components/subjects/MySubjectsPage";
import HelpPage from "./components/help/HelpPage";

const useStyles = defaultStyles();

export default function App() {
    const classes = useStyles();

    const [loggedIn, setLoggedIn] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [currentYearSemester, setCurrentYearSemester] = useState<YearSemesterDTO>({});
    const [me, setMe] = useState<UserExtendedDTO>({});
    const usersAPI = new UsersApi(config);
    const globalAPI = new GlobalApi(config);

    React.useEffect(fetchData, []);

    function fetchData() {
        Promise.all([usersAPI.getMeUser(), globalAPI.getYearAndSemester()])
            .then(results => {
                const [user, yearSemester] = results;
                setMe(user);
                setCurrentYearSemester(yearSemester);
                setLoggedIn(true);
            })
            .catch(_ => setLoggedIn(false))
            .finally(() => setLoading(false));
    }

    /**
     * Saves path as a query parameter and pushes a new
     * @param props
     * @constructor
     */
    const RedirectHelper = (props: any) => {
        const {history} = props;
        let path = history.location.pathname;
        let location = "/login";
        if (path !== "/") {
            location += `?${QUERY_PARAM_FROM}=${path}`;
        }

        window.location.href = location;
        return null;
    }

    return (
        <CustomThemeProvider>
            <CssBaseline/>
            <SnackbarProvider maxSnack={3}>
                {loading ? <LinearProgress/> :
                    <div>
                        <AppContextProvider currentYearSemester={currentYearSemester} me={me}>
                            <PageContextProvider initialState={"Home"}>
                                <NotifyUpdateContextProvider>
                                    <BrowserRouter>
                                        {loggedIn ?
                                            <Nav page={
                                                <div>
                                                    <div className={classes.body}>
                                                        <Switch>
                                                            <Route exact path="/" component={Home}/>
                                                            <Route exact path="/me" component={MyProfilePage}/>
                                                            <Route exact path="/users/:user_id"
                                                                   component={ProfilePage}/>
                                                            <Route exact path="/departments"
                                                                   component={DepartmentsPage}/>
                                                            <Route exact path="/departments/addDepartment"
                                                                   component={AddDepartmentPage}/>
                                                            <Route exact path="/departments/:department_id"
                                                                   component={DepartmentDetail}/>
                                                            <Route exact path="/departments/:department_id/users"
                                                                   component={UsersPage}/>
                                                            <Route exact path="/departments/:department_id/addUser"
                                                                   component={AddUserPage}/>
                                                            <Route exact path="/departments/:department_id/addSubject"
                                                                   component={AddSubjectPage}/>
                                                            <Route exact path="/my_vigilances"
                                                                   component={MyVigilancesPage}/>
                                                            <Route exact path="/departments/:department_id/vigilances"
                                                                   component={DepartmentVigilancesPage}/>
                                                            <Route exact path="/vigilances/:vigilance_id"
                                                                   component={VigilanceDetailedCardComponent}/>

                                                            <Route exact path="/departments/:department_id/evaluations"
                                                                   component={EvaluationsPage}/>
                                                            {/*<Route exact path="/all_evaluations" component={AllEvaluationsPage}/>*/}
                                                            <Route exact path="/my_evaluations"
                                                                   component={MyEvaluationsPage}/>
                                                            <Route exact path="/evaluations/:evaluation_id"
                                                                   component={EvaluationDetailedCard}/>

                                                            <Route exact path="/roles" component={RolesPage}/>
                                                            <Route exact path="/roles/:role_id"
                                                                   component={RoleDetailedCard}/>
                                                            <Route exact path="/capabilities"
                                                                   component={CapabilitiesPage}/>

                                                            <Route exact path="/departments/:department_id/subjects"
                                                                   component={SubjectsPage}/>
                                                            <Route exact path="/my_subjects"
                                                                   component={MySubjectsPage}/>
                                                            {/*<Route exact path="/all_subjects" component={AllSubjectsPage}/>*/}

                                                            <Route exact path="/subjects/:subject_id"
                                                                   component={SubjectDetailedCard}/>

                                                            <Route exact path="/editions/:edition_id/addEvaluation"
                                                                   component={NewEvaluationComponent}/>

                                                            <Route exact path="/vigilances/:vigilance_id/reject"
                                                                   component={RejectVigilanceForm}/>
                                                            <Route exact path="/vigilances/:vigilance_id/swap"
                                                                   component={SwapVigilanceForm}/>
                                                            <Route exact path="/departments/:department_id/scores"
                                                                   component={DepartmentScoreTable}/>
                                                            <Route exact path="/unconfirmed"
                                                                   component={RejectionCardList}/>
                                                            <Route exact path="/unconfirmed/:vigilance_id"
                                                                   component={RejectionDetailedCard}/>
                                                            <Route exact path="/admin" component={AdminPage}/>
                                                            <Route exact path="/changePassword"
                                                                   component={ChangePasswordPage}/>
                                                            {/*<Route exact path="/editProfile"*/}
                                                            {/*       component={EditProfileInfo}/>*/}
                                                            <Route exact path="/old_semesters"
                                                                   component={OldSemestersPage}/>
                                                            <Route exact path="/logs" component={AllLogsPage}/>
                                                            <Route exact path="/dep_logs"
                                                                   component={DepartmentLogsPage}/>
                                                            <Route exact path="/settings" component={SettingsPage}/>
                                                            <Route exact path="/help" component={HelpPage}/>

                                                            {/* Default with login  */}
                                                            <Redirect to={"/"}/>
                                                        </Switch>
                                                    </div>
                                                    <Footer/>
                                                </div>
                                            }/>
                                            :
                                            <CustomThemeProvider>
                                                <Paper className={classes.body}>
                                                    <Header/>
                                                    <Divider/>
                                                    <Switch>
                                                        <Route exact path="/resetPassword"
                                                               component={ResetPasswordPage}/>
                                                        <Route exact path="/login" component={LoginPage}/>
                                                        <Route exact path="/changePasswordAfterReset"
                                                               component={ChangePasswordAfterReset}/>

                                                        {/* Default with no login */}
                                                        <Route component={RedirectHelper}/>
                                                    </Switch>
                                                </Paper>
                                            </CustomThemeProvider>
                                        }

                                    </BrowserRouter>
                                </NotifyUpdateContextProvider>
                            </PageContextProvider>
                        </AppContextProvider>

                    </div>
                }

                </SnackbarProvider>

            </CustomThemeProvider>
    );
}

