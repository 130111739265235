import React from 'react';
import {defaultStyles} from "../../styles";
import {PageContext} from "../contexts/PageContext";


const useStyles = defaultStyles();


export default function HelpPage() {
    const classes = useStyles();
    const pageContext = React.useContext(PageContext) as Array<any>;
    React.useEffect(initialSetup, []);

    function initialSetup() {
        pageContext[1]("Ajuda");
    }

    return (
        <div>

<p>Nota: prazos medidos em horas.</p>

<h2 id="criar-avalia-o">Criar Avaliação</h2>
<ol>
<li>Na página de uma unidade curricular, carregar em <strong>CRIAR NOVA AVALIAÇÃO</strong>;</li>
<li>Escolher data, hora, e duração, em minutos;</li>
<li>Clicar em submeter.</li>
</ol>
<h4 id="consequ-ncias-">Consequências:</h4>
<ul>
<li>Email agendado para lembrar o regente, 16 dias  
antes da data avaliação, com informação sobre o estado da avaliação.</li>
</ul>

<h2 id="pedir-vigilantes">Pedir vigilantes</h2>
<ol>
<li>Na página de uma avaliação, carregar em <strong>PEDIR VIGILANTES</strong>;</li>
<li>Indicar a quantidade de vigilantes que pretende acrescentar;</li>
<li>Clicar em submeter.</li>
</ol>
<h4 id="restri-es-">Restrições:</h4>
<ul>
<li>Só os regentes podem pedir vigilantes;</li>
<li>Faltam pelo menos 14 dias até à avaliação;</li>
</ul>
<h4 id="consequ-ncias">Consequências</h4>
<ul>
<li>O sistema cria convocatórias no estado <strong>PENDENTE</strong>;</li>
<li>Os docentes convocados recebem uma convocatória por email;</li>
<li>Os docentes convocados recebem pontos;</li>
</ul>

<h2 id="cancelar-vigil-ncias">Cancelar vigilâncias</h2>
<ol>
<li>Na página de uma avaliação, carregar em <strong>CANCELAR VIGILANTES</strong>;</li>
<li>Indicar a quantidade de vigilantes que pretende cancelar;</li>
<li>Clicar em submeter.</li>
</ol>
<h4 id="restri-es">Restrições</h4>
<ul>
<li>Só os regentes podem cancelar vigilantes;</li>
<li>Faltam mais de 48 horas até à avaliação.</li>
</ul>
<h4 id="consequ-ncias">Consequências</h4>
<ul>
<li>O número de convocatórias indicado é cancelado, desconvocando primeiro quem tem mais pontos;</li>
<li>As convocatórias canceladas passam a ter estado <strong>CANCELADO</strong>;</li>
<li>Os docentes desconvocados recebem um email com a informação.</li>
</ul>

<h2 id="aceitar-uma-convocat-ria">Aceitar uma convocatória</h2>
<ol>
<li>Na página inicial, coluna <strong>Próximas vigilâncias</strong>, clicar na convocatória;</li>
<li>Clicar no botão <strong>ACEITAR</strong>;</li>
<li>Confirmar a operação.</li>
</ol>

<h2 id="transferir-uma-convocat-ria">Transferir uma convocatória</h2>
<ol>
<li>Na página inicial, coluna <strong>Próximas vigilâncias</strong>, clicar na convocatória;</li>
<li>Clicar no botão <strong>TRANSFERIR</strong>.</li>
<li>Selecionar o docente que o substitui;</li>
<li>Confirmar a operação.</li>
</ol>
<h4 id="restri-es">Restrições</h4>
<ul>
<li>Se for uma convocatória recebida por transferência, não pode estar ainda PENDENTE;</li>
<li>Têm de faltar mais de 24 horas até à avaliação.</li>
</ul>
<h4 id="consequ-ncias">Consequências</h4>
<ul>
<li>Os pontos da vigilância são subtraídos;</li>
<li>A convocatória passa a ter estado <strong>TRANSFERIDO</strong>;</li>
<li>São atribuídos pontos ao docente substituto;</li>
<li>É enviado uma convocatória por email ao docente substituto;</li>
<li>Se o docente substituto não aceitar no prazo de 48h ou até 12 horas antes da avaliação (o que ocorrer primeiro), a transferência é rejeitada automaticamente e volta ao docente original.</li>
</ul>

<h2 id="rejeitar-uma-convocat-ria">Rejeitar uma convocatória</h2>
<ol>
<li>Na página inicial, coluna <strong>Próximas vigilâncias</strong>, clicar no botão <strong>REJEITAR</strong>.</li>
</ol>
<p>Se não for uma convocatória <strong>transferida</strong>, vai ser redirecionado para um formulário de pedido de rejeição:</p>
<ol>
<li>Selecionar o motivo do pedido de rejeição na lista;</li>
<li>Quando for um motivo previsto no nº2 Artº 6º do RVE é necessário escrever uma justificação que permita confirmar a situação;</li>
<li>Clicar em <strong>SUBMETER</strong>.</li>
</ol>
<h4 id="restri-es">Restrições</h4>
<ul>
<li>Tem de estar no período de 48h para responder e a convocatória no estado <strong>PENDENTE</strong> ou <strong>ACEITE</strong>;</li>
<li>Se o pedido de rejeição for pessoal ou outro, de acordo com nº3 Artº 6º do RVE, este será logo aceite se ainda não tiver atingido o limite de 3 rejeições no semestre.</li>
</ul>
<h4 id="consequ-ncias">Consequências</h4>
<p>Se for uma convocatória <strong>normal</strong>:</p>
<ul>
<li>O sistema convoca automaticamente um docente substituto se este tiver tempo para responder. Senão, é enviado um aviso por email para a Secretaria para continuar o processo;</li>
<li>Os pontos da vigilância são subtraídos;</li>
<li>Se o pedido é pessoal ou outro não justificado, é aplicada a penalização prevista no RVE (30%, 50% ou 70% dos pontos da vigilância).</li>
</ul>
<p>Se for uma convocatória <strong>transferida</strong>:</p>
<ul>
<li>A convocatória da vigilância volta ao docente original;</li>
<li>O docente da convocatória original recebe um email a notificar que a transferência pedida foi rejeitada.</li>
</ul>

<h2 id="marcar-presen-as-dos-vigilantes">Marcar presenças dos vigilantes</h2>
<ol>
<li>Na página da avaliação, escolher os vigilantes que compareceram;</li>
<li>Clicar em submeter;</li>
<li>Confirmar as presenças.</li>
</ol>
<h4 id="restri-es">Restrições</h4>
<ul>
<li>A data da avaliação já tem de ter passado.</li>
</ul>
            
        </div>
    );
}
