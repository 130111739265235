import React from 'react';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    DepartmentDTO,
    DepartmentsApi,
    LecturersApi,
    RoleDTO,
    RolesApi,
    SignUpLecturerDTO,
    SignUpUserDTO,
    UserDTO,
    UsersApi
} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";

import {Backdrop, Button, Checkbox, FormControlLabel, Grid, TextField} from '@material-ui/core';
import {useHistory} from "react-router-dom";
import {genericErrorCallback, getErrorMessage, isPrimaryRole, Roles} from "../util/util";
import {useSnackbar} from "notistack";
import {Autocomplete} from "@material-ui/lab";
import {BackButton, BasicButton} from "../util/Buttons";
import {PageContext} from "../contexts/PageContext";
import {AppContext} from "../contexts/AppContext";

const useStyles = defaultStyles();

export default function AddUserPage(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();

    const departmentAPI = new DepartmentsApi(config);
    const lecturersAPI = new LecturersApi(config);
    const usersAPI = new UsersApi(config);
    const rolesAPI = new RolesApi(config);

    const loggedUserDepartmentId = parseInt(props.match.params.department_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [allAvailableRoles, setAllAvailableRoles] = React.useState<Array<RoleDTO>>([]);
    const [availablePrimaryRoles, setAvailablePrimaryRoles] = React.useState<Array<RoleDTO>>([]);
    const [availableSecondaryRoles, setAvailableSecondaryRoles] = React.useState<Array<RoleDTO>>([]);
    const [availableDepartments, setAvailableDepartments] = React.useState<Array<DepartmentDTO>>([]);

    const [name, setName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState<string | undefined>(undefined);
    const [departmentId, setDepartmentId] = React.useState(-1);
    const [primaryRoleId, setPrimaryRoleId] = React.useState<string>("");
    const [secondaryRoleIds, setSecondaryRoleIds] = React.useState<Array<string>>([]);
    const [vigilantStatus, setVigilantStatus] = React.useState(false);
    const [passwordStatus, setPasswordStatus] = React.useState(false);
    const [errorMessages, setErrorMessages] = React.useState({name: "", email: "", password: "", primaryRoles: ""});
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const pageContext = React.useContext(PageContext) as Array<any>;
    const appContext = React.useContext(AppContext);


    React.useEffect(fetchData, []);
    React.useEffect(updateAvailableSecondaryRoles, [primaryRoleId]);

    function updateAvailableSecondaryRoles() {
        setAvailableSecondaryRoles(allAvailableRoles.filter(x => x.id !== primaryRoleId));
    }

    React.useEffect(updateAvailableSecondaryRoles, [primaryRoleId]);

    function fetchData() {
        Promise.all([departmentAPI.getAllDepartments(), rolesAPI.getAllRoles()])
            .then(
                values => {
                    const [ds, r] = values;

                    setDepartmentId(loggedUserDepartmentId);
                    setAvailableDepartments(ds);

                    setAllAvailableRoles(r);
                    setAvailablePrimaryRoles(r.filter(x => isPrimaryRole(x)));
                    updateAvailableSecondaryRoles();

                    setIsLoading(false);
                }).catch(genericErrorCallback)
        pageContext[1]("Criar Utilizador");
    }

    function successCallback(userDTO: UserDTO) {
        resetForm();
        snackbar.enqueueSnackbar("Utilizador criado com sucesso.",
            {
                variant: "success",
                action: _ =>
                    <Button onClick={() => history.push(`/users/${userDTO.id}`)}>
                        Ver
                    </Button>
            })
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    function isLecturer() {
        return (primaryRoleId && primaryRoleId === Roles.ROLE_LECTURER) || (secondaryRoleIds && secondaryRoleIds.includes(Roles.ROLE_LECTURER));
    }

    function resetForm() {
        setName("");
        setEmail("");
        setDepartmentId(appContext.me.value.department?.id!!);
        setPrimaryRoleId("");
        setSecondaryRoleIds([]);
        setPassword("");
        setPasswordStatus(false);
        setVigilantStatus(false);
        setErrorMessages({name: "", email: "", password: "", primaryRoles: ""})
    }

    function submit() {
        if (!validate()) return
        setOpenBackdrop(true)

        if (isLecturer()) {
            const data: SignUpLecturerDTO = {
                name: name,
                email: email,
                department: departmentId,
                vigilantStatus: vigilantStatus,
                roles: secondaryRoleIds.concat([primaryRoleId]),
                password: password
            }
            lecturersAPI.addNewLecturer(data)
                .then(successCallback)
                .catch(errorCallback)
                .finally(() => setOpenBackdrop(false));


        } else {
            const data: SignUpUserDTO = {
                name: name,
                email: email,
                department: departmentId,
                roles: secondaryRoleIds.concat([primaryRoleId]),
                password: password
            }
            usersAPI.addNewUser(data)
                .then(successCallback)
                .catch(errorCallback)
                .finally(() => setOpenBackdrop(false));

        }

    }

    function canSelectDepartment() {
        return appContext.me.value.roles?.includes(Roles.ROLE_SYSTEM_ADMIN);
    }

    function validate() {
        let valid = true;
        let nameMessage = "";
        let emailMessage = "";
        let passwordMessage = "";
        let primaryRoleMessage = "";

        if (!name || name === "") {
            nameMessage = "Por favor insira um nome";
            valid = false;
        }

        if (!email || email === "") {
            emailMessage = "Por favor insira um email";
            valid = false;
        }

        if (passwordStatus && (!password)) {
            passwordMessage = "Por favor insira uma password";
            valid = false;
        }

        if (passwordStatus && (password && password.length < 4)) {
            passwordMessage = "Password tem de ter pelo menos 4 caracteres";
            valid = false;
        }


        if (!departmentId || departmentId === -1) {
            emailMessage = "Por favor escolha um departamento";
            valid = false;
        }

        if (!primaryRoleId || primaryRoleId === "") {
            primaryRoleMessage = "Por favor atribua um role";
            valid = false;
        }

        setErrorMessages({
            name: nameMessage, email: emailMessage, password: passwordMessage, primaryRoles: primaryRoleMessage
        });
        return valid;
    }

    function handleRolesChange(event: React.ChangeEvent<{}>, values: Array<RoleDTO>) {
        setSecondaryRoleIds(values.map(r => r.id!!));
    }

    return (
        <div>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>
                    <form className={classes.form} noValidate>
                        <TextField
                            autoComplete="off"
                            variant="outlined"
                            margin="normal"
                            required
                            label="Nome"
                            autoFocus
                            value={name}
                            onChange={e => setName(e.target.value)}
                            className={classes.textFieldSpaced}
                        />
                        {errorMessages["name"] === "" ? null :
                            <Typography className={classes.errorMessage}>{errorMessages["name"]}</Typography>}

                        <TextField
                            variant="outlined"
                            required
                            label="Email"
                            autoComplete="off"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className={classes.textFieldSpaced}
                        />
                        {errorMessages["email"] === "" ? null :
                            <Typography className={classes.errorMessage}>{errorMessages["email"]}</Typography>}

                        {canSelectDepartment() ?
                            <TextField
                                select
                                fullWidth
                                onChange={e => setDepartmentId(parseInt(e.target.value, 10))}
                                SelectProps={{
                                    native: true,
                                }}
                                label="Departamento"
                                variant="outlined"
                                required
                                value={departmentId}
                                className={classes.textFieldSpaced}
                            >
                                <option/>
                                {
                                    availableDepartments.map(d =>
                                        <option key={"department" + d.id} value={d.id}>
                                            {d.name}
                                        </option>
                                    )
                                }
                            </TextField> : null
                        }
                        <TextField
                            select
                            fullWidth
                            onChange={e => setPrimaryRoleId(e.target.value)}
                            SelectProps={{
                                native: true,
                            }}
                            label="Role"
                            variant="outlined"
                            required
                            value={primaryRoleId}
                            className={classes.textFieldSpaced}
                        >
                            <option/>
                            {
                                availablePrimaryRoles.map(r =>
                                    <option key={"role" + r.id} value={r.id}>
                                        {r.description}
                                    </option>
                                )
                            }
                        </TextField>

                        {errorMessages["primaryRoles"] === "" ? null :
                            <Typography className={classes.errorMessage}>{errorMessages["primaryRoles"]}</Typography>}

                        <Autocomplete
                            multiple
                            size={"medium"}
                            options={availableSecondaryRoles}
                            getOptionLabel={(option: RoleDTO) => option.description!!}
                            disableCloseOnSelect
                            onChange={handleRolesChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Roles extra"
                                />
                            )}
                            className={classes.textFieldSpaced}
                        />

                        <Grid container direction={"column"}>
                            <Grid item>
                                <FormControlLabel
                                    control={<Checkbox
                                        onChange={e => {
                                            setPasswordStatus(e.target.checked);
                                            errorMessages.password = ""
                                            setErrorMessages(errorMessages)
                                        }}
                                        checked={passwordStatus}
                                    />}
                                    label="Definir password"
                                />
                            </Grid>
                            <Grid item>


                                {passwordStatus ?

                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        label="Password"
                                        type="password"
                                        autoComplete="off"
                                        className={classes.textFieldSpaced}
                                        onChange={e => setPassword(e.target.value)}
                                    /> : null
                                }
                                {errorMessages["password"] === "" ? null :
                                    <Typography
                                        className={classes.errorMessage}>{errorMessages["password"]}</Typography>}
                            </Grid>
                            <Grid item>
                                {isLecturer() ?
                                    <FormControlLabel
                                        control={<Checkbox
                                            onChange={e => setVigilantStatus(e.target.checked)}
                                            checked={vigilantStatus}
                                        />}
                                        label="Docente ativo (poderá ser já convocado)"
                                    /> : null
                                }
                            </Grid>
                        </Grid>

                        <Grid container direction={"row"} spacing={1}>

                            <Grid item>
                                <BackButton/>
                            </Grid>

                            <Grid item>
                                <BasicButton
                                    onClick={submit}
                                >
                                    Criar
                                </BasicButton>
                            </Grid>

                        </Grid>

                    </form>

                    <Backdrop className={classes.backdrop} open={openBackdrop}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </div>
            )}
        </div>
    );
}
