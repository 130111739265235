import React from 'react';
import {useHistory} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from "@material-ui/core/CircularProgress";
import {CustomLink, DTYPE, genericErrorCallback, renderDtype, Scopes, vigilanceRequestSorter} from "../util/util";

import {
    VigilanceRequestExtendedDTO,
    VigilanceRequestsApi,
    YearSemesterDTO
} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from '../../styles';
import DatetimeComponent from "../util/DatetimeComponents";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {IconButton} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {AppContext} from "../contexts/AppContext";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";


const useStyles = defaultStyles();

let vigilancesAPI: VigilanceRequestsApi = new VigilanceRequestsApi(config);

export default function VigilancesTable(props: { evaluationId?: number, scope: number, yearSemester?: YearSemesterDTO, compact: boolean, departmentId?: number }) {

    const classes = useStyles();
    const history = useHistory();

    const [rows, setRows] = React.useState<Array<VigilanceRequestExtendedDTO>>(new Array<VigilanceRequestExtendedDTO>());
    const [compactRows, setCompactRows] = React.useState<Array<VigilanceRequestExtendedDTO>>(new Array<VigilanceRequestExtendedDTO>());
    const [isLoading, setIsLoading] = React.useState(true);
    const [expanded, setExpanded] = React.useState(false);

    const appContext = React.useContext(AppContext);
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    React.useEffect(fetchTableRows, [appContext.me.value, props.evaluationId, props.scope, props.yearSemester, notifyUpdateContext.state])
    React.useEffect(updateCompactRows, [rows])

    function updateCompactRows() {
        setCompactRows(filterCompact(rows));
    }


    function fetchTableRows() {
        const me = appContext.me.value;

        if (props.evaluationId) {
            if (props.yearSemester) {
                vigilancesAPI.getAllVigilanceRequestsExtendedForEdition(props.yearSemester.year!!, props.yearSemester.semester!!, undefined, props.evaluationId).then(
                    r => {
                        setRows(r);
                        setIsLoading(false);
                    }
                ).catch(genericErrorCallback);
            } else {
                vigilancesAPI.getAllVigilanceRequestsExtended(undefined, props.evaluationId).then(
                    r => {
                        setRows(r);
                        setIsLoading(false);
                    }
                ).catch(genericErrorCallback);
            }
        } else {
            switch (props.scope) {
                case Scopes.ALL:
                    if (props.yearSemester) {
                        vigilancesAPI.getAllVigilanceRequestsExtendedForEdition(props.yearSemester.year!!, props.yearSemester.semester!!).then(
                            r => {
                                setRows(r.sort(vigilanceRequestSorter));
                                setIsLoading(false);
                            }
                        ).catch(genericErrorCallback);
                    } else {
                        vigilancesAPI.getAllVigilanceRequestsExtended().then(
                            r => {
                                setRows(r.sort(vigilanceRequestSorter));
                                setIsLoading(false);
                            }
                        ).catch(genericErrorCallback);
                    }
                    break;
                case Scopes.DEPARTMENT:
                    if (props.yearSemester) {
                        vigilancesAPI.getAllVigilanceRequestsExtendedForEdition(
                            props.yearSemester.year!!,
                            props.yearSemester.semester!!,
                            props.departmentId ? props.departmentId : me.department?.id!!).then(
                            r => {
                                setRows(r.sort(vigilanceRequestSorter));
                                setIsLoading(false);
                            }
                        ).catch(genericErrorCallback);
                    } else {
                        vigilancesAPI.getAllVigilanceRequestsExtended(
                            props.departmentId ? props.departmentId : me.department?.id!!
                        ).then(
                            r => {
                                setRows(r.sort(vigilanceRequestSorter));
                                setIsLoading(false);
                            }
                        ).catch(genericErrorCallback);
                    }
                    break;
                case Scopes.ME:
                    if (props.yearSemester) {
                        vigilancesAPI.getAllVigilanceRequestsExtendedForEdition(props.yearSemester.year!!, props.yearSemester.semester!!, undefined, undefined, me.id).then(
                            r => {
                                setRows(r.sort(vigilanceRequestSorter));
                                setIsLoading(false);
                            }
                        ).catch(genericErrorCallback);
                    } else {
                        vigilancesAPI.getAllVigilanceRequestsExtended(undefined, undefined, me.id).then(
                            r => {
                                setRows(r.sort(vigilanceRequestSorter));
                                setIsLoading(false);
                            }
                        ).catch(genericErrorCallback);
                    }
                    break;
                default:
                    break;
            }
        }
    }

    function renderScopeText() {
        let text = "";
        switch (props.scope) {
            case Scopes.DEPARTMENT:
                text = "Todas as vigilâncias do seu departamento"
                break;
            case Scopes.ME:
                text = "Todas as suas vigilâncias"
                break;
            case Scopes.ALL:
                text = "Todas as vigilâncias do sistema"
                break;
        }

        return text === "" ? null : <Typography component="p" style={{margin: "5%"}}>
            {text}
        </Typography>
    }

    function filterCompact(rows: Array<VigilanceRequestExtendedDTO>) {
        return rows.filter(r => r.dtype === DTYPE.ACCEPTED || r.dtype === DTYPE.PENDING || r.dtype === DTYPE.DONE);
    }

    return (
        <div>
            {isLoading ? (
                <CircularProgress/>
            ) : rows.length === 0 ?
                <Typography className={classes.text}>Não existem vigilâncias</Typography> : (
                    <div>
                        {props.departmentId ? null :
                            <Typography className={classes.centered}>
                                {renderScopeText()}
                            </Typography>
                        }
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table" size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        {!props.compact ?
                                            <>
                                                <TableCell className={classes.tableHead}>Unidade
                                                    Curricular</TableCell>
                                                <TableCell className={classes.tableHead}>Data</TableCell>
                                                <TableCell className={classes.tableHead}>Duração</TableCell>
                                                <TableCell className={classes.tableHead}>Estado</TableCell>
                                            </>
                                            :
                                            <>
                                                <TableCell className={classes.tableHead}>Docente</TableCell>
                                                <TableCell className={classes.tableHead}>Estado</TableCell>
                                            </>
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {(props.compact && !expanded ? compactRows : rows).map(row => (
                                        <TableRow onClick={() => history.push(`/vigilances/${row.id}`)} key={row.id}
                                                  className={classes.clickable}>
                                            {!props.compact ?
                                                <>
                                                    <TableCell>{row.subject?.name}</TableCell>
                                                    <TableCell>{DatetimeComponent(new Date(row.evaluationDatetime!!))}</TableCell>
                                                    <TableCell>{row.evaluationDuration}</TableCell>
                                                    <TableCell>{renderDtype(row.dtype!!)}</TableCell>
                                                </>
                                                :
                                                <>
                                                    <TableCell>
                                                        <CustomLink href={`mailto:${row.lecturer?.email}`}>
                                                            {row.lecturer?.name}
                                                        </CustomLink>
                                                    </TableCell>

                                                    <TableCell>{renderDtype(row.dtype!!)}{row.replacedBy ? ` para ${row.replacedBy.lecturer?.name}` : null}</TableCell>
                                                </>
                                            }
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {props.compact && rows.length !== compactRows.length ?
                            <IconButton onClick={() => setExpanded(!expanded)}>
                                {expanded ? <ExpandLess/> : <ExpandMore/>}
                            </IconButton>
                            : null}

                    </div>
                )}
        </div>
    );
}
