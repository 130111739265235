import React from 'react';

import {defaultStyles} from '../../styles';
import SubjectsTable from "./SubjectsTable";
import {config} from "../../configuration";
import {DepartmentDTO, DepartmentsApi, SubjectsApi} from "../../typescript-fetch-client-generated";
import {CircularProgress, FormControl, FormControlLabel, RadioGroup} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {
    addToQueryParams,
    ConditionalDepartmentText,
    CustomRadio,
    genericErrorCallback,
    getFromQueryParams,
    Scopes
} from "../util/util";
import {BasicButton} from '../util/Buttons';
import {PageContext} from "../contexts/PageContext";
import {AppContext} from "../contexts/AppContext";

const useStyles = defaultStyles();

const scopeValues = ["open", "all"];
const scopeKey = "scope";

export default function SubjectsPage(props: any) {

    const classes = useStyles();
    const history = useHistory();

    const subjectsAPI = new SubjectsApi(config);
    const departmentAPI = new DepartmentsApi(config);

    const departmentId = parseInt(props.match.params.department_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [canCreate, setCanCreate] = React.useState(false);
    const [scopeRadioValue, setScopeRadioValue] = React.useState(scopeValues[0]);
    const [useYearSemesterContext, setUseYearSemesterContext] = React.useState(true);
    const [department, setDepartment] = React.useState<DepartmentDTO>({});
    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(fetchData, []);

    const appContext = React.useContext(AppContext);


    function handleScopeRadioChangeValue(value: string) {
        setScopeRadioValue(value);

        if (value === scopeValues[1]) {
            setUseYearSemesterContext(false);
        } else {
            setUseYearSemesterContext(true);
        }

    }

    function fetchData() {
        pageContext[1]("Unidades Curriculares");


        let scopeParam = getFromQueryParams(scopeKey);
        let _scopeRadioValue;

        if (scopeParam !== undefined && scopeValues.indexOf(scopeParam) >= 0) {
            _scopeRadioValue = scopeParam;
        } else {
            _scopeRadioValue = scopeValues[0];
        }

        handleScopeRadioChangeValue(_scopeRadioValue)

        subjectsAPI.canAddNewSubject(departmentId)
            .then(_ => setCanCreate(true))
            .catch(_ => setCanCreate(false));

        departmentAPI.getOneDepartment(departmentId)
            .then(d => {
                setDepartment(d);
                setIsLoading(false);
            }).catch(genericErrorCallback);

    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? <CircularProgress/> : (
                <div className={classes.centeredWithMaxWidth}>

                    <ConditionalDepartmentText department={department}/>

                    <FormControl component="fieldset">
                        <RadioGroup
                            name="radio-buttons-group"
                            value={scopeRadioValue}
                            onChange={(event) => {
                                addToQueryParams(scopeKey, event.target.value);
                                handleScopeRadioChangeValue(event.target.value);
                            }}
                            className={classes.radioGroup}
                        >
                            <FormControlLabel value={scopeValues[0]} control={<CustomRadio/>}
                                              label="Com edição aberta"/>
                            <FormControlLabel value={scopeValues[1]} control={<CustomRadio/>}
                                              label="Todas"/>
                        </RadioGroup>
                    </FormControl>


                    <SubjectsTable
                        scope={Scopes.DEPARTMENT}
                        yearSemester={useYearSemesterContext ? appContext.yearSemester.value : undefined}
                        departmentId={departmentId ? departmentId : appContext.me.value.department?.id}
                    />
                    {canCreate ?
                        <BasicButton
                            onClick={_ => history.push(`/departments/${appContext.me.value.department?.id}/addSubject`)}
                        >
                            Criar Unidade Curricular
                        </BasicButton> : null
                    }
                </div>
            )}
        </div>
    );
}
