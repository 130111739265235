import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {UsersApi, VigilantRecordDTO, VigilantRecordsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {Paper, Table, TableBody, Typography} from "@material-ui/core";
import {customRound, genericErrorCallback} from "../util/util";
import ModifyVigilantRecord from "./ModifyVigilantRecord";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";


const useStyles = defaultStyles();

let userControllerAPI: UsersApi = new UsersApi(config);
let vigilantRecordAPI: VigilantRecordsApi = new VigilantRecordsApi(config);

export default function VigilantRecord(props: { lecturerId: number, completeTable: boolean, canModify: boolean }) {
    const classes = useStyles();
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);


    const [isLecturer, setIsLecturer] = React.useState(false);
    const [vigilantRecord, setVigilantRecord] = React.useState<VigilantRecordDTO>({});
    React.useEffect(fetchData, [notifyUpdateContext.state])

    function fetchData() {
        userControllerAPI.getOneUser(props.lecturerId).then(u => {
            vigilantRecordAPI
                .getCurrentVigilantRecord(u.id!!)
                .then(vr => {
                    setVigilantRecord(vr);
                    setIsLecturer(true);
                }).catch(genericErrorCallback);
        }).catch(genericErrorCallback);


    }

    const data = [
        ["Pontos", customRound(vigilantRecord.points!!, 2)],
        ["Penalizações", customRound(vigilantRecord.penalties!!, 2)],
        ["Vigilâncias confirmadas", vigilantRecord.doneCounter],
        ["Faltas", vigilantRecord.absenteeCounter],
        ["Rejeições justificadas", vigilantRecord.acceptedRejections],
        ["Rejeições c/penalização", vigilantRecord.notAcceptedRejections]
    ]

    const basicData = [
        ["Pontos", vigilantRecord.points],
        ["Penalizações", vigilantRecord.penalties],
        ["Rejeições c/penalização", vigilantRecord.notAcceptedRejections]
    ]

    return (
        <div className={classes.paper}>
            {isLecturer ? (
                <div>
                    <Typography className={classes.tertiaryTitleMarginBot}>
                        Ficha de Vigilante
                    </Typography>
                    <Paper>
                        <Table size={"small"}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>
                                        Total (maior é melhor)
                                    </TableCell>
                                    <TableCell className={classes.tableHead} align="right">
                                        {customRound(vigilantRecord.score!!, 2)}
                                    </TableCell>
                                </TableRow>

                                {(props.completeTable ? data : basicData).map(entry =>
                                    <TableRow key={entry[0]}>
                                        <TableCell>
                                            {entry[0]}
                                        </TableCell>
                                        <TableCell align="right">
                                            {entry[1]}
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </Paper>

                    {
                        props.canModify ?
                            <ModifyVigilantRecord initialVigilantRecord={vigilantRecord}/>
                            : null
                    }


                </div>
            ) : null}
        </div>
    );
}
