/* eslint-disable @typescript-eslint/consistent-type-assertions */
/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Vigilance
 * Spring REST API for the vigilance platform
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AcceptedDTO
 */
export interface AcceptedDTO {
    /**
     * 
     * @type {string}
     * @memberof AcceptedDTO
     */
    dtype?: string;
    /**
     * 
     * @type {number}
     * @memberof AcceptedDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof AcceptedDTO
     */
    lecturer?: number;
    /**
     * 
     * @type {number}
     * @memberof AcceptedDTO
     */
    evaluation?: number;
    /**
     * 
     * @type {number}
     * @memberof AcceptedDTO
     */
    replacementFor?: number;
    /**
     * 
     * @type {Date}
     * @memberof AcceptedDTO
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AcceptedDTO
     */
    updatedOn?: Date;
}
/**
 * 
 * @export
 * @interface AttendanceDTO
 */
export interface AttendanceDTO {
    /**
     * 
     * @type {number}
     * @memberof AttendanceDTO
     */
    vigilanceRequestId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AttendanceDTO
     */
    present?: boolean;
}
/**
 * 
 * @export
 * @interface CapabilityDTO
 */
export interface CapabilityDTO {
    /**
     * 
     * @type {string}
     * @memberof CapabilityDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CapabilityDTO
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CapabilityDTO
     */
    roles?: Array<string>;
}
/**
 * 
 * @export
 * @interface ChangePasswordDTO
 */
export interface ChangePasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDTO
     */
    oldPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangePasswordDTO
     */
    newPassword?: string;
}
/**
 * 
 * @export
 * @interface CourseDTO
 */
export interface CourseDTO {
    /**
     * 
     * @type {number}
     * @memberof CourseDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CourseDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CourseDTO
     */
    offeredEditions?: Array<string>;
}
/**
 * 
 * @export
 * @interface DepartmentDTO
 */
export interface DepartmentDTO {
    /**
     * 
     * @type {number}
     * @memberof DepartmentDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DepartmentDTO
     */
    subjects?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DepartmentDTO
     */
    users?: Array<number>;
}
/**
 * 
 * @export
 * @interface DepartmentExtendedDTO
 */
export interface DepartmentExtendedDTO {
    /**
     * 
     * @type {number}
     * @memberof DepartmentExtendedDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentExtendedDTO
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof DepartmentExtendedDTO
     */
    subjects?: Array<number>;
    /**
     * 
     * @type {Array<UserMinimalDTO>}
     * @memberof DepartmentExtendedDTO
     */
    users?: Array<UserMinimalDTO>;
}
/**
 * 
 * @export
 * @interface DepartmentMinimalDTO
 */
export interface DepartmentMinimalDTO {
    /**
     * 
     * @type {number}
     * @memberof DepartmentMinimalDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentMinimalDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface DoneDTO
 */
export interface DoneDTO {
    /**
     * 
     * @type {string}
     * @memberof DoneDTO
     */
    dtype?: string;
    /**
     * 
     * @type {number}
     * @memberof DoneDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof DoneDTO
     */
    lecturer?: number;
    /**
     * 
     * @type {number}
     * @memberof DoneDTO
     */
    evaluation?: number;
    /**
     * 
     * @type {number}
     * @memberof DoneDTO
     */
    replacementFor?: number;
    /**
     * 
     * @type {Date}
     * @memberof DoneDTO
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DoneDTO
     */
    updatedOn?: Date;
}
/**
 * 
 * @export
 * @interface EditionDTO
 */
export interface EditionDTO {
    /**
     * 
     * @type {string}
     * @memberof EditionDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof EditionDTO
     */
    semester?: number;
    /**
     * 
     * @type {number}
     * @memberof EditionDTO
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof EditionDTO
     */
    subject?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof EditionDTO
     */
    evaluations?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EditionDTO
     */
    coursesOffering?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EditionDTO
     */
    lecturers?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EditionDTO
     */
    headLecturers?: Array<number>;
}
/**
 * 
 * @export
 * @interface EditionExtendedDTO
 */
export interface EditionExtendedDTO {
    /**
     * 
     * @type {string}
     * @memberof EditionExtendedDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof EditionExtendedDTO
     */
    semester?: number;
    /**
     * 
     * @type {number}
     * @memberof EditionExtendedDTO
     */
    year?: number;
    /**
     * 
     * @type {SubjectMinimalDTO}
     * @memberof EditionExtendedDTO
     */
    subject?: SubjectMinimalDTO;
    /**
     * 
     * @type {Array<number>}
     * @memberof EditionExtendedDTO
     */
    evaluations?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof EditionExtendedDTO
     */
    coursesOffering?: Array<number>;
    /**
     * 
     * @type {Array<LecturerMinimalDTO>}
     * @memberof EditionExtendedDTO
     */
    lecturers?: Array<LecturerMinimalDTO>;
    /**
     * 
     * @type {Array<LecturerMinimalDTO>}
     * @memberof EditionExtendedDTO
     */
    headLecturers?: Array<LecturerMinimalDTO>;
}
/**
 * 
 * @export
 * @interface EditionMinimalDTO
 */
export interface EditionMinimalDTO {
    /**
     * 
     * @type {string}
     * @memberof EditionMinimalDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof EditionMinimalDTO
     */
    semester?: number;
    /**
     * 
     * @type {number}
     * @memberof EditionMinimalDTO
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof EditionMinimalDTO
     */
    subject?: number;
    /**
     * 
     * @type {Array<LecturerMinimalDTO>}
     * @memberof EditionMinimalDTO
     */
    lecturers?: Array<LecturerMinimalDTO>;
    /**
     * 
     * @type {Array<LecturerMinimalDTO>}
     * @memberof EditionMinimalDTO
     */
    headLecturers?: Array<LecturerMinimalDTO>;
}
/**
 * 
 * @export
 * @interface EvaluationDTO
 */
export interface EvaluationDTO {
    /**
     * 
     * @type {number}
     * @memberof EvaluationDTO
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof EvaluationDTO
     */
    datetime?: Date;
    /**
     * 
     * @type {number}
     * @memberof EvaluationDTO
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof EvaluationDTO
     */
    edition?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof EvaluationDTO
     */
    rooms?: Array<number>;
    /**
     * 
     * @type {number}
     * @memberof EvaluationDTO
     */
    applicant?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof EvaluationDTO
     */
    vigilances?: Array<number>;
}
/**
 * 
 * @export
 * @interface EvaluationExtendedDTO
 */
export interface EvaluationExtendedDTO {
    /**
     * 
     * @type {number}
     * @memberof EvaluationExtendedDTO
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof EvaluationExtendedDTO
     */
    datetime?: Date;
    /**
     * 
     * @type {number}
     * @memberof EvaluationExtendedDTO
     */
    duration?: number;
    /**
     * 
     * @type {EditionMinimalDTO}
     * @memberof EvaluationExtendedDTO
     */
    edition?: EditionMinimalDTO;
    /**
     * 
     * @type {UserMinimalDTO}
     * @memberof EvaluationExtendedDTO
     */
    applicant?: UserMinimalDTO;
    /**
     * 
     * @type {Array<VigilanceRequestMinimalDTO>}
     * @memberof EvaluationExtendedDTO
     */
    vigilances?: Array<VigilanceRequestMinimalDTO>;
    /**
     * 
     * @type {Array<RoomMinimalDTO>}
     * @memberof EvaluationExtendedDTO
     */
    rooms?: Array<RoomMinimalDTO>;
    /**
     * 
     * @type {SubjectMinimalDTO}
     * @memberof EvaluationExtendedDTO
     */
    subject?: SubjectMinimalDTO;
}
/**
 * 
 * @export
 * @interface ExemptDTO
 */
export interface ExemptDTO {
    /**
     * 
     * @type {string}
     * @memberof ExemptDTO
     */
    dtype?: string;
    /**
     * 
     * @type {number}
     * @memberof ExemptDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ExemptDTO
     */
    lecturer?: number;
    /**
     * 
     * @type {number}
     * @memberof ExemptDTO
     */
    evaluation?: number;
    /**
     * 
     * @type {number}
     * @memberof ExemptDTO
     */
    replacementFor?: number;
    /**
     * 
     * @type {Date}
     * @memberof ExemptDTO
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExemptDTO
     */
    updatedOn?: Date;
}
/**
 * 
 * @export
 * @interface GlobalParameterDTO
 */
export interface GlobalParameterDTO {
    /**
     * 
     * @type {string}
     * @memberof GlobalParameterDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalParameterDTO
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalParameterDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalParameterDTO
     */
    type?: string;
}
/**
 * 
 * @export
 * @interface LecturerDTO
 */
export interface LecturerDTO {
    /**
     * 
     * @type {number}
     * @memberof LecturerDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LecturerDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LecturerDTO
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof LecturerDTO
     */
    department?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof LecturerDTO
     */
    roles?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof LecturerDTO
     */
    removed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LecturerDTO
     */
    vigilantStatus?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof LecturerDTO
     */
    editions?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof LecturerDTO
     */
    editionsHeading?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LecturerDTO
     */
    addedEvaluations?: Array<number>;
    /**
     * 
     * @type {Array<SlotDAOId>}
     * @memberof LecturerDTO
     */
    schedule?: Array<SlotDAOId>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LecturerDTO
     */
    records?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LecturerDTO
     */
    vigilances?: Array<number>;
}
/**
 * 
 * @export
 * @interface LecturerMinimalDTO
 */
export interface LecturerMinimalDTO {
    /**
     * 
     * @type {number}
     * @memberof LecturerMinimalDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LecturerMinimalDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof LecturerMinimalDTO
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LecturerMinimalDTO
     */
    vigilantStatus?: boolean;
}
/**
 * 
 * @export
 * @interface LocalTime
 */
export interface LocalTime {
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    hour?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    minute?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    second?: number;
    /**
     * 
     * @type {number}
     * @memberof LocalTime
     */
    nano?: number;
}
/**
 * 
 * @export
 * @interface LogEntryDTO
 */
export interface LogEntryDTO {
    /**
     * 
     * @type {number}
     * @memberof LogEntryDTO
     */
    id?: number;
    /**
     * 
     * @type {LogLevelDTO}
     * @memberof LogEntryDTO
     */
    level?: LogLevelDTO;
    /**
     * 
     * @type {Date}
     * @memberof LogEntryDTO
     */
    datetime?: Date;
    /**
     * 
     * @type {string}
     * @memberof LogEntryDTO
     */
    description?: string;
    /**
     * 
     * @type {UserMinimalDTO}
     * @memberof LogEntryDTO
     */
    user?: UserMinimalDTO;
    /**
     * 
     * @type {DepartmentMinimalDTO}
     * @memberof LogEntryDTO
     */
    department?: DepartmentMinimalDTO;
    /**
     * 
     * @type {string}
     * @memberof LogEntryDTO
     */
    sourceService?: string;
}
/**
 * 
 * @export
 * @interface LogFilter
 */
export interface LogFilter {
    /**
     * 
     * @type {string}
     * @memberof LogFilter
     */
    descriptionContains?: string;
    /**
     * 
     * @type {Array<LogLevelDTO>}
     * @memberof LogFilter
     */
    filterLevels?: Array<LogLevelDTO>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LogFilter
     */
    filterDepartments?: Array<number>;
}
/**
 * 
 * @export
 * @interface LogLevelDTO
 */
export interface LogLevelDTO {
    /**
     * 
     * @type {string}
     * @memberof LogLevelDTO
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface PageLogEntryDTO
 */
export interface PageLogEntryDTO {
    /**
     * 
     * @type {number}
     * @memberof PageLogEntryDTO
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLogEntryDTO
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLogEntryDTO
     */
    size?: number;
    /**
     * 
     * @type {Array<LogEntryDTO>}
     * @memberof PageLogEntryDTO
     */
    content?: Array<LogEntryDTO>;
    /**
     * 
     * @type {number}
     * @memberof PageLogEntryDTO
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLogEntryDTO
     */
    sort?: Sort;
    /**
     * 
     * @type {Pageable}
     * @memberof PageLogEntryDTO
     */
    pageable?: Pageable;
    /**
     * 
     * @type {boolean}
     * @memberof PageLogEntryDTO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLogEntryDTO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageLogEntryDTO
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageLogEntryDTO
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    offset?: number;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    paged?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface PenaltyJustificationDTO
 */
export interface PenaltyJustificationDTO {
    /**
     * 
     * @type {string}
     * @memberof PenaltyJustificationDTO
     */
    text?: string;
}
/**
 * 
 * @export
 * @interface RejectedDTO
 */
export interface RejectedDTO {
    /**
     * 
     * @type {string}
     * @memberof RejectedDTO
     */
    dtype?: string;
    /**
     * 
     * @type {number}
     * @memberof RejectedDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof RejectedDTO
     */
    lecturer?: number;
    /**
     * 
     * @type {number}
     * @memberof RejectedDTO
     */
    evaluation?: number;
    /**
     * 
     * @type {number}
     * @memberof RejectedDTO
     */
    replacementFor?: number;
    /**
     * 
     * @type {Date}
     * @memberof RejectedDTO
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof RejectedDTO
     */
    updatedOn?: Date;
    /**
     * 
     * @type {string}
     * @memberof RejectedDTO
     */
    justification?: string;
    /**
     * 
     * @type {number}
     * @memberof RejectedDTO
     */
    rejectionType?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RejectedDTO
     */
    confirmed?: boolean;
}
/**
 * 
 * @export
 * @interface RejectionDTO
 */
export interface RejectionDTO {
    /**
     * 
     * @type {number}
     * @memberof RejectionDTO
     */
    idType?: number;
    /**
     * 
     * @type {string}
     * @memberof RejectionDTO
     */
    justification?: string;
}
/**
 * 
 * @export
 * @interface RejectionTypeDTO
 */
export interface RejectionTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof RejectionTypeDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RejectionTypeDTO
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RejectionTypeDTO
     */
    needsConfirmation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RejectionTypeDTO
     */
    needsJustification?: boolean;
}
/**
 * 
 * @export
 * @interface RoleDTO
 */
export interface RoleDTO {
    /**
     * 
     * @type {string}
     * @memberof RoleDTO
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleDTO
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleDTO
     */
    capabilities?: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoleDTO
     */
    users?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RoleDTO
     */
    canGive?: Array<string>;
}
/**
 * 
 * @export
 * @interface RoomDTO
 */
export interface RoomDTO {
    /**
     * 
     * @type {number}
     * @memberof RoomDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomDTO
     */
    building?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomDTO
     */
    number?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomDTO
     */
    maxVigilants?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomDTO
     */
    maxCapacity?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof RoomDTO
     */
    evaluations?: Array<number>;
}
/**
 * 
 * @export
 * @interface RoomMinimalDTO
 */
export interface RoomMinimalDTO {
    /**
     * 
     * @type {number}
     * @memberof RoomMinimalDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RoomMinimalDTO
     */
    building?: string;
    /**
     * 
     * @type {string}
     * @memberof RoomMinimalDTO
     */
    number?: string;
    /**
     * 
     * @type {number}
     * @memberof RoomMinimalDTO
     */
    maxVigilants?: number;
    /**
     * 
     * @type {number}
     * @memberof RoomMinimalDTO
     */
    maxCapacity?: number;
}
/**
 * 
 * @export
 * @interface ScoreTableEntryDTO
 */
export interface ScoreTableEntryDTO {
    /**
     * 
     * @type {LecturerMinimalDTO}
     * @memberof ScoreTableEntryDTO
     */
    lecturer?: LecturerMinimalDTO;
    /**
     * 
     * @type {number}
     * @memberof ScoreTableEntryDTO
     */
    score?: number;
}
/**
 * 
 * @export
 * @interface SignUpLecturerDTO
 */
export interface SignUpLecturerDTO {
    /**
     * 
     * @type {string}
     * @memberof SignUpLecturerDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpLecturerDTO
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof SignUpLecturerDTO
     */
    department?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SignUpLecturerDTO
     */
    roles?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SignUpLecturerDTO
     */
    vigilantStatus?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SignUpLecturerDTO
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface SignUpUserDTO
 */
export interface SignUpUserDTO {
    /**
     * 
     * @type {string}
     * @memberof SignUpUserDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserDTO
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof SignUpUserDTO
     */
    department?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SignUpUserDTO
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SignUpUserDTO
     */
    password?: string;
}
/**
 * 
 * @export
 * @interface SimpleDateDTO
 */
export interface SimpleDateDTO {
    /**
     * 
     * @type {number}
     * @memberof SimpleDateDTO
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDateDTO
     */
    month?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDateDTO
     */
    day?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDateDTO
     */
    hour?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleDateDTO
     */
    minute?: number;
}
/**
 * 
 * @export
 * @interface SimpleEvaluationDTO
 */
export interface SimpleEvaluationDTO {
    /**
     * 
     * @type {SimpleDateDTO}
     * @memberof SimpleEvaluationDTO
     */
    date?: SimpleDateDTO;
    /**
     * 
     * @type {number}
     * @memberof SimpleEvaluationDTO
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof SimpleEvaluationDTO
     */
    edition?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof SimpleEvaluationDTO
     */
    rooms?: Array<number>;
}
/**
 * 
 * @export
 * @interface SimpleVigilanceRequestDTO
 */
export interface SimpleVigilanceRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof SimpleVigilanceRequestDTO
     */
    lecturer?: number;
    /**
     * 
     * @type {number}
     * @memberof SimpleVigilanceRequestDTO
     */
    evaluation?: number;
}
/**
 * 
 * @export
 * @interface SlotDAOId
 */
export interface SlotDAOId {
    /**
     * 
     * @type {string}
     * @memberof SlotDAOId
     */
    dayOfWeek?: SlotDAOId.DayOfWeekEnum;
    /**
     * 
     * @type {LocalTime}
     * @memberof SlotDAOId
     */
    initialTime?: LocalTime;
}

/**
 * @export
 * @namespace SlotDAOId
 */
export declare namespace SlotDAOId {
    /**
     * @export
     * @enum {string}
     */
    export enum DayOfWeekEnum {
        MONDAY = 'MONDAY',
        TUESDAY = 'TUESDAY',
        WEDNESDAY = 'WEDNESDAY',
        THURSDAY = 'THURSDAY',
        FRIDAY = 'FRIDAY',
        SATURDAY = 'SATURDAY',
        SUNDAY = 'SUNDAY'
    }
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
}
/**
 * 
 * @export
 * @interface SubjectDTO
 */
export interface SubjectDTO {
    /**
     * 
     * @type {number}
     * @memberof SubjectDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SubjectDTO
     */
    department?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SubjectDTO
     */
    editions?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SubjectDTO
     */
    initials?: string;
}
/**
 * 
 * @export
 * @interface SubjectExtendedDTO
 */
export interface SubjectExtendedDTO {
    /**
     * 
     * @type {number}
     * @memberof SubjectExtendedDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectExtendedDTO
     */
    name?: string;
    /**
     * 
     * @type {DepartmentMinimalDTO}
     * @memberof SubjectExtendedDTO
     */
    department?: DepartmentMinimalDTO;
    /**
     * 
     * @type {Array<EditionMinimalDTO>}
     * @memberof SubjectExtendedDTO
     */
    editions?: Array<EditionMinimalDTO>;
    /**
     * 
     * @type {string}
     * @memberof SubjectExtendedDTO
     */
    initials?: string;
}
/**
 * 
 * @export
 * @interface SubjectMinimalDTO
 */
export interface SubjectMinimalDTO {
    /**
     * 
     * @type {number}
     * @memberof SubjectMinimalDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectMinimalDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof SubjectMinimalDTO
     */
    department?: number;
    /**
     * 
     * @type {string}
     * @memberof SubjectMinimalDTO
     */
    initials?: string;
}
/**
 * 
 * @export
 * @interface SwappedDTO
 */
export interface SwappedDTO {
    /**
     * 
     * @type {string}
     * @memberof SwappedDTO
     */
    dtype?: string;
    /**
     * 
     * @type {number}
     * @memberof SwappedDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof SwappedDTO
     */
    lecturer?: number;
    /**
     * 
     * @type {number}
     * @memberof SwappedDTO
     */
    evaluation?: number;
    /**
     * 
     * @type {number}
     * @memberof SwappedDTO
     */
    replacementFor?: number;
    /**
     * 
     * @type {Date}
     * @memberof SwappedDTO
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SwappedDTO
     */
    updatedOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof SwappedDTO
     */
    replacedBy?: number;
}
/**
 * 
 * @export
 * @interface UserDTO
 */
export interface UserDTO {
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDTO
     */
    email?: string;
    /**
     * 
     * @type {number}
     * @memberof UserDTO
     */
    department?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDTO
     */
    roles?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserDTO
     */
    removed?: boolean;
}
/**
 * 
 * @export
 * @interface UserExtendedDTO
 */
export interface UserExtendedDTO {
    /**
     * 
     * @type {number}
     * @memberof UserExtendedDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserExtendedDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserExtendedDTO
     */
    email?: string;
    /**
     * 
     * @type {DepartmentMinimalDTO}
     * @memberof UserExtendedDTO
     */
    department?: DepartmentMinimalDTO;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserExtendedDTO
     */
    roles?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UserExtendedDTO
     */
    hasLocalPassword?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserExtendedDTO
     */
    removed?: boolean;
}
/**
 * 
 * @export
 * @interface UserMinimalDTO
 */
export interface UserMinimalDTO {
    /**
     * 
     * @type {number}
     * @memberof UserMinimalDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserMinimalDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserMinimalDTO
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserMinimalDTO
     */
    removed?: boolean;
}
/**
 * 
 * @export
 * @interface VigilanceRequestDTO
 */
export interface VigilanceRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof VigilanceRequestDTO
     */
    dtype?: string;
    /**
     * 
     * @type {number}
     * @memberof VigilanceRequestDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilanceRequestDTO
     */
    lecturer?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilanceRequestDTO
     */
    evaluation?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilanceRequestDTO
     */
    replacementFor?: number;
    /**
     * 
     * @type {Date}
     * @memberof VigilanceRequestDTO
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VigilanceRequestDTO
     */
    updatedOn?: Date;
}
/**
 * 
 * @export
 * @interface VigilanceRequestExtendedDTO
 */
export interface VigilanceRequestExtendedDTO {
    /**
     * 
     * @type {string}
     * @memberof VigilanceRequestExtendedDTO
     */
    dtype?: string;
    /**
     * 
     * @type {number}
     * @memberof VigilanceRequestExtendedDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilanceRequestExtendedDTO
     */
    evaluation?: number;
    /**
     * 
     * @type {VigilanceRequestMinimalDTO}
     * @memberof VigilanceRequestExtendedDTO
     */
    replacementFor?: VigilanceRequestMinimalDTO;
    /**
     * 
     * @type {Date}
     * @memberof VigilanceRequestExtendedDTO
     */
    createdOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VigilanceRequestExtendedDTO
     */
    updatedOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VigilanceRequestExtendedDTO
     */
    evaluationDatetime?: Date;
    /**
     * 
     * @type {number}
     * @memberof VigilanceRequestExtendedDTO
     */
    evaluationDuration?: number;
    /**
     * 
     * @type {SubjectMinimalDTO}
     * @memberof VigilanceRequestExtendedDTO
     */
    subject?: SubjectMinimalDTO;
    /**
     * 
     * @type {UserMinimalDTO}
     * @memberof VigilanceRequestExtendedDTO
     */
    lecturer?: UserMinimalDTO;
    /**
     * 
     * @type {Array<UserMinimalDTO>}
     * @memberof VigilanceRequestExtendedDTO
     */
    headLecturers?: Array<UserMinimalDTO>;
    /**
     * 
     * @type {Array<RoomMinimalDTO>}
     * @memberof VigilanceRequestExtendedDTO
     */
    rooms?: Array<RoomMinimalDTO>;
    /**
     * 
     * @type {VigilanceRequestMinimalDTO}
     * @memberof VigilanceRequestExtendedDTO
     */
    replacedBy?: VigilanceRequestMinimalDTO;
    /**
     * 
     * @type {RejectionTypeDTO}
     * @memberof VigilanceRequestExtendedDTO
     */
    rejectionType?: RejectionTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof VigilanceRequestExtendedDTO
     */
    justification?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VigilanceRequestExtendedDTO
     */
    confirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof VigilanceRequestExtendedDTO
     */
    delegated?: boolean;
}
/**
 * 
 * @export
 * @interface VigilanceRequestMinimalDTO
 */
export interface VigilanceRequestMinimalDTO {
    /**
     * 
     * @type {string}
     * @memberof VigilanceRequestMinimalDTO
     */
    dtype?: string;
    /**
     * 
     * @type {number}
     * @memberof VigilanceRequestMinimalDTO
     */
    id?: number;
    /**
     * 
     * @type {UserMinimalDTO}
     * @memberof VigilanceRequestMinimalDTO
     */
    lecturer?: UserMinimalDTO;
}
/**
 * 
 * @export
 * @interface VigilantRecordDTO
 */
export interface VigilantRecordDTO {
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    semester?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    lecturer?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    points?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    penalties?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    doneCounter?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    absenteeCounter?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    acceptedRejections?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    notAcceptedRejections?: number;
    /**
     * 
     * @type {number}
     * @memberof VigilantRecordDTO
     */
    score?: number;
}
/**
 * 
 * @export
 * @interface YearSemesterDTO
 */
export interface YearSemesterDTO {
    /**
     * 
     * @type {string}
     * @memberof YearSemesterDTO
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof YearSemesterDTO
     */
    year?: number;
    /**
     * 
     * @type {number}
     * @memberof YearSemesterDTO
     */
    semester?: number;
}
/**
 * CapabilitiesApi - fetch parameter creator
 * @export
 */
export const CapabilitiesApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new capability
         * @param {CapabilityDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCapability(body?: CapabilityDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/capabilities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CapabilityDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a capability
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneCapability(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneCapability.');
            }
            const localVarPath = `/api/capabilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all capabilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCapabilities(options: any = {}): FetchArgs {
            const localVarPath = `/api/capabilities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific capability
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneCapability(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneCapability.');
            }
            const localVarPath = `/api/capabilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify a capability
         * @param {string} id 
         * @param {CapabilityDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneCapability(id: string, body?: CapabilityDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneCapability.');
            }
            const localVarPath = `/api/capabilities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CapabilityDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CapabilitiesApi - functional programming interface
 * @export
 */
export const CapabilitiesApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new capability
         * @param {CapabilityDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCapability(body?: CapabilityDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CapabilityDTO> {
            const localVarFetchArgs = CapabilitiesApiFetchParamCreator(configuration).addNewCapability(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a capability
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneCapability(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CapabilitiesApiFetchParamCreator(configuration).deleteOneCapability(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all capabilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCapabilities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CapabilityDTO>> {
            const localVarFetchArgs = CapabilitiesApiFetchParamCreator(configuration).getAllCapabilities(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific capability
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneCapability(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CapabilityDTO> {
            const localVarFetchArgs = CapabilitiesApiFetchParamCreator(configuration).getOneCapability(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify a capability
         * @param {string} id 
         * @param {CapabilityDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneCapability(id: string, body?: CapabilityDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CapabilityDTO> {
            const localVarFetchArgs = CapabilitiesApiFetchParamCreator(configuration).modifyOneCapability(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CapabilitiesApi - factory interface
 * @export
 */
export const CapabilitiesApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new capability
         * @param {CapabilityDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCapability(body?: CapabilityDTO, options?: any) {
            return CapabilitiesApiFp(configuration).addNewCapability(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a capability
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneCapability(id: string, options?: any) {
            return CapabilitiesApiFp(configuration).deleteOneCapability(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all capabilities
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCapabilities(options?: any) {
            return CapabilitiesApiFp(configuration).getAllCapabilities(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific capability
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneCapability(id: string, options?: any) {
            return CapabilitiesApiFp(configuration).getOneCapability(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify a capability
         * @param {string} id 
         * @param {CapabilityDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneCapability(id: string, body?: CapabilityDTO, options?: any) {
            return CapabilitiesApiFp(configuration).modifyOneCapability(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * CapabilitiesApi - object-oriented interface
 * @export
 * @class CapabilitiesApi
 * @extends {BaseAPI}
 */
export class CapabilitiesApi extends BaseAPI {
    /**
     * 
     * @summary Create a new capability
     * @param {CapabilityDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapabilitiesApi
     */
    public addNewCapability(body?: CapabilityDTO, options?: any) {
        return CapabilitiesApiFp(this.configuration).addNewCapability(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a capability
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapabilitiesApi
     */
    public deleteOneCapability(id: string, options?: any) {
        return CapabilitiesApiFp(this.configuration).deleteOneCapability(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all capabilities
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapabilitiesApi
     */
    public getAllCapabilities(options?: any) {
        return CapabilitiesApiFp(this.configuration).getAllCapabilities(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific capability
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapabilitiesApi
     */
    public getOneCapability(id: string, options?: any) {
        return CapabilitiesApiFp(this.configuration).getOneCapability(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify a capability
     * @param {string} id 
     * @param {CapabilityDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CapabilitiesApi
     */
    public modifyOneCapability(id: string, body?: CapabilityDTO, options?: any) {
        return CapabilitiesApiFp(this.configuration).modifyOneCapability(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * CoursesApi - fetch parameter creator
 * @export
 */
export const CoursesApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new course
         * @param {CourseDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCourse(body?: CourseDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/courses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CourseDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a course
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneCourse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneCourse.');
            }
            const localVarPath = `/api/courses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses(options: any = {}): FetchArgs {
            const localVarPath = `/api/courses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific course
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneCourse(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneCourse.');
            }
            const localVarPath = `/api/courses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify a course
         * @param {number} id 
         * @param {CourseDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneCourse(id: number, body?: CourseDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneCourse.');
            }
            const localVarPath = `/api/courses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CourseDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoursesApi - functional programming interface
 * @export
 */
export const CoursesApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new course
         * @param {CourseDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCourse(body?: CourseDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CourseDTO> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).addNewCourse(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a course
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneCourse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).deleteOneCourse(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CourseDTO>> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).getAllCourses(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific course
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneCourse(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CourseDTO> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).getOneCourse(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify a course
         * @param {number} id 
         * @param {CourseDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneCourse(id: number, body?: CourseDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CourseDTO> {
            const localVarFetchArgs = CoursesApiFetchParamCreator(configuration).modifyOneCourse(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CoursesApi - factory interface
 * @export
 */
export const CoursesApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new course
         * @param {CourseDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewCourse(body?: CourseDTO, options?: any) {
            return CoursesApiFp(configuration).addNewCourse(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a course
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneCourse(id: number, options?: any) {
            return CoursesApiFp(configuration).deleteOneCourse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all courses
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCourses(options?: any) {
            return CoursesApiFp(configuration).getAllCourses(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific course
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneCourse(id: number, options?: any) {
            return CoursesApiFp(configuration).getOneCourse(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify a course
         * @param {number} id 
         * @param {CourseDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneCourse(id: number, body?: CourseDTO, options?: any) {
            return CoursesApiFp(configuration).modifyOneCourse(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * CoursesApi - object-oriented interface
 * @export
 * @class CoursesApi
 * @extends {BaseAPI}
 */
export class CoursesApi extends BaseAPI {
    /**
     * 
     * @summary Create a new course
     * @param {CourseDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public addNewCourse(body?: CourseDTO, options?: any) {
        return CoursesApiFp(this.configuration).addNewCourse(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a course
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public deleteOneCourse(id: number, options?: any) {
        return CoursesApiFp(this.configuration).deleteOneCourse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all courses
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getAllCourses(options?: any) {
        return CoursesApiFp(this.configuration).getAllCourses(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific course
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public getOneCourse(id: number, options?: any) {
        return CoursesApiFp(this.configuration).getOneCourse(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify a course
     * @param {number} id 
     * @param {CourseDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoursesApi
     */
    public modifyOneCourse(id: number, body?: CourseDTO, options?: any) {
        return CoursesApiFp(this.configuration).modifyOneCourse(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * DepartmentsApi - fetch parameter creator
 * @export
 */
export const DepartmentsApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new department
         * @param {DepartmentDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewDepartment(body?: DepartmentDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/departments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DepartmentDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new department
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewDepartment(options: any = {}): FetchArgs {
            const localVarPath = `/api/departments/canCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can delete a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneDepartment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canDeleteOneDepartment.');
            }
            const localVarPath = `/api/departments/canDelete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneDepartment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneDepartment.');
            }
            const localVarPath = `/api/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartments(options: any = {}): FetchArgs {
            const localVarPath = `/api/departments`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all departments with extra information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExtendedDepartments(options: any = {}): FetchArgs {
            const localVarPath = `/api/departments/extended`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMinimalDepartments(options: any = {}): FetchArgs {
            const localVarPath = `/api/departments/minimal`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all lecturers of a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentLecturers(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDepartmentLecturers.');
            }
            const localVarPath = `/api/departments/{id}/lecturers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all subjects of a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentSubjects(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDepartmentSubjects.');
            }
            const localVarPath = `/api/departments/{id}/subjects`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all lecturers of a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentVigilants(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getDepartmentVigilants.');
            }
            const localVarPath = `/api/departments/{id}/vigilants`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneDepartment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneDepartment.');
            }
            const localVarPath = `/api/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific department with extra information
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExtendedDepartment(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneExtendedDepartment.');
            }
            const localVarPath = `/api/departments/extended/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify a department
         * @param {number} id Department id
         * @param {DepartmentDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneDepartment(id: number, body?: DepartmentDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneDepartment.');
            }
            const localVarPath = `/api/departments/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DepartmentDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new department
         * @param {DepartmentDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewDepartment(body?: DepartmentDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DepartmentDTO> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).addNewDepartment(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new department
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewDepartment(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).canAddNewDepartment(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can delete a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneDepartment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).canDeleteOneDepartment(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneDepartment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).deleteOneDepartment(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartments(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DepartmentDTO>> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).getAllDepartments(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all departments with extra information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExtendedDepartments(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DepartmentExtendedDTO>> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).getAllExtendedDepartments(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMinimalDepartments(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DepartmentMinimalDTO>> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).getAllMinimalDepartments(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all lecturers of a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentLecturers(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LecturerDTO>> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).getDepartmentLecturers(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all subjects of a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentSubjects(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubjectDTO>> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).getDepartmentSubjects(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all lecturers of a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentVigilants(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LecturerDTO>> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).getDepartmentVigilants(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneDepartment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DepartmentDTO> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).getOneDepartment(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific department with extra information
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExtendedDepartment(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DepartmentExtendedDTO> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).getOneExtendedDepartment(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify a department
         * @param {number} id Department id
         * @param {DepartmentDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneDepartment(id: number, body?: DepartmentDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DepartmentDTO> {
            const localVarFetchArgs = DepartmentsApiFetchParamCreator(configuration).modifyOneDepartment(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new department
         * @param {DepartmentDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewDepartment(body?: DepartmentDTO, options?: any) {
            return DepartmentsApiFp(configuration).addNewDepartment(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can create a new department
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewDepartment(options?: any) {
            return DepartmentsApiFp(configuration).canAddNewDepartment(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can delete a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneDepartment(id: number, options?: any) {
            return DepartmentsApiFp(configuration).canDeleteOneDepartment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneDepartment(id: number, options?: any) {
            return DepartmentsApiFp(configuration).deleteOneDepartment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDepartments(options?: any) {
            return DepartmentsApiFp(configuration).getAllDepartments(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all departments with extra information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExtendedDepartments(options?: any) {
            return DepartmentsApiFp(configuration).getAllExtendedDepartments(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all departments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllMinimalDepartments(options?: any) {
            return DepartmentsApiFp(configuration).getAllMinimalDepartments(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all lecturers of a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentLecturers(id: number, options?: any) {
            return DepartmentsApiFp(configuration).getDepartmentLecturers(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all subjects of a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentSubjects(id: number, options?: any) {
            return DepartmentsApiFp(configuration).getDepartmentSubjects(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all lecturers of a department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentVigilants(id: number, options?: any) {
            return DepartmentsApiFp(configuration).getDepartmentVigilants(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific department
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneDepartment(id: number, options?: any) {
            return DepartmentsApiFp(configuration).getOneDepartment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific department with extra information
         * @param {number} id Department id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExtendedDepartment(id: number, options?: any) {
            return DepartmentsApiFp(configuration).getOneExtendedDepartment(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify a department
         * @param {number} id Department id
         * @param {DepartmentDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneDepartment(id: number, body?: DepartmentDTO, options?: any) {
            return DepartmentsApiFp(configuration).modifyOneDepartment(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new department
     * @param {DepartmentDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public addNewDepartment(body?: DepartmentDTO, options?: any) {
        return DepartmentsApiFp(this.configuration).addNewDepartment(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can create a new department
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public canAddNewDepartment(options?: any) {
        return DepartmentsApiFp(this.configuration).canAddNewDepartment(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can delete a department
     * @param {number} id Department id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public canDeleteOneDepartment(id: number, options?: any) {
        return DepartmentsApiFp(this.configuration).canDeleteOneDepartment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a department
     * @param {number} id Department id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public deleteOneDepartment(id: number, options?: any) {
        return DepartmentsApiFp(this.configuration).deleteOneDepartment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all departments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getAllDepartments(options?: any) {
        return DepartmentsApiFp(this.configuration).getAllDepartments(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all departments with extra information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getAllExtendedDepartments(options?: any) {
        return DepartmentsApiFp(this.configuration).getAllExtendedDepartments(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all departments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getAllMinimalDepartments(options?: any) {
        return DepartmentsApiFp(this.configuration).getAllMinimalDepartments(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all lecturers of a department
     * @param {number} id Department id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartmentLecturers(id: number, options?: any) {
        return DepartmentsApiFp(this.configuration).getDepartmentLecturers(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all subjects of a department
     * @param {number} id Department id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartmentSubjects(id: number, options?: any) {
        return DepartmentsApiFp(this.configuration).getDepartmentSubjects(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all lecturers of a department
     * @param {number} id Department id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartmentVigilants(id: number, options?: any) {
        return DepartmentsApiFp(this.configuration).getDepartmentVigilants(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific department
     * @param {number} id Department id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getOneDepartment(id: number, options?: any) {
        return DepartmentsApiFp(this.configuration).getOneDepartment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific department with extra information
     * @param {number} id Department id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getOneExtendedDepartment(id: number, options?: any) {
        return DepartmentsApiFp(this.configuration).getOneExtendedDepartment(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify a department
     * @param {number} id Department id
     * @param {DepartmentDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public modifyOneDepartment(id: number, body?: DepartmentDTO, options?: any) {
        return DepartmentsApiFp(this.configuration).modifyOneDepartment(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * EditionsApi - fetch parameter creator
 * @export
 */
export const EditionsApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new edition
         * @param {EditionDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewEdition(body?: EditionDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/editions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditionDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new edition for a subject for the current semester
         * @param {number} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewEditionForCurrentSemester(subjectId: number, options: any = {}): FetchArgs {
            // verify required parameter 'subjectId' is not null or undefined
            if (subjectId === null || subjectId === undefined) {
                throw new RequiredError('subjectId','Required parameter subjectId was null or undefined when calling addNewEditionForCurrentSemester.');
            }
            const localVarPath = `/api/editions/current/{subjectId}`
                .replace(`{${"subjectId"}}`, encodeURIComponent(String(subjectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new edition for a subject for the current semester
         * @param {number} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewEditionForCurrentSemester(subjectId: number, options: any = {}): FetchArgs {
            // verify required parameter 'subjectId' is not null or undefined
            if (subjectId === null || subjectId === undefined) {
                throw new RequiredError('subjectId','Required parameter subjectId was null or undefined when calling canAddNewEditionForCurrentSemester.');
            }
            const localVarPath = `/api/editions/canCreate/current/{subjectId}`
                .replace(`{${"subjectId"}}`, encodeURIComponent(String(subjectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can delete an edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneEdition(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canDeleteOneEdition.');
            }
            const localVarPath = `/api/editions/canDelete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can modify an edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canModifyOneEdition(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canModifyOneEdition.');
            }
            const localVarPath = `/api/editions/canEdit/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneEdition(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneEdition.');
            }
            const localVarPath = `/api/editions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all editions
         * @param {number} [subjectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEditions(subjectId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/editions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subjectId !== undefined) {
                localVarQueryParameter['subjectId'] = subjectId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all editions with extra info
         * @param {number} [subjectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExtendedEditions(subjectId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/editions/extended`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (subjectId !== undefined) {
                localVarQueryParameter['subjectId'] = subjectId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current edition of a subject
         * @param {number} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentEdition(subjectId: number, options: any = {}): FetchArgs {
            // verify required parameter 'subjectId' is not null or undefined
            if (subjectId === null || subjectId === undefined) {
                throw new RequiredError('subjectId','Required parameter subjectId was null or undefined when calling getCurrentEdition.');
            }
            const localVarPath = `/api/editions/current/{subjectId}`
                .replace(`{${"subjectId"}}`, encodeURIComponent(String(subjectId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneEdition(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneEdition.');
            }
            const localVarPath = `/api/editions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific edition with extra information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExtendedEdition(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneExtendedEdition.');
            }
            const localVarPath = `/api/editions/extended/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current edition of a subject
         * @param {number} subjectId 
         * @param {number} year 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecificEdition(subjectId: number, year: number, semester: number, options: any = {}): FetchArgs {
            // verify required parameter 'subjectId' is not null or undefined
            if (subjectId === null || subjectId === undefined) {
                throw new RequiredError('subjectId','Required parameter subjectId was null or undefined when calling getSpecificEdition.');
            }
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling getSpecificEdition.');
            }
            // verify required parameter 'semester' is not null or undefined
            if (semester === null || semester === undefined) {
                throw new RequiredError('semester','Required parameter semester was null or undefined when calling getSpecificEdition.');
            }
            const localVarPath = `/api/editions/current/{subjectId}/{year}/{semester}`
                .replace(`{${"subjectId"}}`, encodeURIComponent(String(subjectId)))
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"semester"}}`, encodeURIComponent(String(semester)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify the head lecturers of an edition
         * @param {string} id 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyHeadLecturersOfEdition(id: string, body?: Array<number>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyHeadLecturersOfEdition.');
            }
            const localVarPath = `/api/editions/{id}/lecturers`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;number&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify an edition
         * @param {string} id 
         * @param {EditionDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneEdition(id: string, body?: EditionDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneEdition.');
            }
            const localVarPath = `/api/editions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EditionDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EditionsApi - functional programming interface
 * @export
 */
export const EditionsApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new edition
         * @param {EditionDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewEdition(body?: EditionDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditionDTO> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).addNewEdition(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a new edition for a subject for the current semester
         * @param {number} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewEditionForCurrentSemester(subjectId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditionDTO> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).addNewEditionForCurrentSemester(subjectId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new edition for a subject for the current semester
         * @param {number} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewEditionForCurrentSemester(subjectId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).canAddNewEditionForCurrentSemester(subjectId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can delete an edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneEdition(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).canDeleteOneEdition(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can modify an edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canModifyOneEdition(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).canModifyOneEdition(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete an edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneEdition(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).deleteOneEdition(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all editions
         * @param {number} [subjectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEditions(subjectId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EditionDTO>> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).getAllEditions(subjectId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all editions with extra info
         * @param {number} [subjectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExtendedEditions(subjectId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EditionExtendedDTO>> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).getAllExtendedEditions(subjectId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the current edition of a subject
         * @param {number} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentEdition(subjectId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditionDTO> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).getCurrentEdition(subjectId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneEdition(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditionDTO> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).getOneEdition(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific edition with extra information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExtendedEdition(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditionExtendedDTO> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).getOneExtendedEdition(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the current edition of a subject
         * @param {number} subjectId 
         * @param {number} year 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecificEdition(subjectId: number, year: number, semester: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditionDTO> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).getSpecificEdition(subjectId, year, semester, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify the head lecturers of an edition
         * @param {string} id 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyHeadLecturersOfEdition(id: string, body?: Array<number>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditionDTO> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).modifyHeadLecturersOfEdition(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify an edition
         * @param {string} id 
         * @param {EditionDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneEdition(id: string, body?: EditionDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EditionDTO> {
            const localVarFetchArgs = EditionsApiFetchParamCreator(configuration).modifyOneEdition(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EditionsApi - factory interface
 * @export
 */
export const EditionsApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new edition
         * @param {EditionDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewEdition(body?: EditionDTO, options?: any) {
            return EditionsApiFp(configuration).addNewEdition(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a new edition for a subject for the current semester
         * @param {number} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewEditionForCurrentSemester(subjectId: number, options?: any) {
            return EditionsApiFp(configuration).addNewEditionForCurrentSemester(subjectId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can create a new edition for a subject for the current semester
         * @param {number} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewEditionForCurrentSemester(subjectId: number, options?: any) {
            return EditionsApiFp(configuration).canAddNewEditionForCurrentSemester(subjectId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can delete an edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneEdition(id: string, options?: any) {
            return EditionsApiFp(configuration).canDeleteOneEdition(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can modify an edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canModifyOneEdition(id: string, options?: any) {
            return EditionsApiFp(configuration).canModifyOneEdition(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete an edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneEdition(id: string, options?: any) {
            return EditionsApiFp(configuration).deleteOneEdition(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all editions
         * @param {number} [subjectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEditions(subjectId?: number, options?: any) {
            return EditionsApiFp(configuration).getAllEditions(subjectId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all editions with extra info
         * @param {number} [subjectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExtendedEditions(subjectId?: number, options?: any) {
            return EditionsApiFp(configuration).getAllExtendedEditions(subjectId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the current edition of a subject
         * @param {number} subjectId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentEdition(subjectId: number, options?: any) {
            return EditionsApiFp(configuration).getCurrentEdition(subjectId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific edition
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneEdition(id: string, options?: any) {
            return EditionsApiFp(configuration).getOneEdition(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific edition with extra information
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExtendedEdition(id: string, options?: any) {
            return EditionsApiFp(configuration).getOneExtendedEdition(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the current edition of a subject
         * @param {number} subjectId 
         * @param {number} year 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSpecificEdition(subjectId: number, year: number, semester: number, options?: any) {
            return EditionsApiFp(configuration).getSpecificEdition(subjectId, year, semester, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify the head lecturers of an edition
         * @param {string} id 
         * @param {Array<number>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyHeadLecturersOfEdition(id: string, body?: Array<number>, options?: any) {
            return EditionsApiFp(configuration).modifyHeadLecturersOfEdition(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify an edition
         * @param {string} id 
         * @param {EditionDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneEdition(id: string, body?: EditionDTO, options?: any) {
            return EditionsApiFp(configuration).modifyOneEdition(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * EditionsApi - object-oriented interface
 * @export
 * @class EditionsApi
 * @extends {BaseAPI}
 */
export class EditionsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new edition
     * @param {EditionDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public addNewEdition(body?: EditionDTO, options?: any) {
        return EditionsApiFp(this.configuration).addNewEdition(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a new edition for a subject for the current semester
     * @param {number} subjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public addNewEditionForCurrentSemester(subjectId: number, options?: any) {
        return EditionsApiFp(this.configuration).addNewEditionForCurrentSemester(subjectId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can create a new edition for a subject for the current semester
     * @param {number} subjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public canAddNewEditionForCurrentSemester(subjectId: number, options?: any) {
        return EditionsApiFp(this.configuration).canAddNewEditionForCurrentSemester(subjectId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can delete an edition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public canDeleteOneEdition(id: string, options?: any) {
        return EditionsApiFp(this.configuration).canDeleteOneEdition(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can modify an edition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public canModifyOneEdition(id: string, options?: any) {
        return EditionsApiFp(this.configuration).canModifyOneEdition(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete an edition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public deleteOneEdition(id: string, options?: any) {
        return EditionsApiFp(this.configuration).deleteOneEdition(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all editions
     * @param {number} [subjectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public getAllEditions(subjectId?: number, options?: any) {
        return EditionsApiFp(this.configuration).getAllEditions(subjectId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all editions with extra info
     * @param {number} [subjectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public getAllExtendedEditions(subjectId?: number, options?: any) {
        return EditionsApiFp(this.configuration).getAllExtendedEditions(subjectId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the current edition of a subject
     * @param {number} subjectId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public getCurrentEdition(subjectId: number, options?: any) {
        return EditionsApiFp(this.configuration).getCurrentEdition(subjectId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific edition
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public getOneEdition(id: string, options?: any) {
        return EditionsApiFp(this.configuration).getOneEdition(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific edition with extra information
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public getOneExtendedEdition(id: string, options?: any) {
        return EditionsApiFp(this.configuration).getOneExtendedEdition(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the current edition of a subject
     * @param {number} subjectId 
     * @param {number} year 
     * @param {number} semester 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public getSpecificEdition(subjectId: number, year: number, semester: number, options?: any) {
        return EditionsApiFp(this.configuration).getSpecificEdition(subjectId, year, semester, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify the head lecturers of an edition
     * @param {string} id 
     * @param {Array<number>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public modifyHeadLecturersOfEdition(id: string, body?: Array<number>, options?: any) {
        return EditionsApiFp(this.configuration).modifyHeadLecturersOfEdition(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify an edition
     * @param {string} id 
     * @param {EditionDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EditionsApi
     */
    public modifyOneEdition(id: string, body?: EditionDTO, options?: any) {
        return EditionsApiFp(this.configuration).modifyOneEdition(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * EvaluationsApi - fetch parameter creator
 * @export
 */
export const EvaluationsApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new evaluation
         * @param {EvaluationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewEvaluation(body?: EvaluationDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/evaluations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EvaluationDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new evaluation with the principal as applicant
         * @param {SimpleEvaluationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewSimpleEvaluation(body?: SimpleEvaluationDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/evaluations/new`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SimpleEvaluationDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new evaluation
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewEvaluation(editionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'editionId' is not null or undefined
            if (editionId === null || editionId === undefined) {
                throw new RequiredError('editionId','Required parameter editionId was null or undefined when calling canAddNewEvaluation.');
            }
            const localVarPath = `/api/evaluations/canCreate/{editionId}`
                .replace(`{${"editionId"}}`, encodeURIComponent(String(editionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can delete an evaluation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneEvaluation(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canDeleteOneEvaluation.');
            }
            const localVarPath = `/api/evaluations/canDelete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an evaluation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneEvaluation(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneEvaluation.');
            }
            const localVarPath = `/api/evaluations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all evaluations
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluations(departmentId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/evaluations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all evaluations with extra information
         * @param {number} [departmentId] 
         * @param {number} [year] 
         * @param {number} [semester] 
         * @param {number} [subject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluationsExtended(departmentId?: number, year?: number, semester?: number, subject?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/evaluations/extended`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (semester !== undefined) {
                localVarQueryParameter['semester'] = semester;
            }

            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all evaluations of editions the logged lecturer is part of
         * @param {number} [year] 
         * @param {number} [semester] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEvaluations(year?: number, semester?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/evaluations/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (semester !== undefined) {
                localVarQueryParameter['semester'] = semester;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific evaluation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneEvaluation(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneEvaluation.');
            }
            const localVarPath = `/api/evaluations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific evaluation with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneEvaluationExtended(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneEvaluationExtended.');
            }
            const localVarPath = `/api/evaluations/extended/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify an evaluation
         * @param {number} id 
         * @param {EvaluationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneEvaluation(id: number, body?: EvaluationDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneEvaluation.');
            }
            const localVarPath = `/api/evaluations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EvaluationDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the attendance for each vigilant in an evaluation
         * @param {number} id 
         * @param {Array<AttendanceDTO>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttendance(id: number, body?: Array<AttendanceDTO>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setAttendance.');
            }
            const localVarPath = `/api/evaluations/{id}/attendance`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;AttendanceDTO&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Tests if a user can change attendances
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testAttendancePermissions(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling testAttendancePermissions.');
            }
            const localVarPath = `/api/evaluations/{id}/attendance`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EvaluationsApi - functional programming interface
 * @export
 */
export const EvaluationsApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new evaluation
         * @param {EvaluationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewEvaluation(body?: EvaluationDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationDTO> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).addNewEvaluation(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a new evaluation with the principal as applicant
         * @param {SimpleEvaluationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewSimpleEvaluation(body?: SimpleEvaluationDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationDTO> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).addNewSimpleEvaluation(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new evaluation
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewEvaluation(editionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).canAddNewEvaluation(editionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can delete an evaluation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneEvaluation(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).canDeleteOneEvaluation(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete an evaluation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneEvaluation(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).deleteOneEvaluation(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all evaluations
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluations(departmentId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EvaluationDTO>> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).getAllEvaluations(departmentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all evaluations with extra information
         * @param {number} [departmentId] 
         * @param {number} [year] 
         * @param {number} [semester] 
         * @param {number} [subject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluationsExtended(departmentId?: number, year?: number, semester?: number, subject?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EvaluationExtendedDTO>> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).getAllEvaluationsExtended(departmentId, year, semester, subject, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all evaluations of editions the logged lecturer is part of
         * @param {number} [year] 
         * @param {number} [semester] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEvaluations(year?: number, semester?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<EvaluationExtendedDTO>> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).getMyEvaluations(year, semester, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific evaluation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneEvaluation(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationDTO> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).getOneEvaluation(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific evaluation with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneEvaluationExtended(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationExtendedDTO> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).getOneEvaluationExtended(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify an evaluation
         * @param {number} id 
         * @param {EvaluationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneEvaluation(id: number, body?: EvaluationDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EvaluationDTO> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).modifyOneEvaluation(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set the attendance for each vigilant in an evaluation
         * @param {number} id 
         * @param {Array<AttendanceDTO>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttendance(id: number, body?: Array<AttendanceDTO>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).setAttendance(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Tests if a user can change attendances
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testAttendancePermissions(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<boolean> {
            const localVarFetchArgs = EvaluationsApiFetchParamCreator(configuration).testAttendancePermissions(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * EvaluationsApi - factory interface
 * @export
 */
export const EvaluationsApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new evaluation
         * @param {EvaluationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewEvaluation(body?: EvaluationDTO, options?: any) {
            return EvaluationsApiFp(configuration).addNewEvaluation(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a new evaluation with the principal as applicant
         * @param {SimpleEvaluationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewSimpleEvaluation(body?: SimpleEvaluationDTO, options?: any) {
            return EvaluationsApiFp(configuration).addNewSimpleEvaluation(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can create a new evaluation
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewEvaluation(editionId: string, options?: any) {
            return EvaluationsApiFp(configuration).canAddNewEvaluation(editionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can delete an evaluation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneEvaluation(id: number, options?: any) {
            return EvaluationsApiFp(configuration).canDeleteOneEvaluation(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete an evaluation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneEvaluation(id: number, options?: any) {
            return EvaluationsApiFp(configuration).deleteOneEvaluation(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all evaluations
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluations(departmentId?: number, options?: any) {
            return EvaluationsApiFp(configuration).getAllEvaluations(departmentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all evaluations with extra information
         * @param {number} [departmentId] 
         * @param {number} [year] 
         * @param {number} [semester] 
         * @param {number} [subject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEvaluationsExtended(departmentId?: number, year?: number, semester?: number, subject?: number, options?: any) {
            return EvaluationsApiFp(configuration).getAllEvaluationsExtended(departmentId, year, semester, subject, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all evaluations of editions the logged lecturer is part of
         * @param {number} [year] 
         * @param {number} [semester] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyEvaluations(year?: number, semester?: number, options?: any) {
            return EvaluationsApiFp(configuration).getMyEvaluations(year, semester, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific evaluation
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneEvaluation(id: number, options?: any) {
            return EvaluationsApiFp(configuration).getOneEvaluation(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific evaluation with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneEvaluationExtended(id: number, options?: any) {
            return EvaluationsApiFp(configuration).getOneEvaluationExtended(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify an evaluation
         * @param {number} id 
         * @param {EvaluationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneEvaluation(id: number, body?: EvaluationDTO, options?: any) {
            return EvaluationsApiFp(configuration).modifyOneEvaluation(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set the attendance for each vigilant in an evaluation
         * @param {number} id 
         * @param {Array<AttendanceDTO>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setAttendance(id: number, body?: Array<AttendanceDTO>, options?: any) {
            return EvaluationsApiFp(configuration).setAttendance(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Tests if a user can change attendances
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testAttendancePermissions(id: number, options?: any) {
            return EvaluationsApiFp(configuration).testAttendancePermissions(id, options)(fetch, basePath);
        },
    };
};

/**
 * EvaluationsApi - object-oriented interface
 * @export
 * @class EvaluationsApi
 * @extends {BaseAPI}
 */
export class EvaluationsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new evaluation
     * @param {EvaluationDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public addNewEvaluation(body?: EvaluationDTO, options?: any) {
        return EvaluationsApiFp(this.configuration).addNewEvaluation(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a new evaluation with the principal as applicant
     * @param {SimpleEvaluationDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public addNewSimpleEvaluation(body?: SimpleEvaluationDTO, options?: any) {
        return EvaluationsApiFp(this.configuration).addNewSimpleEvaluation(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can create a new evaluation
     * @param {string} editionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public canAddNewEvaluation(editionId: string, options?: any) {
        return EvaluationsApiFp(this.configuration).canAddNewEvaluation(editionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can delete an evaluation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public canDeleteOneEvaluation(id: number, options?: any) {
        return EvaluationsApiFp(this.configuration).canDeleteOneEvaluation(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete an evaluation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public deleteOneEvaluation(id: number, options?: any) {
        return EvaluationsApiFp(this.configuration).deleteOneEvaluation(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all evaluations
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public getAllEvaluations(departmentId?: number, options?: any) {
        return EvaluationsApiFp(this.configuration).getAllEvaluations(departmentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all evaluations with extra information
     * @param {number} [departmentId] 
     * @param {number} [year] 
     * @param {number} [semester] 
     * @param {number} [subject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public getAllEvaluationsExtended(departmentId?: number, year?: number, semester?: number, subject?: number, options?: any) {
        return EvaluationsApiFp(this.configuration).getAllEvaluationsExtended(departmentId, year, semester, subject, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all evaluations of editions the logged lecturer is part of
     * @param {number} [year] 
     * @param {number} [semester] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public getMyEvaluations(year?: number, semester?: number, options?: any) {
        return EvaluationsApiFp(this.configuration).getMyEvaluations(year, semester, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific evaluation
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public getOneEvaluation(id: number, options?: any) {
        return EvaluationsApiFp(this.configuration).getOneEvaluation(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific evaluation with extra information
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public getOneEvaluationExtended(id: number, options?: any) {
        return EvaluationsApiFp(this.configuration).getOneEvaluationExtended(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify an evaluation
     * @param {number} id 
     * @param {EvaluationDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public modifyOneEvaluation(id: number, body?: EvaluationDTO, options?: any) {
        return EvaluationsApiFp(this.configuration).modifyOneEvaluation(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set the attendance for each vigilant in an evaluation
     * @param {number} id 
     * @param {Array<AttendanceDTO>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public setAttendance(id: number, body?: Array<AttendanceDTO>, options?: any) {
        return EvaluationsApiFp(this.configuration).setAttendance(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Tests if a user can change attendances
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EvaluationsApi
     */
    public testAttendancePermissions(id: number, options?: any) {
        return EvaluationsApiFp(this.configuration).testAttendancePermissions(id, options)(this.fetch, this.basePath);
    }

}
/**
 * GlobalApi - fetch parameter creator
 * @export
 */
export const GlobalApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Checks if the principal can move to the next semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canMoveToNextSemester(options: any = {}): FetchArgs {
            const localVarPath = `/api/global/canNextSemester`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a list of (semester, year) that have editions associated with descending order of time.
         * @summary Get a list of all system editions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enumerateSystemEditions(options: any = {}): FetchArgs {
            const localVarPath = `/api/global/enumerateSystemEditions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all global parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options: any = {}): FetchArgs {
            const localVarPath = `/api/global`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the current year and semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearAndSemester(options: any = {}): FetchArgs {
            const localVarPath = `/api/global/yearSemester`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Move to the next semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToNextSemester(options: any = {}): FetchArgs {
            const localVarPath = `/api/global/nextSemester`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set the value and description of one specific global parameters
         * @param {string} id 
         * @param {GlobalParameterDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOne(id: string, body?: GlobalParameterDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setOne.');
            }
            const localVarPath = `/api/global/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GlobalParameterDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GlobalApi - functional programming interface
 * @export
 */
export const GlobalApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Checks if the principal can move to the next semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canMoveToNextSemester(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GlobalApiFetchParamCreator(configuration).canMoveToNextSemester(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns a list of (semester, year) that have editions associated with descending order of time.
         * @summary Get a list of all system editions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enumerateSystemEditions(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<YearSemesterDTO>> {
            const localVarFetchArgs = GlobalApiFetchParamCreator(configuration).enumerateSystemEditions(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all global parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GlobalParameterDTO>> {
            const localVarFetchArgs = GlobalApiFetchParamCreator(configuration).getAll(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the current year and semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearAndSemester(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<YearSemesterDTO> {
            const localVarFetchArgs = GlobalApiFetchParamCreator(configuration).getYearAndSemester(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Move to the next semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToNextSemester(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GlobalApiFetchParamCreator(configuration).moveToNextSemester(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Set the value and description of one specific global parameters
         * @param {string} id 
         * @param {GlobalParameterDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOne(id: string, body?: GlobalParameterDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GlobalParameterDTO> {
            const localVarFetchArgs = GlobalApiFetchParamCreator(configuration).setOne(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GlobalApi - factory interface
 * @export
 */
export const GlobalApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Checks if the principal can move to the next semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canMoveToNextSemester(options?: any) {
            return GlobalApiFp(configuration).canMoveToNextSemester(options)(fetch, basePath);
        },
        /**
         * Returns a list of (semester, year) that have editions associated with descending order of time.
         * @summary Get a list of all system editions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enumerateSystemEditions(options?: any) {
            return GlobalApiFp(configuration).enumerateSystemEditions(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all global parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any) {
            return GlobalApiFp(configuration).getAll(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the current year and semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getYearAndSemester(options?: any) {
            return GlobalApiFp(configuration).getYearAndSemester(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Move to the next semester
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveToNextSemester(options?: any) {
            return GlobalApiFp(configuration).moveToNextSemester(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Set the value and description of one specific global parameters
         * @param {string} id 
         * @param {GlobalParameterDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOne(id: string, body?: GlobalParameterDTO, options?: any) {
            return GlobalApiFp(configuration).setOne(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * GlobalApi - object-oriented interface
 * @export
 * @class GlobalApi
 * @extends {BaseAPI}
 */
export class GlobalApi extends BaseAPI {
    /**
     * 
     * @summary Checks if the principal can move to the next semester
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public canMoveToNextSemester(options?: any) {
        return GlobalApiFp(this.configuration).canMoveToNextSemester(options)(this.fetch, this.basePath);
    }

    /**
     * Returns a list of (semester, year) that have editions associated with descending order of time.
     * @summary Get a list of all system editions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public enumerateSystemEditions(options?: any) {
        return GlobalApiFp(this.configuration).enumerateSystemEditions(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all global parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public getAll(options?: any) {
        return GlobalApiFp(this.configuration).getAll(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the current year and semester
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public getYearAndSemester(options?: any) {
        return GlobalApiFp(this.configuration).getYearAndSemester(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Move to the next semester
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public moveToNextSemester(options?: any) {
        return GlobalApiFp(this.configuration).moveToNextSemester(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Set the value and description of one specific global parameters
     * @param {string} id 
     * @param {GlobalParameterDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GlobalApi
     */
    public setOne(id: string, body?: GlobalParameterDTO, options?: any) {
        return GlobalApiFp(this.configuration).setOne(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * LecturersApi - fetch parameter creator
 * @export
 */
export const LecturersApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Add a new edition to a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEditionToLecturer(id: number, editionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addEditionToLecturer.');
            }
            // verify required parameter 'editionId' is not null or undefined
            if (editionId === null || editionId === undefined) {
                throw new RequiredError('editionId','Required parameter editionId was null or undefined when calling addEditionToLecturer.');
            }
            const localVarPath = `/api/lecturers/{id}/edition/{editionId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"editionId"}}`, encodeURIComponent(String(editionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add a new edition to be headed by a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHeadEditionToLecturer(id: number, editionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling addHeadEditionToLecturer.');
            }
            // verify required parameter 'editionId' is not null or undefined
            if (editionId === null || editionId === undefined) {
                throw new RequiredError('editionId','Required parameter editionId was null or undefined when calling addHeadEditionToLecturer.');
            }
            const localVarPath = `/api/lecturers/{id}/editionHeading/{editionId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"editionId"}}`, encodeURIComponent(String(editionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * If password is not present, a random one is generated.
         * @summary Create a new lecturer
         * @param {SignUpLecturerDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewLecturer(body?: SignUpLecturerDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/lecturers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SignUpLecturerDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can modify a lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canModifyOneLecturer(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canModifyOneLecturer.');
            }
            const localVarPath = `/api/lecturers/canModify/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can toggle the vigilant status of a specific lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canToggleVigilantStatus(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canToggleVigilantStatus.');
            }
            const localVarPath = `/api/lecturers/{id}/canSetVigilantStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an edition to a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEditionFromLecturer(id: number, editionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteEditionFromLecturer.');
            }
            // verify required parameter 'editionId' is not null or undefined
            if (editionId === null || editionId === undefined) {
                throw new RequiredError('editionId','Required parameter editionId was null or undefined when calling deleteEditionFromLecturer.');
            }
            const localVarPath = `/api/lecturers/{id}/edition/{editionId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"editionId"}}`, encodeURIComponent(String(editionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Add an edition headed by a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHeadEditionFromLecturer(id: number, editionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteHeadEditionFromLecturer.');
            }
            // verify required parameter 'editionId' is not null or undefined
            if (editionId === null || editionId === undefined) {
                throw new RequiredError('editionId','Required parameter editionId was null or undefined when calling deleteHeadEditionFromLecturer.');
            }
            const localVarPath = `/api/lecturers/{id}/editionHeading/{editionId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"editionId"}}`, encodeURIComponent(String(editionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all lecturers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLecturers(options: any = {}): FetchArgs {
            const localVarPath = `/api/lecturers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available lecturers for a evaluation
         * @param {number} evaluationId The id of the evaluation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableForEvaluation(evaluationId: number, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling getAvailableForEvaluation.');
            }
            const localVarPath = `/api/lecturers/available/{evaluationId}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get available lecturers for a evaluation, including lecturers with rejected requests for the evaluation
         * @param {number} evaluationId The id of the evaluation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableForEvaluationIncludeRejected(evaluationId: number, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling getAvailableForEvaluationIncludeRejected.');
            }
            const localVarPath = `/api/lecturers/availableIncRej/{evaluationId}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the currently authenticated lecturer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeLecturer(options: any = {}): FetchArgs {
            const localVarPath = `/api/lecturers/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneLecturer(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneLecturer.');
            }
            const localVarPath = `/api/lecturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify a lecturer
         * @param {number} id 
         * @param {LecturerDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneLecturer(id: number, body?: LecturerDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneLecturer.');
            }
            const localVarPath = `/api/lecturers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LecturerDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Toggle the vigilant status of a specific lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleVigilantStatus(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling toggleVigilantStatus.');
            }
            const localVarPath = `/api/lecturers/{id}/vigilantStatus`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LecturersApi - functional programming interface
 * @export
 */
export const LecturersApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Add a new edition to a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEditionToLecturer(id: number, editionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LecturerDTO> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).addEditionToLecturer(id, editionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add a new edition to be headed by a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHeadEditionToLecturer(id: number, editionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LecturerDTO> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).addHeadEditionToLecturer(id, editionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * If password is not present, a random one is generated.
         * @summary Create a new lecturer
         * @param {SignUpLecturerDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewLecturer(body?: SignUpLecturerDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LecturerDTO> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).addNewLecturer(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can modify a lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canModifyOneLecturer(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).canModifyOneLecturer(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can toggle the vigilant status of a specific lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canToggleVigilantStatus(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).canToggleVigilantStatus(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete an edition to a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEditionFromLecturer(id: number, editionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LecturerDTO> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).deleteEditionFromLecturer(id, editionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Add an edition headed by a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHeadEditionFromLecturer(id: number, editionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LecturerDTO> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).deleteHeadEditionFromLecturer(id, editionId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all lecturers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLecturers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LecturerDTO>> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).getAllLecturers(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get available lecturers for a evaluation
         * @param {number} evaluationId The id of the evaluation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableForEvaluation(evaluationId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LecturerDTO>> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).getAvailableForEvaluation(evaluationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get available lecturers for a evaluation, including lecturers with rejected requests for the evaluation
         * @param {number} evaluationId The id of the evaluation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableForEvaluationIncludeRejected(evaluationId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LecturerDTO>> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).getAvailableForEvaluationIncludeRejected(evaluationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the currently authenticated lecturer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeLecturer(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LecturerDTO> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).getMeLecturer(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneLecturer(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LecturerDTO> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).getOneLecturer(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify a lecturer
         * @param {number} id 
         * @param {LecturerDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneLecturer(id: number, body?: LecturerDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LecturerDTO> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).modifyOneLecturer(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Toggle the vigilant status of a specific lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleVigilantStatus(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LecturerDTO> {
            const localVarFetchArgs = LecturersApiFetchParamCreator(configuration).toggleVigilantStatus(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LecturersApi - factory interface
 * @export
 */
export const LecturersApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Add a new edition to a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addEditionToLecturer(id: number, editionId: string, options?: any) {
            return LecturersApiFp(configuration).addEditionToLecturer(id, editionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add a new edition to be headed by a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addHeadEditionToLecturer(id: number, editionId: string, options?: any) {
            return LecturersApiFp(configuration).addHeadEditionToLecturer(id, editionId, options)(fetch, basePath);
        },
        /**
         * If password is not present, a random one is generated.
         * @summary Create a new lecturer
         * @param {SignUpLecturerDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewLecturer(body?: SignUpLecturerDTO, options?: any) {
            return LecturersApiFp(configuration).addNewLecturer(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can modify a lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canModifyOneLecturer(id: number, options?: any) {
            return LecturersApiFp(configuration).canModifyOneLecturer(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can toggle the vigilant status of a specific lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canToggleVigilantStatus(id: number, options?: any) {
            return LecturersApiFp(configuration).canToggleVigilantStatus(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete an edition to a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEditionFromLecturer(id: number, editionId: string, options?: any) {
            return LecturersApiFp(configuration).deleteEditionFromLecturer(id, editionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Add an edition headed by a lecturer
         * @param {number} id 
         * @param {string} editionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHeadEditionFromLecturer(id: number, editionId: string, options?: any) {
            return LecturersApiFp(configuration).deleteHeadEditionFromLecturer(id, editionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all lecturers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllLecturers(options?: any) {
            return LecturersApiFp(configuration).getAllLecturers(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get available lecturers for a evaluation
         * @param {number} evaluationId The id of the evaluation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableForEvaluation(evaluationId: number, options?: any) {
            return LecturersApiFp(configuration).getAvailableForEvaluation(evaluationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get available lecturers for a evaluation, including lecturers with rejected requests for the evaluation
         * @param {number} evaluationId The id of the evaluation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAvailableForEvaluationIncludeRejected(evaluationId: number, options?: any) {
            return LecturersApiFp(configuration).getAvailableForEvaluationIncludeRejected(evaluationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the currently authenticated lecturer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeLecturer(options?: any) {
            return LecturersApiFp(configuration).getMeLecturer(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneLecturer(id: number, options?: any) {
            return LecturersApiFp(configuration).getOneLecturer(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify a lecturer
         * @param {number} id 
         * @param {LecturerDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneLecturer(id: number, body?: LecturerDTO, options?: any) {
            return LecturersApiFp(configuration).modifyOneLecturer(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Toggle the vigilant status of a specific lecturer
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleVigilantStatus(id: number, options?: any) {
            return LecturersApiFp(configuration).toggleVigilantStatus(id, options)(fetch, basePath);
        },
    };
};

/**
 * LecturersApi - object-oriented interface
 * @export
 * @class LecturersApi
 * @extends {BaseAPI}
 */
export class LecturersApi extends BaseAPI {
    /**
     * 
     * @summary Add a new edition to a lecturer
     * @param {number} id 
     * @param {string} editionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public addEditionToLecturer(id: number, editionId: string, options?: any) {
        return LecturersApiFp(this.configuration).addEditionToLecturer(id, editionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add a new edition to be headed by a lecturer
     * @param {number} id 
     * @param {string} editionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public addHeadEditionToLecturer(id: number, editionId: string, options?: any) {
        return LecturersApiFp(this.configuration).addHeadEditionToLecturer(id, editionId, options)(this.fetch, this.basePath);
    }

    /**
     * If password is not present, a random one is generated.
     * @summary Create a new lecturer
     * @param {SignUpLecturerDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public addNewLecturer(body?: SignUpLecturerDTO, options?: any) {
        return LecturersApiFp(this.configuration).addNewLecturer(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can modify a lecturer
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public canModifyOneLecturer(id: number, options?: any) {
        return LecturersApiFp(this.configuration).canModifyOneLecturer(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can toggle the vigilant status of a specific lecturer
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public canToggleVigilantStatus(id: number, options?: any) {
        return LecturersApiFp(this.configuration).canToggleVigilantStatus(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete an edition to a lecturer
     * @param {number} id 
     * @param {string} editionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public deleteEditionFromLecturer(id: number, editionId: string, options?: any) {
        return LecturersApiFp(this.configuration).deleteEditionFromLecturer(id, editionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Add an edition headed by a lecturer
     * @param {number} id 
     * @param {string} editionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public deleteHeadEditionFromLecturer(id: number, editionId: string, options?: any) {
        return LecturersApiFp(this.configuration).deleteHeadEditionFromLecturer(id, editionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all lecturers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public getAllLecturers(options?: any) {
        return LecturersApiFp(this.configuration).getAllLecturers(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get available lecturers for a evaluation
     * @param {number} evaluationId The id of the evaluation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public getAvailableForEvaluation(evaluationId: number, options?: any) {
        return LecturersApiFp(this.configuration).getAvailableForEvaluation(evaluationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get available lecturers for a evaluation, including lecturers with rejected requests for the evaluation
     * @param {number} evaluationId The id of the evaluation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public getAvailableForEvaluationIncludeRejected(evaluationId: number, options?: any) {
        return LecturersApiFp(this.configuration).getAvailableForEvaluationIncludeRejected(evaluationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the currently authenticated lecturer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public getMeLecturer(options?: any) {
        return LecturersApiFp(this.configuration).getMeLecturer(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific lecturer
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public getOneLecturer(id: number, options?: any) {
        return LecturersApiFp(this.configuration).getOneLecturer(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify a lecturer
     * @param {number} id 
     * @param {LecturerDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public modifyOneLecturer(id: number, body?: LecturerDTO, options?: any) {
        return LecturersApiFp(this.configuration).modifyOneLecturer(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Toggle the vigilant status of a specific lecturer
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LecturersApi
     */
    public toggleVigilantStatus(id: number, options?: any) {
        return LecturersApiFp(this.configuration).toggleVigilantStatus(id, options)(this.fetch, this.basePath);
    }

}
/**
 * LogsApi - fetch parameter creator
 * @export
 */
export const LogsApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * Returns the department's logs, ordered by date in decreasing order
         * @summary Get the logs of a department
         * @param {number} departmentId 
         * @param {number} size 
         * @param {LogFilter} [body] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentLogs(departmentId: number, size: number, body?: LogFilter, page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'departmentId' is not null or undefined
            if (departmentId === null || departmentId === undefined) {
                throw new RequiredError('departmentId','Required parameter departmentId was null or undefined when calling getDepartmentLogs.');
            }
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getDepartmentLogs.');
            }
            const localVarPath = `/api/logs/department/{departmentId}/{size}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"size"}}`, encodeURIComponent(String(size)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LogFilter" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all possible log levels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogLevels(options: any = {}): FetchArgs {
            const localVarPath = `/api/logs/log_levels`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the department's logs, ordered by date in decreasing order
         * @summary Get the logs of a department
         * @param {number} size 
         * @param {LogFilter} [body] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(size: number, body?: LogFilter, page?: number, options: any = {}): FetchArgs {
            // verify required parameter 'size' is not null or undefined
            if (size === null || size === undefined) {
                throw new RequiredError('size','Required parameter size was null or undefined when calling getLogs.');
            }
            const localVarPath = `/api/logs/{size}`
                .replace(`{${"size"}}`, encodeURIComponent(String(size)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LogFilter" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function(configuration: Configuration) {
    return {
        /**
         * Returns the department's logs, ordered by date in decreasing order
         * @summary Get the logs of a department
         * @param {number} departmentId 
         * @param {number} size 
         * @param {LogFilter} [body] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentLogs(departmentId: number, size: number, body?: LogFilter, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLogEntryDTO> {
            const localVarFetchArgs = LogsApiFetchParamCreator(configuration).getDepartmentLogs(departmentId, size, body, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all possible log levels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogLevels(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = LogsApiFetchParamCreator(configuration).getLogLevels(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Returns the department's logs, ordered by date in decreasing order
         * @summary Get the logs of a department
         * @param {number} size 
         * @param {LogFilter} [body] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(size: number, body?: LogFilter, page?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageLogEntryDTO> {
            const localVarFetchArgs = LogsApiFetchParamCreator(configuration).getLogs(size, body, page, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Returns the department's logs, ordered by date in decreasing order
         * @summary Get the logs of a department
         * @param {number} departmentId 
         * @param {number} size 
         * @param {LogFilter} [body] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentLogs(departmentId: number, size: number, body?: LogFilter, page?: number, options?: any) {
            return LogsApiFp(configuration).getDepartmentLogs(departmentId, size, body, page, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all possible log levels
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogLevels(options?: any) {
            return LogsApiFp(configuration).getLogLevels(options)(fetch, basePath);
        },
        /**
         * Returns the department's logs, ordered by date in decreasing order
         * @summary Get the logs of a department
         * @param {number} size 
         * @param {LogFilter} [body] 
         * @param {number} [page] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(size: number, body?: LogFilter, page?: number, options?: any) {
            return LogsApiFp(configuration).getLogs(size, body, page, options)(fetch, basePath);
        },
    };
};

/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     * Returns the department's logs, ordered by date in decreasing order
     * @summary Get the logs of a department
     * @param {number} departmentId 
     * @param {number} size 
     * @param {LogFilter} [body] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public getDepartmentLogs(departmentId: number, size: number, body?: LogFilter, page?: number, options?: any) {
        return LogsApiFp(this.configuration).getDepartmentLogs(departmentId, size, body, page, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all possible log levels
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public getLogLevels(options?: any) {
        return LogsApiFp(this.configuration).getLogLevels(options)(this.fetch, this.basePath);
    }

    /**
     * Returns the department's logs, ordered by date in decreasing order
     * @summary Get the logs of a department
     * @param {number} size 
     * @param {LogFilter} [body] 
     * @param {number} [page] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    public getLogs(size: number, body?: LogFilter, page?: number, options?: any) {
        return LogsApiFp(this.configuration).getLogs(size, body, page, options)(this.fetch, this.basePath);
    }

}
/**
 * RejectionTypesApi - fetch parameter creator
 * @export
 */
export const RejectionTypesApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new rejection type
         * @param {RejectionTypeDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewRejectionType(body?: RejectionTypeDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/rejection-types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RejectionTypeDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a rejection type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneRejectionType(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneRejectionType.');
            }
            const localVarPath = `/api/rejection-types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all rejection types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRejectionTypes(options: any = {}): FetchArgs {
            const localVarPath = `/api/rejection-types`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RejectionTypesApi - functional programming interface
 * @export
 */
export const RejectionTypesApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new rejection type
         * @param {RejectionTypeDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewRejectionType(body?: RejectionTypeDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RejectionTypesApiFetchParamCreator(configuration).addNewRejectionType(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a rejection type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneRejectionType(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RejectionTypesApiFetchParamCreator(configuration).deleteOneRejectionType(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all rejection types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRejectionTypes(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RejectionTypeDTO>> {
            const localVarFetchArgs = RejectionTypesApiFetchParamCreator(configuration).getAllRejectionTypes(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RejectionTypesApi - factory interface
 * @export
 */
export const RejectionTypesApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new rejection type
         * @param {RejectionTypeDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewRejectionType(body?: RejectionTypeDTO, options?: any) {
            return RejectionTypesApiFp(configuration).addNewRejectionType(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a rejection type
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneRejectionType(id: number, options?: any) {
            return RejectionTypesApiFp(configuration).deleteOneRejectionType(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all rejection types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRejectionTypes(options?: any) {
            return RejectionTypesApiFp(configuration).getAllRejectionTypes(options)(fetch, basePath);
        },
    };
};

/**
 * RejectionTypesApi - object-oriented interface
 * @export
 * @class RejectionTypesApi
 * @extends {BaseAPI}
 */
export class RejectionTypesApi extends BaseAPI {
    /**
     * 
     * @summary Create a new rejection type
     * @param {RejectionTypeDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RejectionTypesApi
     */
    public addNewRejectionType(body?: RejectionTypeDTO, options?: any) {
        return RejectionTypesApiFp(this.configuration).addNewRejectionType(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a rejection type
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RejectionTypesApi
     */
    public deleteOneRejectionType(id: number, options?: any) {
        return RejectionTypesApiFp(this.configuration).deleteOneRejectionType(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all rejection types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RejectionTypesApi
     */
    public getAllRejectionTypes(options?: any) {
        return RejectionTypesApiFp(this.configuration).getAllRejectionTypes(options)(this.fetch, this.basePath);
    }

}
/**
 * RolesApi - fetch parameter creator
 * @export
 */
export const RolesApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new role
         * @param {RoleDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewRole(body?: RoleDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/roles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RoleDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a role
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneRole(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneRole.');
            }
            const localVarPath = `/api/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoles(options: any = {}): FetchArgs {
            const localVarPath = `/api/roles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific role
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRole(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneRole.');
            }
            const localVarPath = `/api/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify a role
         * @param {string} id 
         * @param {RoleDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneRole(id: string, body?: RoleDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneRole.');
            }
            const localVarPath = `/api/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RoleDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new role
         * @param {RoleDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewRole(body?: RoleDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleDTO> {
            const localVarFetchArgs = RolesApiFetchParamCreator(configuration).addNewRole(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a role
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneRole(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RolesApiFetchParamCreator(configuration).deleteOneRole(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoles(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RoleDTO>> {
            const localVarFetchArgs = RolesApiFetchParamCreator(configuration).getAllRoles(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific role
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRole(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleDTO> {
            const localVarFetchArgs = RolesApiFetchParamCreator(configuration).getOneRole(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify a role
         * @param {string} id 
         * @param {RoleDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneRole(id: string, body?: RoleDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoleDTO> {
            const localVarFetchArgs = RolesApiFetchParamCreator(configuration).modifyOneRole(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new role
         * @param {RoleDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewRole(body?: RoleDTO, options?: any) {
            return RolesApiFp(configuration).addNewRole(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a role
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneRole(id: string, options?: any) {
            return RolesApiFp(configuration).deleteOneRole(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all roles
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRoles(options?: any) {
            return RolesApiFp(configuration).getAllRoles(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific role
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRole(id: string, options?: any) {
            return RolesApiFp(configuration).getOneRole(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify a role
         * @param {string} id 
         * @param {RoleDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneRole(id: string, body?: RoleDTO, options?: any) {
            return RolesApiFp(configuration).modifyOneRole(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @summary Create a new role
     * @param {RoleDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public addNewRole(body?: RoleDTO, options?: any) {
        return RolesApiFp(this.configuration).addNewRole(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a role
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public deleteOneRole(id: string, options?: any) {
        return RolesApiFp(this.configuration).deleteOneRole(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all roles
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getAllRoles(options?: any) {
        return RolesApiFp(this.configuration).getAllRoles(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific role
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public getOneRole(id: string, options?: any) {
        return RolesApiFp(this.configuration).getOneRole(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify a role
     * @param {string} id 
     * @param {RoleDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public modifyOneRole(id: string, body?: RoleDTO, options?: any) {
        return RolesApiFp(this.configuration).modifyOneRole(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * RoomsApi - fetch parameter creator
 * @export
 */
export const RoomsApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new room
         * @param {RoomDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewRoom(body?: RoomDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/rooms`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RoomDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneRoom(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneRoom.');
            }
            const localVarPath = `/api/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRooms(options: any = {}): FetchArgs {
            const localVarPath = `/api/rooms`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRoom(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneRoom.');
            }
            const localVarPath = `/api/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify a room
         * @param {number} id 
         * @param {RoomDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneRoom(id: number, body?: RoomDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneRoom.');
            }
            const localVarPath = `/api/rooms/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RoomDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RoomsApi - functional programming interface
 * @export
 */
export const RoomsApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new room
         * @param {RoomDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewRoom(body?: RoomDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoomDTO> {
            const localVarFetchArgs = RoomsApiFetchParamCreator(configuration).addNewRoom(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneRoom(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = RoomsApiFetchParamCreator(configuration).deleteOneRoom(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRooms(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RoomDTO>> {
            const localVarFetchArgs = RoomsApiFetchParamCreator(configuration).getAllRooms(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRoom(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoomDTO> {
            const localVarFetchArgs = RoomsApiFetchParamCreator(configuration).getOneRoom(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify a room
         * @param {number} id 
         * @param {RoomDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneRoom(id: number, body?: RoomDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RoomDTO> {
            const localVarFetchArgs = RoomsApiFetchParamCreator(configuration).modifyOneRoom(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RoomsApi - factory interface
 * @export
 */
export const RoomsApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new room
         * @param {RoomDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewRoom(body?: RoomDTO, options?: any) {
            return RoomsApiFp(configuration).addNewRoom(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneRoom(id: number, options?: any) {
            return RoomsApiFp(configuration).deleteOneRoom(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all rooms
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRooms(options?: any) {
            return RoomsApiFp(configuration).getAllRooms(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific room
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRoom(id: number, options?: any) {
            return RoomsApiFp(configuration).getOneRoom(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify a room
         * @param {number} id 
         * @param {RoomDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneRoom(id: number, body?: RoomDTO, options?: any) {
            return RoomsApiFp(configuration).modifyOneRoom(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * RoomsApi - object-oriented interface
 * @export
 * @class RoomsApi
 * @extends {BaseAPI}
 */
export class RoomsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new room
     * @param {RoomDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public addNewRoom(body?: RoomDTO, options?: any) {
        return RoomsApiFp(this.configuration).addNewRoom(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a room
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public deleteOneRoom(id: number, options?: any) {
        return RoomsApiFp(this.configuration).deleteOneRoom(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all rooms
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public getAllRooms(options?: any) {
        return RoomsApiFp(this.configuration).getAllRooms(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific room
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public getOneRoom(id: number, options?: any) {
        return RoomsApiFp(this.configuration).getOneRoom(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify a room
     * @param {number} id 
     * @param {RoomDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RoomsApi
     */
    public modifyOneRoom(id: number, body?: RoomDTO, options?: any) {
        return RoomsApiFp(this.configuration).modifyOneRoom(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * SubjectsApi - fetch parameter creator
 * @export
 */
export const SubjectsApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new subject
         * @param {SubjectDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewSubject(body?: SubjectDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/subjects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubjectDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new subject
         * @param {number} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewSubject(departmentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'departmentId' is not null or undefined
            if (departmentId === null || departmentId === undefined) {
                throw new RequiredError('departmentId','Required parameter departmentId was null or undefined when calling canAddNewSubject.');
            }
            const localVarPath = `/api/subjects/canCreate/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can delete a subject
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneSubject(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canDeleteOneSubject.');
            }
            const localVarPath = `/api/subjects/canDelete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a subject
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneSubject(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneSubject.');
            }
            const localVarPath = `/api/subjects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all subjects with similar name in a department
         * @param {number} departmentId 
         * @param {string} subjectName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSimilarSubjects(departmentId: number, subjectName: string, options: any = {}): FetchArgs {
            // verify required parameter 'departmentId' is not null or undefined
            if (departmentId === null || departmentId === undefined) {
                throw new RequiredError('departmentId','Required parameter departmentId was null or undefined when calling getAllSimilarSubjects.');
            }
            // verify required parameter 'subjectName' is not null or undefined
            if (subjectName === null || subjectName === undefined) {
                throw new RequiredError('subjectName','Required parameter subjectName was null or undefined when calling getAllSimilarSubjects.');
            }
            const localVarPath = `/api/subjects/similar/{departmentId}/{subjectName}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)))
                .replace(`{${"subjectName"}}`, encodeURIComponent(String(subjectName)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all subjects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubjects(options: any = {}): FetchArgs {
            const localVarPath = `/api/subjects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all subjects with extra information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubjectsExtended(options: any = {}): FetchArgs {
            const localVarPath = `/api/subjects/extended`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all subjects that have an edition on a given year and semester
         * @param {number} year 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubjectsExtendedWithEditionsOn(year: number, semester: number, options: any = {}): FetchArgs {
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling getAllSubjectsExtendedWithEditionsOn.');
            }
            // verify required parameter 'semester' is not null or undefined
            if (semester === null || semester === undefined) {
                throw new RequiredError('semester','Required parameter semester was null or undefined when calling getAllSubjectsExtendedWithEditionsOn.');
            }
            const localVarPath = `/api/subjects/withEditionOn/{year}/{semester}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"semester"}}`, encodeURIComponent(String(semester)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * As a lecturer or head lecturer.
         * @summary Get all subjects associated with the principal for the current edition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubjectsForCurrentEdition(options: any = {}): FetchArgs {
            const localVarPath = `/api/subjects/myCurrentSubjects`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * As a lecturer or head lecturer.
         * @summary Get all subjects associated with the principal for a specific year and semester
         * @param {number} year 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubjectsForSpecificEdition(year: number, semester: number, options: any = {}): FetchArgs {
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling getMySubjectsForSpecificEdition.');
            }
            // verify required parameter 'semester' is not null or undefined
            if (semester === null || semester === undefined) {
                throw new RequiredError('semester','Required parameter semester was null or undefined when calling getMySubjectsForSpecificEdition.');
            }
            const localVarPath = `/api/subjects/mySubjectsWithEditionOn/{year}/{semester}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"semester"}}`, encodeURIComponent(String(semester)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific subject
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneSubject(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneSubject.');
            }
            const localVarPath = `/api/subjects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific subject with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneSubjectExtended(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneSubjectExtended.');
            }
            const localVarPath = `/api/subjects/extended/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify a subject
         * @param {number} id 
         * @param {SubjectDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneSubject(id: number, body?: SubjectDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneSubject.');
            }
            const localVarPath = `/api/subjects/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubjectDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubjectsApi - functional programming interface
 * @export
 */
export const SubjectsApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new subject
         * @param {SubjectDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewSubject(body?: SubjectDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubjectDTO> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).addNewSubject(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new subject
         * @param {number} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewSubject(departmentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).canAddNewSubject(departmentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can delete a subject
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneSubject(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).canDeleteOneSubject(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a subject
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneSubject(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).deleteOneSubject(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all subjects with similar name in a department
         * @param {number} departmentId 
         * @param {string} subjectName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSimilarSubjects(departmentId: number, subjectName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubjectDTO>> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).getAllSimilarSubjects(departmentId, subjectName, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all subjects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubjects(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubjectDTO>> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).getAllSubjects(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all subjects with extra information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubjectsExtended(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubjectExtendedDTO>> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).getAllSubjectsExtended(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all subjects that have an edition on a given year and semester
         * @param {number} year 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubjectsExtendedWithEditionsOn(year: number, semester: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubjectExtendedDTO>> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).getAllSubjectsExtendedWithEditionsOn(year, semester, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * As a lecturer or head lecturer.
         * @summary Get all subjects associated with the principal for the current edition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubjectsForCurrentEdition(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubjectExtendedDTO>> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).getMySubjectsForCurrentEdition(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * As a lecturer or head lecturer.
         * @summary Get all subjects associated with the principal for a specific year and semester
         * @param {number} year 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubjectsForSpecificEdition(year: number, semester: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubjectExtendedDTO>> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).getMySubjectsForSpecificEdition(year, semester, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific subject
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneSubject(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubjectDTO> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).getOneSubject(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific subject with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneSubjectExtended(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubjectExtendedDTO> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).getOneSubjectExtended(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify a subject
         * @param {number} id 
         * @param {SubjectDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneSubject(id: number, body?: SubjectDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubjectDTO> {
            const localVarFetchArgs = SubjectsApiFetchParamCreator(configuration).modifyOneSubject(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SubjectsApi - factory interface
 * @export
 */
export const SubjectsApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new subject
         * @param {SubjectDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewSubject(body?: SubjectDTO, options?: any) {
            return SubjectsApiFp(configuration).addNewSubject(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can create a new subject
         * @param {number} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewSubject(departmentId: number, options?: any) {
            return SubjectsApiFp(configuration).canAddNewSubject(departmentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can delete a subject
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneSubject(id: number, options?: any) {
            return SubjectsApiFp(configuration).canDeleteOneSubject(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a subject
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneSubject(id: number, options?: any) {
            return SubjectsApiFp(configuration).deleteOneSubject(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all subjects with similar name in a department
         * @param {number} departmentId 
         * @param {string} subjectName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSimilarSubjects(departmentId: number, subjectName: string, options?: any) {
            return SubjectsApiFp(configuration).getAllSimilarSubjects(departmentId, subjectName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all subjects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubjects(options?: any) {
            return SubjectsApiFp(configuration).getAllSubjects(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all subjects with extra information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubjectsExtended(options?: any) {
            return SubjectsApiFp(configuration).getAllSubjectsExtended(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all subjects that have an edition on a given year and semester
         * @param {number} year 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSubjectsExtendedWithEditionsOn(year: number, semester: number, options?: any) {
            return SubjectsApiFp(configuration).getAllSubjectsExtendedWithEditionsOn(year, semester, options)(fetch, basePath);
        },
        /**
         * As a lecturer or head lecturer.
         * @summary Get all subjects associated with the principal for the current edition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubjectsForCurrentEdition(options?: any) {
            return SubjectsApiFp(configuration).getMySubjectsForCurrentEdition(options)(fetch, basePath);
        },
        /**
         * As a lecturer or head lecturer.
         * @summary Get all subjects associated with the principal for a specific year and semester
         * @param {number} year 
         * @param {number} semester 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMySubjectsForSpecificEdition(year: number, semester: number, options?: any) {
            return SubjectsApiFp(configuration).getMySubjectsForSpecificEdition(year, semester, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific subject
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneSubject(id: number, options?: any) {
            return SubjectsApiFp(configuration).getOneSubject(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific subject with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneSubjectExtended(id: number, options?: any) {
            return SubjectsApiFp(configuration).getOneSubjectExtended(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify a subject
         * @param {number} id 
         * @param {SubjectDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneSubject(id: number, body?: SubjectDTO, options?: any) {
            return SubjectsApiFp(configuration).modifyOneSubject(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * SubjectsApi - object-oriented interface
 * @export
 * @class SubjectsApi
 * @extends {BaseAPI}
 */
export class SubjectsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new subject
     * @param {SubjectDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public addNewSubject(body?: SubjectDTO, options?: any) {
        return SubjectsApiFp(this.configuration).addNewSubject(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can create a new subject
     * @param {number} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public canAddNewSubject(departmentId: number, options?: any) {
        return SubjectsApiFp(this.configuration).canAddNewSubject(departmentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can delete a subject
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public canDeleteOneSubject(id: number, options?: any) {
        return SubjectsApiFp(this.configuration).canDeleteOneSubject(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a subject
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public deleteOneSubject(id: number, options?: any) {
        return SubjectsApiFp(this.configuration).deleteOneSubject(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all subjects with similar name in a department
     * @param {number} departmentId 
     * @param {string} subjectName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getAllSimilarSubjects(departmentId: number, subjectName: string, options?: any) {
        return SubjectsApiFp(this.configuration).getAllSimilarSubjects(departmentId, subjectName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all subjects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getAllSubjects(options?: any) {
        return SubjectsApiFp(this.configuration).getAllSubjects(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all subjects with extra information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getAllSubjectsExtended(options?: any) {
        return SubjectsApiFp(this.configuration).getAllSubjectsExtended(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all subjects that have an edition on a given year and semester
     * @param {number} year 
     * @param {number} semester 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getAllSubjectsExtendedWithEditionsOn(year: number, semester: number, options?: any) {
        return SubjectsApiFp(this.configuration).getAllSubjectsExtendedWithEditionsOn(year, semester, options)(this.fetch, this.basePath);
    }

    /**
     * As a lecturer or head lecturer.
     * @summary Get all subjects associated with the principal for the current edition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getMySubjectsForCurrentEdition(options?: any) {
        return SubjectsApiFp(this.configuration).getMySubjectsForCurrentEdition(options)(this.fetch, this.basePath);
    }

    /**
     * As a lecturer or head lecturer.
     * @summary Get all subjects associated with the principal for a specific year and semester
     * @param {number} year 
     * @param {number} semester 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getMySubjectsForSpecificEdition(year: number, semester: number, options?: any) {
        return SubjectsApiFp(this.configuration).getMySubjectsForSpecificEdition(year, semester, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific subject
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getOneSubject(id: number, options?: any) {
        return SubjectsApiFp(this.configuration).getOneSubject(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific subject with extra information
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public getOneSubjectExtended(id: number, options?: any) {
        return SubjectsApiFp(this.configuration).getOneSubjectExtended(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify a subject
     * @param {number} id 
     * @param {SubjectDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubjectsApi
     */
    public modifyOneSubject(id: number, body?: SubjectDTO, options?: any) {
        return SubjectsApiFp(this.configuration).modifyOneSubject(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * If password is not present, a random one is generated.
         * @summary Create a new user
         * @param {SignUpUserDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewUser(body?: SignUpUserDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SignUpUserDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new user
         * @param {number} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewUser(departmentId: number, options: any = {}): FetchArgs {
            // verify required parameter 'departmentId' is not null or undefined
            if (departmentId === null || departmentId === undefined) {
                throw new RequiredError('departmentId','Required parameter departmentId was null or undefined when calling canAddNewUser.');
            }
            const localVarPath = `/api/users/canCreate`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the logged user can delete a user (logical deletion)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneUser(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canDeleteOneUser.');
            }
            const localVarPath = `/api/users/canDelete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verifies if the user can manage justifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canManageDepartmentJustifications(options: any = {}): FetchArgs {
            const localVarPath = `/api/users/canManageJustifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can overwrite the user roles
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canSetRoles(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canSetRoles.');
            }
            const localVarPath = `/api/users/{id}/canSetRoles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the password of the principalnformática
         * @param {ChangePasswordDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(body?: ChangePasswordDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/users/changePassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ChangePasswordDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Change the password after resetting
         * @param {string} [body] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordAfterReset(body?: string, token?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/users/changePasswordAfterResetting`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete an user (logical deletion)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneUser(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneUser.');
            }
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(options: any = {}): FetchArgs {
            const localVarPath = `/api/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the currently authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeUser(options: any = {}): FetchArgs {
            const localVarPath = `/api/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the roles of the currently authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyRoles(options: any = {}): FetchArgs {
            const localVarPath = `/api/users/myRole`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneUser(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneUser.');
            }
            const localVarPath = `/api/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reset the password of a user: sends an email with instructions
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/users/resetPassword`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Overwrite the user roles
         * @param {number} id 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoles(id: number, body?: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling setRoles.');
            }
            const localVarPath = `/api/users/{id}/roles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration: Configuration) {
    return {
        /**
         * If password is not present, a random one is generated.
         * @summary Create a new user
         * @param {SignUpUserDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewUser(body?: SignUpUserDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDTO> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).addNewUser(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new user
         * @param {number} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewUser(departmentId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).canAddNewUser(departmentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the logged user can delete a user (logical deletion)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneUser(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).canDeleteOneUser(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Verifies if the user can manage justifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canManageDepartmentJustifications(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).canManageDepartmentJustifications(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can overwrite the user roles
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canSetRoles(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).canSetRoles(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Change the password of the principalnformática
         * @param {ChangePasswordDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(body?: ChangePasswordDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDTO> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).changePassword(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Change the password after resetting
         * @param {string} [body] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordAfterReset(body?: string, token?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).changePasswordAfterReset(body, token, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete an user (logical deletion)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneUser(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).deleteOneUser(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UserDTO>> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getAllUsers(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the currently authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserExtendedDTO> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getMeUser(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the roles of the currently authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyRoles(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getMyRoles(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneUser(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDTO> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).getOneUser(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reset the password of a user: sends an email with instructions
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).resetPassword(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Overwrite the user roles
         * @param {number} id 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoles(id: number, body?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserDTO> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).setRoles(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * If password is not present, a random one is generated.
         * @summary Create a new user
         * @param {SignUpUserDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewUser(body?: SignUpUserDTO, options?: any) {
            return UsersApiFp(configuration).addNewUser(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can create a new user
         * @param {number} departmentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewUser(departmentId: number, options?: any) {
            return UsersApiFp(configuration).canAddNewUser(departmentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the logged user can delete a user (logical deletion)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canDeleteOneUser(id: number, options?: any) {
            return UsersApiFp(configuration).canDeleteOneUser(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Verifies if the user can manage justifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canManageDepartmentJustifications(options?: any) {
            return UsersApiFp(configuration).canManageDepartmentJustifications(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can overwrite the user roles
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canSetRoles(id: number, options?: any) {
            return UsersApiFp(configuration).canSetRoles(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Change the password of the principalnformática
         * @param {ChangePasswordDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePassword(body?: ChangePasswordDTO, options?: any) {
            return UsersApiFp(configuration).changePassword(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Change the password after resetting
         * @param {string} [body] 
         * @param {string} [token] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordAfterReset(body?: string, token?: string, options?: any) {
            return UsersApiFp(configuration).changePasswordAfterReset(body, token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete an user (logical deletion)
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneUser(id: number, options?: any) {
            return UsersApiFp(configuration).deleteOneUser(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsers(options?: any) {
            return UsersApiFp(configuration).getAllUsers(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the currently authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeUser(options?: any) {
            return UsersApiFp(configuration).getMeUser(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the roles of the currently authenticated user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyRoles(options?: any) {
            return UsersApiFp(configuration).getMyRoles(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific user
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneUser(id: number, options?: any) {
            return UsersApiFp(configuration).getOneUser(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reset the password of a user: sends an email with instructions
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(body?: string, options?: any) {
            return UsersApiFp(configuration).resetPassword(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Overwrite the user roles
         * @param {number} id 
         * @param {Array<string>} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setRoles(id: number, body?: Array<string>, options?: any) {
            return UsersApiFp(configuration).setRoles(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * If password is not present, a random one is generated.
     * @summary Create a new user
     * @param {SignUpUserDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addNewUser(body?: SignUpUserDTO, options?: any) {
        return UsersApiFp(this.configuration).addNewUser(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can create a new user
     * @param {number} departmentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public canAddNewUser(departmentId: number, options?: any) {
        return UsersApiFp(this.configuration).canAddNewUser(departmentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the logged user can delete a user (logical deletion)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public canDeleteOneUser(id: number, options?: any) {
        return UsersApiFp(this.configuration).canDeleteOneUser(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Verifies if the user can manage justifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public canManageDepartmentJustifications(options?: any) {
        return UsersApiFp(this.configuration).canManageDepartmentJustifications(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can overwrite the user roles
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public canSetRoles(id: number, options?: any) {
        return UsersApiFp(this.configuration).canSetRoles(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Change the password of the principalnformática
     * @param {ChangePasswordDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePassword(body?: ChangePasswordDTO, options?: any) {
        return UsersApiFp(this.configuration).changePassword(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Change the password after resetting
     * @param {string} [body] 
     * @param {string} [token] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public changePasswordAfterReset(body?: string, token?: string, options?: any) {
        return UsersApiFp(this.configuration).changePasswordAfterReset(body, token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete an user (logical deletion)
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteOneUser(id: number, options?: any) {
        return UsersApiFp(this.configuration).deleteOneUser(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getAllUsers(options?: any) {
        return UsersApiFp(this.configuration).getAllUsers(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the currently authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMeUser(options?: any) {
        return UsersApiFp(this.configuration).getMeUser(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the roles of the currently authenticated user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMyRoles(options?: any) {
        return UsersApiFp(this.configuration).getMyRoles(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific user
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getOneUser(id: number, options?: any) {
        return UsersApiFp(this.configuration).getOneUser(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reset the password of a user: sends an email with instructions
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public resetPassword(body?: string, options?: any) {
        return UsersApiFp(this.configuration).resetPassword(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Overwrite the user roles
     * @param {number} id 
     * @param {Array<string>} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public setRoles(id: number, body?: Array<string>, options?: any) {
        return UsersApiFp(this.configuration).setRoles(id, body, options)(this.fetch, this.basePath);
    }

}
/**
 * VigilanceRequestsApi - fetch parameter creator
 * @export
 */
export const VigilanceRequestsApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Accept a vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accept(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling accept.');
            }
            const localVarPath = `/api/vigilance-requests/accept/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Accept a rejection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptJustification(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling acceptJustification.');
            }
            const localVarPath = `/api/vigilance-requests/rejected/unconfirmed/accept/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new vigilance request
         * @param {SimpleVigilanceRequestDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewVigilanceRequest(body?: SimpleVigilanceRequestDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SimpleVigilanceRequestDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Automatically create and assign n vigilances for a specific evaluation
         * @param {number} evaluationId 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askVigilants(evaluationId: number, amount: number, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling askVigilants.');
            }
            // verify required parameter 'amount' is not null or undefined
            if (amount === null || amount === undefined) {
                throw new RequiredError('amount','Required parameter amount was null or undefined when calling askVigilants.');
            }
            const localVarPath = `/api/vigilance-requests/ask_vigilants/{evaluationId}/{amount}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)))
                .replace(`{${"amount"}}`, encodeURIComponent(String(amount)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new vigilance request
         * @param {number} evaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewVigilanceRequest(evaluationId: number, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling canAddNewVigilanceRequest.');
            }
            const localVarPath = `/api/vigilance-requests/canCreate/{evaluationId}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can give an answer to a vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAnswer(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canAnswer.');
            }
            const localVarPath = `/api/vigilance-requests/canAnswer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can ask for vigilants for an evaluation
         * @param {number} evaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAskVigilants(evaluationId: number, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling canAskVigilants.');
            }
            const localVarPath = `/api/vigilance-requests/canAskVigilants/{evaluationId}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if the principal can cancel a apecific vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCancelOneVigilanceRequest(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canCancelOneVigilanceRequest.');
            }
            const localVarPath = `/api/vigilance-requests/canCancelSpecific/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if the principal can cancel some vigilance requests from an evaluation
         * @param {number} evaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCancelSomeVigilanceRequest(evaluationId: number, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling canCancelSomeVigilanceRequest.');
            }
            const localVarPath = `/api/vigilance-requests/canCancelSome/{evaluationId}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks if the principal can get all vigilance requests
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canGetAllVigilanceRequests(departmentId?: number, evaluationId?: number, lecturerId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests/canGet`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (evaluationId !== undefined) {
                localVarQueryParameter['evaluationId'] = evaluationId;
            }

            if (lecturerId !== undefined) {
                localVarQueryParameter['lecturerId'] = lecturerId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check if the lecturer can reject the request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canReject(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling canReject.');
            }
            const localVarPath = `/api/vigilance-requests/reject/{id}/canReject`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel a vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOneVigilanceRequest(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling cancelOneVigilanceRequest.');
            }
            const localVarPath = `/api/vigilance-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Cancel some vigilance requests from an evaluation
         * @param {number} evaluationId 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSomeVigilanceRequests(evaluationId: number, amount: number, options: any = {}): FetchArgs {
            // verify required parameter 'evaluationId' is not null or undefined
            if (evaluationId === null || evaluationId === undefined) {
                throw new RequiredError('evaluationId','Required parameter evaluationId was null or undefined when calling cancelSomeVigilanceRequests.');
            }
            // verify required parameter 'amount' is not null or undefined
            if (amount === null || amount === undefined) {
                throw new RequiredError('amount','Required parameter amount was null or undefined when calling cancelSomeVigilanceRequests.');
            }
            const localVarPath = `/api/vigilance-requests/cancelSome/evaluation/{evaluationId}/{amount}`
                .replace(`{${"evaluationId"}}`, encodeURIComponent(String(evaluationId)))
                .replace(`{${"amount"}}`, encodeURIComponent(String(amount)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delegate penalty decision
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delegatePenaltyDecision(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling delegatePenaltyDecision.');
            }
            const localVarPath = `/api/vigilance-requests/rejected/unconfirmed/delegate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all accepted vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccepted(options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests/accepted`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all vigilance requests done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDone(options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests/done`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all exempt vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExempt(options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests/exempt`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all rejected vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRejected(options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests/rejected`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all swapped vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSwapped(options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests/swapped`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all rejected vigilance requests waiting for confirmation
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnconfirmedRejected(departmentId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests/rejected/unconfirmed`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all rejected vigilance requests waiting for confirmation for a specific year and edition
         * @param {number} year 
         * @param {number} semester 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnconfirmedRejectedForEdition(year: number, semester: number, departmentId?: number, options: any = {}): FetchArgs {
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling getAllUnconfirmedRejectedForEdition.');
            }
            // verify required parameter 'semester' is not null or undefined
            if (semester === null || semester === undefined) {
                throw new RequiredError('semester','Required parameter semester was null or undefined when calling getAllUnconfirmedRejectedForEdition.');
            }
            const localVarPath = `/api/vigilance-requests/rejected/unconfirmed/{year}/{semester}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"semester"}}`, encodeURIComponent(String(semester)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all vigilance requests
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilanceRequests(departmentId?: number, evaluationId?: number, lecturerId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (evaluationId !== undefined) {
                localVarQueryParameter['evaluationId'] = evaluationId;
            }

            if (lecturerId !== undefined) {
                localVarQueryParameter['lecturerId'] = lecturerId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all vigilance requests with extra information
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilanceRequestsExtended(departmentId?: number, evaluationId?: number, lecturerId?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilance-requests/extended`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (evaluationId !== undefined) {
                localVarQueryParameter['evaluationId'] = evaluationId;
            }

            if (lecturerId !== undefined) {
                localVarQueryParameter['lecturerId'] = lecturerId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all vigilance requests with extra information for a specific year and semester
         * @param {number} year 
         * @param {number} semester 
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilanceRequestsExtendedForEdition(year: number, semester: number, departmentId?: number, evaluationId?: number, lecturerId?: number, options: any = {}): FetchArgs {
            // verify required parameter 'year' is not null or undefined
            if (year === null || year === undefined) {
                throw new RequiredError('year','Required parameter year was null or undefined when calling getAllVigilanceRequestsExtendedForEdition.');
            }
            // verify required parameter 'semester' is not null or undefined
            if (semester === null || semester === undefined) {
                throw new RequiredError('semester','Required parameter semester was null or undefined when calling getAllVigilanceRequestsExtendedForEdition.');
            }
            const localVarPath = `/api/vigilance-requests/extended/{year}/{semester}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"semester"}}`, encodeURIComponent(String(semester)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (evaluationId !== undefined) {
                localVarQueryParameter['evaluationId'] = evaluationId;
            }

            if (lecturerId !== undefined) {
                localVarQueryParameter['lecturerId'] = lecturerId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific accepted vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneAccepted(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneAccepted.');
            }
            const localVarPath = `/api/vigilance-requests/accepted/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific vigilance request done
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneDone(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneDone.');
            }
            const localVarPath = `/api/vigilance-requests/done/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific exempt vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExempt(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneExempt.');
            }
            const localVarPath = `/api/vigilance-requests/exempt/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific vigilance request with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExtended(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneExtended.');
            }
            const localVarPath = `/api/vigilance-requests/extended/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a rejected vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRejected(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneRejected.');
            }
            const localVarPath = `/api/vigilance-requests/rejected/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific vigilance request with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRejectedExtended(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneRejectedExtended.');
            }
            const localVarPath = `/api/vigilance-requests/extended/rejected/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific swapped vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneSwapped(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneSwapped.');
            }
            const localVarPath = `/api/vigilance-requests/swapped/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneVigilanceRequest(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneVigilanceRequest.');
            }
            const localVarPath = `/api/vigilance-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get rejection penalty justification
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPenaltyJustification(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getPenaltyJustification.');
            }
            const localVarPath = `/api/vigilance-requests/rejected/unconfirmed/reject/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject a vigilance request
         * @param {number} id 
         * @param {RejectionDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reject(id: number, body?: RejectionDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling reject.');
            }
            const localVarPath = `/api/vigilance-requests/reject/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RejectionDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Reject a rejection
         * @param {number} id 
         * @param {PenaltyJustificationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectJustification(id: number, body?: PenaltyJustificationDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling rejectJustification.');
            }
            const localVarPath = `/api/vigilance-requests/rejected/unconfirmed/reject/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PenaltyJustificationDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Swap a vigilance request
         * @param {number} id 
         * @param {number} lecturerReferred 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swap(id: number, lecturerReferred: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling swap.');
            }
            // verify required parameter 'lecturerReferred' is not null or undefined
            if (lecturerReferred === null || lecturerReferred === undefined) {
                throw new RequiredError('lecturerReferred','Required parameter lecturerReferred was null or undefined when calling swap.');
            }
            const localVarPath = `/api/vigilance-requests/swap/{id}/{lecturerReferred}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"lecturerReferred"}}`, encodeURIComponent(String(lecturerReferred)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VigilanceRequestsApi - functional programming interface
 * @export
 */
export const VigilanceRequestsApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Accept a vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accept(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).accept(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Accept a rejection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptJustification(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).acceptJustification(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Create a new vigilance request
         * @param {SimpleVigilanceRequestDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewVigilanceRequest(body?: SimpleVigilanceRequestDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VigilanceRequestDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).addNewVigilanceRequest(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Automatically create and assign n vigilances for a specific evaluation
         * @param {number} evaluationId 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askVigilants(evaluationId: number, amount: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).askVigilants(evaluationId, amount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can create a new vigilance request
         * @param {number} evaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewVigilanceRequest(evaluationId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).canAddNewVigilanceRequest(evaluationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can give an answer to a vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAnswer(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).canAnswer(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can ask for vigilants for an evaluation
         * @param {number} evaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAskVigilants(evaluationId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).canAskVigilants(evaluationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check if the principal can cancel a apecific vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCancelOneVigilanceRequest(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).canCancelOneVigilanceRequest(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check if the principal can cancel some vigilance requests from an evaluation
         * @param {number} evaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCancelSomeVigilanceRequest(evaluationId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).canCancelSomeVigilanceRequest(evaluationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Checks if the principal can get all vigilance requests
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canGetAllVigilanceRequests(departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).canGetAllVigilanceRequests(departmentId, evaluationId, lecturerId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Check if the lecturer can reject the request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canReject(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).canReject(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Cancel a vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOneVigilanceRequest(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).cancelOneVigilanceRequest(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Cancel some vigilance requests from an evaluation
         * @param {number} evaluationId 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSomeVigilanceRequests(evaluationId: number, amount: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).cancelSomeVigilanceRequests(evaluationId, amount, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delegate penalty decision
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delegatePenaltyDecision(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).delegatePenaltyDecision(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all accepted vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccepted(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<AcceptedDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllAccepted(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all vigilance requests done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDone(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DoneDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllDone(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all exempt vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExempt(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExemptDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllExempt(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all rejected vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRejected(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<RejectedDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllRejected(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all swapped vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSwapped(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SwappedDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllSwapped(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all rejected vigilance requests waiting for confirmation
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnconfirmedRejected(departmentId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VigilanceRequestExtendedDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllUnconfirmedRejected(departmentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all rejected vigilance requests waiting for confirmation for a specific year and edition
         * @param {number} year 
         * @param {number} semester 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnconfirmedRejectedForEdition(year: number, semester: number, departmentId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VigilanceRequestExtendedDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllUnconfirmedRejectedForEdition(year, semester, departmentId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all vigilance requests
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilanceRequests(departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VigilanceRequestDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllVigilanceRequests(departmentId, evaluationId, lecturerId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all vigilance requests with extra information
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilanceRequestsExtended(departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VigilanceRequestExtendedDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllVigilanceRequestsExtended(departmentId, evaluationId, lecturerId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all vigilance requests with extra information for a specific year and semester
         * @param {number} year 
         * @param {number} semester 
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilanceRequestsExtendedForEdition(year: number, semester: number, departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VigilanceRequestExtendedDTO>> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getAllVigilanceRequestsExtendedForEdition(year, semester, departmentId, evaluationId, lecturerId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific accepted vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneAccepted(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptedDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getOneAccepted(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific vigilance request done
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneDone(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DoneDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getOneDone(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific exempt vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExempt(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExemptDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getOneExempt(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific vigilance request with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExtended(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VigilanceRequestExtendedDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getOneExtended(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a rejected vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRejected(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RejectedDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getOneRejected(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific vigilance request with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRejectedExtended(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VigilanceRequestExtendedDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getOneRejectedExtended(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific swapped vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneSwapped(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SwappedDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getOneSwapped(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneVigilanceRequest(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VigilanceRequestDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getOneVigilanceRequest(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get rejection penalty justification
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPenaltyJustification(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PenaltyJustificationDTO> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).getPenaltyJustification(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reject a vigilance request
         * @param {number} id 
         * @param {RejectionDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reject(id: number, body?: RejectionDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).reject(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Reject a rejection
         * @param {number} id 
         * @param {PenaltyJustificationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectJustification(id: number, body?: PenaltyJustificationDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).rejectJustification(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Swap a vigilance request
         * @param {number} id 
         * @param {number} lecturerReferred 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swap(id: number, lecturerReferred: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilanceRequestsApiFetchParamCreator(configuration).swap(id, lecturerReferred, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VigilanceRequestsApi - factory interface
 * @export
 */
export const VigilanceRequestsApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Accept a vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accept(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).accept(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Accept a rejection
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptJustification(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).acceptJustification(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Create a new vigilance request
         * @param {SimpleVigilanceRequestDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewVigilanceRequest(body?: SimpleVigilanceRequestDTO, options?: any) {
            return VigilanceRequestsApiFp(configuration).addNewVigilanceRequest(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Automatically create and assign n vigilances for a specific evaluation
         * @param {number} evaluationId 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        askVigilants(evaluationId: number, amount: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).askVigilants(evaluationId, amount, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can create a new vigilance request
         * @param {number} evaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAddNewVigilanceRequest(evaluationId: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).canAddNewVigilanceRequest(evaluationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can give an answer to a vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAnswer(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).canAnswer(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can ask for vigilants for an evaluation
         * @param {number} evaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canAskVigilants(evaluationId: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).canAskVigilants(evaluationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check if the principal can cancel a apecific vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCancelOneVigilanceRequest(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).canCancelOneVigilanceRequest(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check if the principal can cancel some vigilance requests from an evaluation
         * @param {number} evaluationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canCancelSomeVigilanceRequest(evaluationId: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).canCancelSomeVigilanceRequest(evaluationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Checks if the principal can get all vigilance requests
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canGetAllVigilanceRequests(departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).canGetAllVigilanceRequests(departmentId, evaluationId, lecturerId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Check if the lecturer can reject the request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        canReject(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).canReject(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Cancel a vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOneVigilanceRequest(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).cancelOneVigilanceRequest(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Cancel some vigilance requests from an evaluation
         * @param {number} evaluationId 
         * @param {number} amount 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelSomeVigilanceRequests(evaluationId: number, amount: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).cancelSomeVigilanceRequests(evaluationId, amount, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delegate penalty decision
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delegatePenaltyDecision(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).delegatePenaltyDecision(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all accepted vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccepted(options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllAccepted(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all vigilance requests done
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDone(options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllDone(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all exempt vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllExempt(options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllExempt(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all rejected vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllRejected(options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllRejected(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all swapped vigilance requests
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSwapped(options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllSwapped(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all rejected vigilance requests waiting for confirmation
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnconfirmedRejected(departmentId?: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllUnconfirmedRejected(departmentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all rejected vigilance requests waiting for confirmation for a specific year and edition
         * @param {number} year 
         * @param {number} semester 
         * @param {number} [departmentId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUnconfirmedRejectedForEdition(year: number, semester: number, departmentId?: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllUnconfirmedRejectedForEdition(year, semester, departmentId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all vigilance requests
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilanceRequests(departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllVigilanceRequests(departmentId, evaluationId, lecturerId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all vigilance requests with extra information
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilanceRequestsExtended(departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllVigilanceRequestsExtended(departmentId, evaluationId, lecturerId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all vigilance requests with extra information for a specific year and semester
         * @param {number} year 
         * @param {number} semester 
         * @param {number} [departmentId] 
         * @param {number} [evaluationId] 
         * @param {number} [lecturerId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilanceRequestsExtendedForEdition(year: number, semester: number, departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getAllVigilanceRequestsExtendedForEdition(year, semester, departmentId, evaluationId, lecturerId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific accepted vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneAccepted(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getOneAccepted(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific vigilance request done
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneDone(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getOneDone(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific exempt vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExempt(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getOneExempt(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific vigilance request with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneExtended(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getOneExtended(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a rejected vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRejected(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getOneRejected(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific vigilance request with extra information
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneRejectedExtended(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getOneRejectedExtended(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific swapped vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneSwapped(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getOneSwapped(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific vigilance request
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneVigilanceRequest(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getOneVigilanceRequest(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get rejection penalty justification
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPenaltyJustification(id: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).getPenaltyJustification(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reject a vigilance request
         * @param {number} id 
         * @param {RejectionDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reject(id: number, body?: RejectionDTO, options?: any) {
            return VigilanceRequestsApiFp(configuration).reject(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Reject a rejection
         * @param {number} id 
         * @param {PenaltyJustificationDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectJustification(id: number, body?: PenaltyJustificationDTO, options?: any) {
            return VigilanceRequestsApiFp(configuration).rejectJustification(id, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Swap a vigilance request
         * @param {number} id 
         * @param {number} lecturerReferred 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swap(id: number, lecturerReferred: number, options?: any) {
            return VigilanceRequestsApiFp(configuration).swap(id, lecturerReferred, options)(fetch, basePath);
        },
    };
};

/**
 * VigilanceRequestsApi - object-oriented interface
 * @export
 * @class VigilanceRequestsApi
 * @extends {BaseAPI}
 */
export class VigilanceRequestsApi extends BaseAPI {
    /**
     * 
     * @summary Accept a vigilance request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public accept(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).accept(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Accept a rejection
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public acceptJustification(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).acceptJustification(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Create a new vigilance request
     * @param {SimpleVigilanceRequestDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public addNewVigilanceRequest(body?: SimpleVigilanceRequestDTO, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).addNewVigilanceRequest(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Automatically create and assign n vigilances for a specific evaluation
     * @param {number} evaluationId 
     * @param {number} amount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public askVigilants(evaluationId: number, amount: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).askVigilants(evaluationId, amount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can create a new vigilance request
     * @param {number} evaluationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public canAddNewVigilanceRequest(evaluationId: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).canAddNewVigilanceRequest(evaluationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can give an answer to a vigilance request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public canAnswer(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).canAnswer(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can ask for vigilants for an evaluation
     * @param {number} evaluationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public canAskVigilants(evaluationId: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).canAskVigilants(evaluationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check if the principal can cancel a apecific vigilance request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public canCancelOneVigilanceRequest(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).canCancelOneVigilanceRequest(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check if the principal can cancel some vigilance requests from an evaluation
     * @param {number} evaluationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public canCancelSomeVigilanceRequest(evaluationId: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).canCancelSomeVigilanceRequest(evaluationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Checks if the principal can get all vigilance requests
     * @param {number} [departmentId] 
     * @param {number} [evaluationId] 
     * @param {number} [lecturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public canGetAllVigilanceRequests(departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).canGetAllVigilanceRequests(departmentId, evaluationId, lecturerId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Check if the lecturer can reject the request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public canReject(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).canReject(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Cancel a vigilance request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public cancelOneVigilanceRequest(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).cancelOneVigilanceRequest(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Cancel some vigilance requests from an evaluation
     * @param {number} evaluationId 
     * @param {number} amount 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public cancelSomeVigilanceRequests(evaluationId: number, amount: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).cancelSomeVigilanceRequests(evaluationId, amount, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delegate penalty decision
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public delegatePenaltyDecision(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).delegatePenaltyDecision(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all accepted vigilance requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllAccepted(options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllAccepted(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all vigilance requests done
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllDone(options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllDone(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all exempt vigilance requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllExempt(options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllExempt(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all rejected vigilance requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllRejected(options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllRejected(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all swapped vigilance requests
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllSwapped(options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllSwapped(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all rejected vigilance requests waiting for confirmation
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllUnconfirmedRejected(departmentId?: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllUnconfirmedRejected(departmentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all rejected vigilance requests waiting for confirmation for a specific year and edition
     * @param {number} year 
     * @param {number} semester 
     * @param {number} [departmentId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllUnconfirmedRejectedForEdition(year: number, semester: number, departmentId?: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllUnconfirmedRejectedForEdition(year, semester, departmentId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all vigilance requests
     * @param {number} [departmentId] 
     * @param {number} [evaluationId] 
     * @param {number} [lecturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllVigilanceRequests(departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllVigilanceRequests(departmentId, evaluationId, lecturerId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all vigilance requests with extra information
     * @param {number} [departmentId] 
     * @param {number} [evaluationId] 
     * @param {number} [lecturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllVigilanceRequestsExtended(departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllVigilanceRequestsExtended(departmentId, evaluationId, lecturerId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all vigilance requests with extra information for a specific year and semester
     * @param {number} year 
     * @param {number} semester 
     * @param {number} [departmentId] 
     * @param {number} [evaluationId] 
     * @param {number} [lecturerId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getAllVigilanceRequestsExtendedForEdition(year: number, semester: number, departmentId?: number, evaluationId?: number, lecturerId?: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getAllVigilanceRequestsExtendedForEdition(year, semester, departmentId, evaluationId, lecturerId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific accepted vigilance request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getOneAccepted(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getOneAccepted(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific vigilance request done
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getOneDone(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getOneDone(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific exempt vigilance request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getOneExempt(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getOneExempt(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific vigilance request with extra information
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getOneExtended(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getOneExtended(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a rejected vigilance request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getOneRejected(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getOneRejected(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific vigilance request with extra information
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getOneRejectedExtended(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getOneRejectedExtended(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific swapped vigilance request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getOneSwapped(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getOneSwapped(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific vigilance request
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getOneVigilanceRequest(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getOneVigilanceRequest(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get rejection penalty justification
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public getPenaltyJustification(id: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).getPenaltyJustification(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reject a vigilance request
     * @param {number} id 
     * @param {RejectionDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public reject(id: number, body?: RejectionDTO, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).reject(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Reject a rejection
     * @param {number} id 
     * @param {PenaltyJustificationDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public rejectJustification(id: number, body?: PenaltyJustificationDTO, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).rejectJustification(id, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Swap a vigilance request
     * @param {number} id 
     * @param {number} lecturerReferred 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilanceRequestsApi
     */
    public swap(id: number, lecturerReferred: number, options?: any) {
        return VigilanceRequestsApiFp(this.configuration).swap(id, lecturerReferred, options)(this.fetch, this.basePath);
    }

}
/**
 * VigilantRecordsApi - fetch parameter creator
 * @export
 */
export const VigilantRecordsApiFetchParamCreator = function (configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new vigilant record
         * @param {VigilantRecordDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewVigilantRecord(body?: VigilantRecordDTO, options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilant-records`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VigilantRecordDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a vigilant record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneVigilantRecord(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOneVigilantRecord.');
            }
            const localVarPath = `/api/vigilant-records/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all vigilant records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilantRecords(options: any = {}): FetchArgs {
            const localVarPath = `/api/vigilant-records`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the vigilant record of a specific lecturer for the current year and semester
         * @param {number} lecturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentVigilantRecord(lecturerId: number, options: any = {}): FetchArgs {
            // verify required parameter 'lecturerId' is not null or undefined
            if (lecturerId === null || lecturerId === undefined) {
                throw new RequiredError('lecturerId','Required parameter lecturerId was null or undefined when calling getCurrentVigilantRecord.');
            }
            const localVarPath = `/api/vigilant-records/current/{lecturerId}`
                .replace(`{${"lecturerId"}}`, encodeURIComponent(String(lecturerId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a specific vigilant record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneVigilantRecord(id: number, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOneVigilantRecord.');
            }
            const localVarPath = `/api/vigilant-records/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the score list of a specific department for an year and semester with natural order
         * @param {number} departmentId 
         * @param {YearSemesterDTO} [body] Year and semester of the score list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScoreList(departmentId: number, body?: YearSemesterDTO, options: any = {}): FetchArgs {
            // verify required parameter 'departmentId' is not null or undefined
            if (departmentId === null || departmentId === undefined) {
                throw new RequiredError('departmentId','Required parameter departmentId was null or undefined when calling getScoreList.');
            }
            const localVarPath = `/api/vigilant-records/scores/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"YearSemesterDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Modify a vigilant record
         * @param {number} id 
         * @param {VigilantRecordDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneVigilantRecord(id: number, body?: VigilantRecordDTO, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling modifyOneVigilantRecord.');
            }
            const localVarPath = `/api/vigilant-records/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"VigilantRecordDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VigilantRecordsApi - functional programming interface
 * @export
 */
export const VigilantRecordsApiFp = function(configuration: Configuration) {
    return {
        /**
         * 
         * @summary Create a new vigilant record
         * @param {VigilantRecordDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewVigilantRecord(body?: VigilantRecordDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VigilantRecordDTO> {
            const localVarFetchArgs = VigilantRecordsApiFetchParamCreator(configuration).addNewVigilantRecord(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Delete a vigilant record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneVigilantRecord(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = VigilantRecordsApiFetchParamCreator(configuration).deleteOneVigilantRecord(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get all vigilant records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilantRecords(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VigilantRecordDTO>> {
            const localVarFetchArgs = VigilantRecordsApiFetchParamCreator(configuration).getAllVigilantRecords(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the vigilant record of a specific lecturer for the current year and semester
         * @param {number} lecturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentVigilantRecord(lecturerId: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VigilantRecordDTO> {
            const localVarFetchArgs = VigilantRecordsApiFetchParamCreator(configuration).getCurrentVigilantRecord(lecturerId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get a specific vigilant record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneVigilantRecord(id: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VigilantRecordDTO> {
            const localVarFetchArgs = VigilantRecordsApiFetchParamCreator(configuration).getOneVigilantRecord(id, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Get the score list of a specific department for an year and semester with natural order
         * @param {number} departmentId 
         * @param {YearSemesterDTO} [body] Year and semester of the score list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScoreList(departmentId: number, body?: YearSemesterDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ScoreTableEntryDTO>> {
            const localVarFetchArgs = VigilantRecordsApiFetchParamCreator(configuration).getScoreList(departmentId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary Modify a vigilant record
         * @param {number} id 
         * @param {VigilantRecordDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneVigilantRecord(id: number, body?: VigilantRecordDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VigilantRecordDTO> {
            const localVarFetchArgs = VigilantRecordsApiFetchParamCreator(configuration).modifyOneVigilantRecord(id, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * VigilantRecordsApi - factory interface
 * @export
 */
export const VigilantRecordsApiFactory = function (configuration: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary Create a new vigilant record
         * @param {VigilantRecordDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addNewVigilantRecord(body?: VigilantRecordDTO, options?: any) {
            return VigilantRecordsApiFp(configuration).addNewVigilantRecord(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Delete a vigilant record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOneVigilantRecord(id: number, options?: any) {
            return VigilantRecordsApiFp(configuration).deleteOneVigilantRecord(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get all vigilant records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVigilantRecords(options?: any) {
            return VigilantRecordsApiFp(configuration).getAllVigilantRecords(options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the vigilant record of a specific lecturer for the current year and semester
         * @param {number} lecturerId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentVigilantRecord(lecturerId: number, options?: any) {
            return VigilantRecordsApiFp(configuration).getCurrentVigilantRecord(lecturerId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get a specific vigilant record
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOneVigilantRecord(id: number, options?: any) {
            return VigilantRecordsApiFp(configuration).getOneVigilantRecord(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Get the score list of a specific department for an year and semester with natural order
         * @param {number} departmentId 
         * @param {YearSemesterDTO} [body] Year and semester of the score list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScoreList(departmentId: number, body?: YearSemesterDTO, options?: any) {
            return VigilantRecordsApiFp(configuration).getScoreList(departmentId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary Modify a vigilant record
         * @param {number} id 
         * @param {VigilantRecordDTO} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyOneVigilantRecord(id: number, body?: VigilantRecordDTO, options?: any) {
            return VigilantRecordsApiFp(configuration).modifyOneVigilantRecord(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * VigilantRecordsApi - object-oriented interface
 * @export
 * @class VigilantRecordsApi
 * @extends {BaseAPI}
 */
export class VigilantRecordsApi extends BaseAPI {
    /**
     * 
     * @summary Create a new vigilant record
     * @param {VigilantRecordDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilantRecordsApi
     */
    public addNewVigilantRecord(body?: VigilantRecordDTO, options?: any) {
        return VigilantRecordsApiFp(this.configuration).addNewVigilantRecord(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Delete a vigilant record
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilantRecordsApi
     */
    public deleteOneVigilantRecord(id: number, options?: any) {
        return VigilantRecordsApiFp(this.configuration).deleteOneVigilantRecord(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get all vigilant records
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilantRecordsApi
     */
    public getAllVigilantRecords(options?: any) {
        return VigilantRecordsApiFp(this.configuration).getAllVigilantRecords(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the vigilant record of a specific lecturer for the current year and semester
     * @param {number} lecturerId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilantRecordsApi
     */
    public getCurrentVigilantRecord(lecturerId: number, options?: any) {
        return VigilantRecordsApiFp(this.configuration).getCurrentVigilantRecord(lecturerId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get a specific vigilant record
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilantRecordsApi
     */
    public getOneVigilantRecord(id: number, options?: any) {
        return VigilantRecordsApiFp(this.configuration).getOneVigilantRecord(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Get the score list of a specific department for an year and semester with natural order
     * @param {number} departmentId 
     * @param {YearSemesterDTO} [body] Year and semester of the score list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilantRecordsApi
     */
    public getScoreList(departmentId: number, body?: YearSemesterDTO, options?: any) {
        return VigilantRecordsApiFp(this.configuration).getScoreList(departmentId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary Modify a vigilant record
     * @param {number} id 
     * @param {VigilantRecordDTO} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VigilantRecordsApi
     */
    public modifyOneVigilantRecord(id: number, body?: VigilantRecordDTO, options?: any) {
        return VigilantRecordsApiFp(this.configuration).modifyOneVigilantRecord(id, body, options)(this.fetch, this.basePath);
    }

}
