import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {DepartmentsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {useHistory} from "react-router-dom";
import {genericErrorCallback} from "../util/util";
import DeleteDepartmentButton from "./DeleteDepartmentButton";
import {PageContext} from "../contexts/PageContext";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";

const useStyles = defaultStyles();

export default function DepartmentDetail(props: any) {
    const history = useHistory();
    const classes = useStyles();

    const departmentsAPI = new DepartmentsApi(config);

    const departmentId = parseInt(props.match.params.department_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [canDeleteDepartment, setCanDeleteDepartment] = React.useState(false);
    const pageContext = React.useContext(PageContext) as Array<any>;
    React.useEffect(fetchData, [])

    function fetchData() {
        pageContext[1]("Departamento: ");
        departmentsAPI.getOneExtendedDepartment(departmentId).then(
            e => {
                pageContext[1]("Departamento: " + e.name);
                setIsLoading(false);
            }
        ).catch(genericErrorCallback)

        departmentsAPI.canDeleteOneDepartment(departmentId)
            .then(() => setCanDeleteDepartment(true))
            .catch(() => setCanDeleteDepartment(false));

    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table" size={"small"}>
                            <TableBody>
                                <TableRow
                                    onClick={() => history.push(`/departments/${departmentId}/subjects`)}
                                    className={classes.clickable}>
                                    <TableCell>
                                        {"Unidades Curriculares"}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    onClick={() => history.push(`/departments/${departmentId}/users`)}
                                    className={classes.clickable}>
                                    <TableCell>
                                        {"Utilizadores"}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    onClick={() => history.push(`/departments/${departmentId}/evaluations`)}
                                    className={classes.clickable}>
                                    <TableCell>
                                        {"Avaliações"}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    onClick={() => history.push(`/departments/${departmentId}/vigilances`)}
                                    className={classes.clickable}>
                                    <TableCell>
                                        {"Vigilâncias"}
                                    </TableCell>
                                </TableRow>
                                <TableRow
                                    onClick={() => history.push(`/departments/${departmentId}/scores`)}
                                    className={classes.clickable}>
                                    <TableCell>
                                        {"Pontos"}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {canDeleteDepartment ?
                        <DeleteDepartmentButton departmentId={departmentId}/>
                        : null
                    }

                </div>
            )}
        </div>
    );
}
