import React from 'react';
import {SubjectsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {ButtonWithConfirmation} from "../util/Buttons";

const useStyles = defaultStyles();

export default function DeleteSubjectButton(props: { subjectId: number }) {
    const classes = useStyles();
    const subjectsAPI = new SubjectsApi(config);

    function deleteSubject(): Promise<Response> {
        return subjectsAPI.deleteOneSubject(props.subjectId)
    }

    return (
        <ButtonWithConfirmation
            buttonText={"Eliminar unidade curricular"}
            dialogDescription={""}
            dialogTitle={"Eliminar esta unidade curricular?"}
            buttonClassName={classes.deleteButton}
            submit={deleteSubject}/>
    );

}
