import React from 'react';
import {Card} from "@material-ui/core";
import {defaultStyles} from "../../styles";
import {CardProps} from "@material-ui/core/Card/Card";

const useStyles = defaultStyles();
const baseElevation = 3;
const hoverElevation = 10;

export default function StyledCard(props: CardProps) {

    const classes = useStyles();
    const [shadow, setShadow] = React.useState(baseElevation);

    function onMouseOver() {
        setShadow(hoverElevation);
    }

    function onMouseOut() {
        setShadow(baseElevation);
    }

    return (
        <Card
            className={classes.roundedCard}
            variant="elevation"
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
            elevation={shadow}
            {...props}
        >
            {props.children}

        </Card>
    );
}
