import React from 'react';

import {defaultStyles} from '../../styles';
import RolesTable from "./RolesTable";
import {PageContext} from "../contexts/PageContext";

const useStyles = defaultStyles();

export default function RolesPage() {

    const classes = useStyles();
    React.useEffect(fetchTableRows, []);
    const pageContext = React.useContext(PageContext) as Array<any>;

    function fetchTableRows() {
        pageContext[1]("Roles");
    }

    return (
        <div className={classes.paperNoMargin}>
            <RolesTable/>
        </div>
    );
}
