import {makeStyles} from "@material-ui/core/styles";
import './Fonts/fontsCSS.css';
import green from '@material-ui/core/colors/green';
import {grey} from "@material-ui/core/colors";
import {ThemeOptions} from "@material-ui/core";

export const lightTheme: ThemeOptions = {
    palette: {
        type: "light",
        background: {
            default: grey[100],
        },
        primary: {
            main: "#007ee0",
        },
        secondary: {
            main: green[500],
        },
    }
};

export const darkTheme: ThemeOptions = {
    palette: {
        type: "dark",
        background: {
            default: "#292929",
        },
        primary: {
            main: "#007ee0",
        },
        secondary: {
            main: green[500],
        },
    },
};

export function defaultStyles() {
    return makeStyles(theme => ({
        container: {
            display: "flex",
            alignItems: "center"
        },
        border: {
            borderBottom: "2px solid lightgray",
            width: "100%"
        },
        content: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingRight: theme.spacing(0.5),
            paddingLeft: theme.spacing(0.5),
            fontWeight: 500,
            fontSize: 12,
            color: "lightgray"
        },
        home: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
        },
        paddedCard: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
            borderRadius:16,
            padding:10,
            margin:10,
        },
        root: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.paper,
        },
        logo: {
            marginLeft: "5%",
            objectFit: "contain",
            maxWidth: "10em"
        },
        clickable: {
            cursor: "pointer",

            '&:hover': {
                textDecoration: 'underline',
            },
        },
        mainTitle: {
            marginTop: 10,
            textAlign: "center",
            fontSize: '3em',
            fontWeight: "bold"
        },
        secondaryTitle: {
            fontSize: '2em',
            whiteSpace: 'nowrap',
            margin: theme.spacing(4)
        },
        secondaryTitleMarginTop: {
            fontSize: '2em',
            whiteSpace: 'nowrap',
            marginTop: theme.spacing(4)
        },
        tertiaryTitle: {
            fontSize: '1.5em',
            whiteSpace: 'nowrap',
            margin: theme.spacing(3)
        },
        tertiaryTitleMarginTop: {
            fontSize: '1.5em',
            whiteSpace: 'nowrap',
            marginTop: theme.spacing(3)
        },
        tertiaryTitleMarginBot: {
            fontSize: '1.5em',
            whiteSpace: 'nowrap',
            marginBottom: theme.spacing(2),
        },
        paper: {
            marginTop: theme.spacing(5),
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        },
        paperNoMargin: {
            display: "table",
            margin: "auto",
            marginTop: 0
        },
        paperRounded: {
            display: "table",
            padding: 10,
            marginTop: theme.spacing(5),
            margin: "auto",
            backgroundColor: theme.palette.background.paper,
            borderRadius: 16,
        },
        loginStatus: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: theme.spacing(2),
            whiteSpace: 'nowrap',
            marginRight: theme.spacing(2)
        },
        centeredWithMaxWidth: {
            maxWidth: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "0 auto"
        },
        centered: {
            textAlign: "center",
        },
        centeredText: {
            fontFamily: "Arial",
            textAlign: "center",
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main
        },
        form: {
            width: "100%", // Fix IE 11 issue.
            marginTop: theme.spacing(3),
            maxWidth: "50%",
            minWidth: "30%",
            padding: "1%",
            alignItems: "center"
        },
        submit: {
            margin: theme.spacing(3, 0, 2)
        },
        textField: {
            textAlign: "center",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: "100%"
        },
        dateField: {
            textAlign: "center",
            whiteSpace: "nowrap",
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: "100%"
        },
        undercoverLink: {
            flexGrow: 1,
            color: "inherit",
            textDecoration: "none",
        },
        noColorLink: {
            flexGrow: 1,
            color: "inherit",
        },
        appbar: {
            background: theme.palette.background.paper,
            border: "solid",
            borderLeft: "none",
            borderRight: "none",
            borderColor: "black",
            color: "black",
            boxShadow: "none",
        },
        roundedCard: {
            width: "100%",
            borderRadius: 16,
            // DO NOT PUT MARGIN
            cursor: "pointer",

            '&:hover': {
                // backgroundColor: "#f8f8f8"
            },
        },
        cardListTitle: {
            fontSize: '1.5em',
            textAlign: 'center'
        },
        text: {
            fontFamily: "Arial",
            margin: 0
        },
        boldText: {
            fontFamily: "Arial",
            fontWeight: "bold"
        },
        italicText: {
            fontFamily: "Arial",
            fontWeight: "bold"
        },
        textMarginBottom: {
            fontFamily: "Arial",
            marginBottom: theme.spacing(1)
        },
        biggerText: {
            fontFamily: "Arial",
            fontSize: "medium"
        },
        errorMessage: {
            color: "red"
        },
        usePasswordLoginText: {
            // color: "#585858",
            // cursor: "pointer",
            // textDecoration: "underline",
            textTransform: "none",
            textAlign: "center",
            margin: "auto",

        },
        success: {
            color: "green"
        },
        tableHead: {
            fontWeight: 'bold'
        },

        textFieldSpaced: {
            textAlign: "center",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            width: "100%"
        },
        OAuthButton: {
            width: "100%",
            height: 42,
            marginTop: theme.spacing(1),
        },
        baseButton: {
            width: "100%",
            height: 42,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        deleteButton: {
            color: "red",
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff'
        },
        tabsRoot: {
            justifyContent: "center"
        },
        tabsScroller: {
            flexGrow: 0
        },
        newAppBar: {
            width: "100vw",
            position: "relative",
            left: "calc(-50vw + 50%)",
            boxShadow: "none",
        },
        footer: {
            marginTop: "auto"
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            marginLeft: 'auto',
            transform: 'rotate(180deg)',
        },
        link: {
            fontSize: "0.9rem",
        },
        body: {
            minHeight: "100vh",
            margin: 0,
        },
        radioGroup: {
            margin: theme.spacing(2),
        }
    }))
}