import React from 'react';
import {EditionsApi, UserMinimalDTO} from "../../typescript-fetch-client-generated";
import {defaultStyles} from "../../styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Backdrop, Checkbox, IconButton, TableHead} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {useHistory} from "react-router-dom";
import {Delete} from "@material-ui/icons";
import {config} from "../../configuration";
import {errorCallback, successCallback, userSorter} from "../util/util";
import {useSnackbar} from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import {BasicButton} from "../util/Buttons";

const useStyles = defaultStyles();

export default function SmallUserTable(props: {
    users: Array<UserMinimalDTO>,
    deleteOne?: (id: number) => void,
    editionId?: string,
    headLecturers?: Array<UserMinimalDTO>,
    canModifyEdition?: boolean
}) {

    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();

    const editionsAPI: EditionsApi = new EditionsApi(config);

    const [oldSelectedHeadLecturers, setOldSelectedHeadLecturers] = React.useState<Array<UserMinimalDTO>>([]);
    const [selectedHeadLecturers, setSelectedHeadLecturers] = React.useState<Array<UserMinimalDTO>>([]);
    const [pendingChanges, setPendingChanges] = React.useState<boolean>(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [sortedUsers, setSortedUsers] = React.useState<Array<UserMinimalDTO>>([]);


    React.useEffect(initialSetup, [props.users]);
    React.useEffect(checkPendingChanges, [selectedHeadLecturers, oldSelectedHeadLecturers]);

    function initialSetup() {
        if (props.headLecturers !== undefined) {
            setOldSelectedHeadLecturers(props.headLecturers);
            setSelectedHeadLecturers(props.headLecturers);
        }
        setSortedUsers(props.users.sort(userSorter));
    }

    function isHeadLecturer(u: UserMinimalDTO): boolean {
        return selectedHeadLecturers.findIndex(x => x.id === u.id) >= 0;
    }

    function toggleHeadLecturer(u: UserMinimalDTO) {
        let arr = [...selectedHeadLecturers];

        let i = arr.findIndex(x => x.id === u.id);
        if (i === -1 || arr.length === 0) arr.push(u);
        else arr.splice(i, 1);

        setSelectedHeadLecturers(arr);
    }

    function checkPendingChanges() {

        if (!shouldShowHeadLecturers()) {
            setPendingChanges(false);
            return;
        }

        let result = false;

        if (selectedHeadLecturers.length !== oldSelectedHeadLecturers.length)
            result = true;
        else {
            let aux1 = [...selectedHeadLecturers];
            let aux2 = [...oldSelectedHeadLecturers];

            aux1.sort((a, b) => a.id!! - b.id!!);
            aux2.sort((a, b) => a.id!! - b.id!!);

            let i = 0;
            while (i < aux1.length) {
                if (aux1[i].id !== aux2[i].id) {
                    result = true;
                    break;
                }
                i++;
            }
        }

        setPendingChanges(result);
    }

    function cancelChanges() {
        setSelectedHeadLecturers(oldSelectedHeadLecturers);
    }


    function applyChanges() {
        setOpenBackdrop(true);

        if (props.headLecturers === undefined || props.editionId === undefined) {
            console.log("this should not happen")
            return
        }

        editionsAPI.modifyHeadLecturersOfEdition(props.editionId, selectedHeadLecturers.map(x => x.id!!))
            .then(_ => {
                successCallback(snackbar);
                setOldSelectedHeadLecturers(selectedHeadLecturers);
            })
            .catch((e) => errorCallback(snackbar, e))
            .finally(() => {
                setOpenBackdrop(false);
            });

    }

    function shouldShowHeadLecturers() {
        return props.headLecturers !== undefined
    }

    return (
        <div className={classes.paperNoMargin}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table" size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.tableHead}>Nome</TableCell>
                            <TableCell className={classes.tableHead}>Email</TableCell>
                            {shouldShowHeadLecturers() ?
                                <TableCell className={classes.tableHead}>Regente</TableCell>
                                : null}
                            {props.deleteOne ?
                                <TableCell/> : null
                            }
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {sortedUsers.map(u =>
                            <TableRow key={u.id} onClick={() => history.push(`/users/${u.id}`)}
                                      className={classes.clickable}>
                                <TableCell>{u.name}</TableCell>
                                <TableCell>{u.email}</TableCell>
                                {shouldShowHeadLecturers() ?
                                    <TableCell>
                                        <Checkbox onChange={() => toggleHeadLecturer(u)}
                                                  checked={isHeadLecturer(u)}
                                                  disabled={!props.canModifyEdition}
                                                  onClick={(e) => e.stopPropagation()}
                                        />
                                    </TableCell> : null
                                }
                                {props.deleteOne ?
                                    <TableCell>

                                        <IconButton onClick={(e) => {
                                            e.stopPropagation();
                                            props.deleteOne!!(u.id!!);
                                        }}>
                                            <Delete/>
                                        </IconButton>


                                    </TableCell>
                                    : null}

                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {pendingChanges ?
                <div>
                    <BasicButton onClick={cancelChanges} style={{width: "45%", alignSelf: "right", marginRight: "5%"}}>
                        Cancelar
                    </BasicButton>
                    <BasicButton onClick={applyChanges} style={{width: "45%", alignSelf: "right"}}>
                        Aplicar
                    </BasicButton>
                </div> : null}

            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
}
