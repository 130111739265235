import {Configuration, ConfigurationParameters} from "./typescript-fetch-client-generated";

let config = new Configuration({
        apiKey: "",
        username: "",
        password: "",
        accessToken: ""
    } as ConfigurationParameters
);

export {config};
