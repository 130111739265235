import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from "@material-ui/core/CircularProgress";
import {DepartmentDTO, DepartmentsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {useHistory} from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import {genericErrorCallback} from "../util/util";


const useStyles = defaultStyles();

let departmentsApi: DepartmentsApi = new DepartmentsApi(config);

export default function DepartmentTable() {
    const classes = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(true);
    const [rows, setRows] = React.useState<Array<DepartmentDTO>>(new Array<DepartmentDTO>());
    React.useEffect(fetchData, [])

    function fetchData() {
        departmentsApi.getAllDepartments().then(
            r => {
                setRows(r);
                setIsLoading(false);
            }
        ).catch(genericErrorCallback)
    }

    return (
        <div>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size={"small"}>
                        <TableBody>
                            {rows?.map(row => (
                                <TableRow key={row.name} onClick={() => history.push(`/departments/${row.id}`)}
                                          className={classes.clickable}>
                                    <TableCell>
                                        {row.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}
