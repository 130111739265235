import React from 'react';
import {UserExtendedDTO} from "../../typescript-fetch-client-generated";
import {
    DepartmentsScoreItem,
    DepartmentUsersItem,
    EvaluationsItem,
    SubjectsItem,
    VigilanceRequestsItem
} from "./NavItems";


export default function NavListSecretary(me: UserExtendedDTO) {

    return [
        <DepartmentUsersItem departmentId={me.department?.id!!}/>,
        <SubjectsItem departmentId={me.department?.id!!}/>,
        <VigilanceRequestsItem departmentId={me.department?.id!!}/>,
        <EvaluationsItem departmentId={me.department?.id!!}/>,
        <DepartmentsScoreItem departmentId={me.department?.id!!}/>
    ];
}






























