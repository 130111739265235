import React from 'react';
import {defaultStyles} from "../../styles";
import {FormControlLabel, Grid, Switch} from "@material-ui/core";
import {CustomThemeContext} from "../util/CustomThemeProvider";
import {PageContext} from "../contexts/PageContext";


const useStyles = defaultStyles();


export default function SettingsPage() {
    const classes = useStyles();

    const pageContext = React.useContext(PageContext) as Array<any>;
    const {darkMode, setDarkMode} = React.useContext(CustomThemeContext);

    React.useEffect(initialSetup, []);

    function initialSetup() {
        pageContext[1]("Definições");
    }

    function toggleDarkMode() {
        setDarkMode(!darkMode);
    }

    return (
        <div className={classes.paper}>
            <Grid container direction={"column"}>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={darkMode}
                                onChange={toggleDarkMode}
                            />
                        }
                        label="Modo escuro"
                    />
                </Grid>
            </Grid>
        </div>
    );
}
