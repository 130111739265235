import React from 'react';
import MaterialTable, {Column} from 'material-table';
import {GlobalApi, GlobalParameterDTO} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {tableIcons} from "../../icons";
import {genericErrorCallback, getErrorMessage} from "../util/util";
import {defaultStyles} from "../../styles";
import {useSnackbar} from "notistack";
import {CircularProgress} from "@material-ui/core";

interface Row {
    id: string,
    value: string;
    description: string,
    type: string,
    width: string
}

interface TableState {
    columns: Array<Column<Row>>;
    data: Row[];
}

const useStyles = defaultStyles();

export default function GlobalParametersTable() {
    const classes = useStyles();
    const snackbar = useSnackbar();

    let globalAPI = new GlobalApi(config);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(fetchData, []);

    const [state, setState] = React.useState<TableState>({
        columns: [
            {title: 'Id', field: 'id', editable: "never", width: '35%'},
            {title: 'Valor', field: 'value', width: '8%'},
            {title: 'Descrição', field: 'description', width: '49%'},
            {title: 'Tipo', field: 'type', editable: "never", width: '8%'},
        ],
        data: new Array<Row>(),
    });

    function fetchData() {
        globalAPI.getAll().then(
            r => {
                setState(
                    prevState => {
                        const data = r.map(
                            dto => (dto as Row)
                        );
                        return {...prevState, data}
                    }
                )
                setIsLoading(false);
            }
        ).catch(genericErrorCallback)
    }

    function successCallback() {
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    return (
        <div className={classes.paper} style={window.innerWidth > 1000 ? {width: "80%"} : {width: "100%"}}>

            {isLoading ? <CircularProgress/> :
                <MaterialTable
                    style={{width: "100%"}}
                    icons={tableIcons}
                    title="Parâmetros Globais do Sistema"
                    columns={state.columns}
                    data={state.data}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve) => {
                                setTimeout(() => {
                                    resolve();
                                    if (oldData) {
                                        const data = [...state.data];
                                        data[data.indexOf(oldData)] = newData;

                                        globalAPI.setOne(oldData.id, newData as GlobalParameterDTO)
                                            .then(_ => {
                                                successCallback()
                                                setState({...state, data});
                                            })
                                            .catch(errorCallback)

                                    }
                                }, 600);
                            })
                    }}
                />}
        </div>

    );
}