import React from 'react';
import {
    VigilanceRequestExtendedDTO,
    VigilanceRequestsApi,
    YearSemesterDTO
} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {Grid, Typography} from "@material-ui/core";
import RejectionCard from "./RejectionCard";
import {genericErrorCallback, Roles, Scopes} from "../util/util";
import {AppContext} from "../contexts/AppContext";
import CenteredCircularProgress from "../util/CenteredCircularProgress";
import PaginatedCardList from "../util/PaginatedCardList";


const useStyles = defaultStyles();

let vigilancesAPI: VigilanceRequestsApi = new VigilanceRequestsApi(config);


export default function RejectionCardList(props: { scope: number, yearSemester: YearSemesterDTO }) {
    const classes = useStyles();

    const [rows, setRows] = React.useState<Array<VigilanceRequestExtendedDTO>>(new Array<VigilanceRequestExtendedDTO>());
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(fetchRows, [props.scope, props.yearSemester])
    const appContext = React.useContext(AppContext);


    function fetchRows() {
        switch (props.scope) {
            case Scopes.DEPARTMENT:
                vigilancesAPI.getAllUnconfirmedRejectedForEdition(
                    props.yearSemester.year!!, props.yearSemester.semester!!, appContext.me.value.department?.id!!
                )
                    .then(ur => {
                        sortAndSetRows(ur);
                        setIsLoading(false);
                    })
                    .catch(genericErrorCallback);
                break;
            case Scopes.ALL:
                vigilancesAPI.getAllUnconfirmedRejectedForEdition(props.yearSemester.year!!, props.yearSemester.semester!!)
                    .then(ur => {
                        sortAndSetRows(ur);
                        setIsLoading(false);
                    })
                    .catch(genericErrorCallback);
                break;
            default:
                break;
        }
    }

    function sortAndSetRows(data: VigilanceRequestExtendedDTO[]) {
        if (isDepartmentPresident()) {
            // delegated first
            data.sort(
                (a, b) =>
                    a.delegated === b.delegated ? 0 : a.delegated ? -1 : 1)
        } else {
            // delegated last
            data = data.filter(d => !d.delegated)
        }
        setRows(data)
    }

    function isDepartmentPresident(): boolean {
        const me = appContext.me.value;
        return !!me?.roles?.includes(Roles.ROLE_DEPARTMENT_PRESIDENT);
    }

    return (
        <div className={classes.paddedCard}>
            {isLoading ? (
                <CenteredCircularProgress/>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.cardListTitle}>Rejeições pendentes</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {rows.length === 0 ? (
                            <Typography className={classes.centeredText}>
                                Não existem rejeições à espera de revisão.
                            </Typography>
                        ) : <PaginatedCardList
                            cards={rows?.map(vigilance => (
                                <RejectionCard vigilance={vigilance}/>))}
                        />
                        }
                    </Grid>
                </Grid>
            )}
        </div>
    );
}
