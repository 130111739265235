import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {EvaluationExtendedDTO, EvaluationsApi, YearSemesterDTO} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {evaluationSorter, genericErrorCallback, Scopes} from "../util/util";
import {AppContext} from "../contexts/AppContext";
import EvaluationsTableView from "./EvaluationsTableView";


const useStyles = defaultStyles();


export default function EvaluationsTable(props: { scope: number, yearSemester: YearSemesterDTO, departmentId?: number, subjectId?: number }) {
    const classes = useStyles();

    let evaluationsAPI = new EvaluationsApi(config);

    const [rows, setRows] = React.useState<Array<EvaluationExtendedDTO>>(new Array<EvaluationExtendedDTO>());
    React.useEffect(fetchTableRows, [props.scope, props.yearSemester]);
    React.useEffect(sortRows, [rows]);
    const [isLoading, setIsLoading] = React.useState(true);
    const appContext = React.useContext(AppContext);

    function fetchTableRows() {
        setRows([]);
        switch (props.scope) {
            case Scopes.ME:
                evaluationsAPI.getMyEvaluations(
                    props.yearSemester.year, props.yearSemester.semester
                ).then(r => {
                    setRows(r);
                    setIsLoading(false);
                }).catch(genericErrorCallback);
                break;
            case Scopes.ALL:
                evaluationsAPI.getAllEvaluationsExtended(
                    undefined,
                    props.yearSemester.year, props.yearSemester.semester
                )
                    .then(r => {
                            setRows(r);
                            setIsLoading(false);
                        }
                    ).catch(genericErrorCallback)
                break;
            case Scopes.DEPARTMENT:
                const dId = props.departmentId ? props.departmentId : appContext.me.value.department?.id;
                evaluationsAPI.getAllEvaluationsExtended(
                    dId, props.yearSemester.year, props.yearSemester.semester, props.subjectId
                ).then(r => {
                    setRows(r);
                    setIsLoading(false);
                }).catch(genericErrorCallback);
                break;
            default:
                break;
        }
    }

    function sortRows() {
        setRows(rows.sort(evaluationSorter));
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? (
                <CircularProgress/>
            ) : <EvaluationsTableView rows={rows}/>}
        </div>
    );
}
