import React from 'react';
import Typography from "@material-ui/core/Typography";

import {defaultStyles} from '../../styles';
import {GlobalApi, YearSemesterDTO} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {genericErrorCallback, renderYearSemester} from "../util/util";
import CircularProgress from "@material-ui/core/CircularProgress";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {AppContext} from "../contexts/AppContext";
import {PageContext} from "../contexts/PageContext";

const useStyles = defaultStyles();

export default function OldSemestersPage() {
    const classes = useStyles();
    const globalAPI = new GlobalApi(config);

    const [isLoading, setIsLoading] = React.useState(true);
    const [editions, setEditions] = React.useState<Array<YearSemesterDTO>>([]);
    const appContext = React.useContext(AppContext);

    React.useEffect(fetchData, []);
    const pageContext = React.useContext(PageContext) as Array<any>;


    function fetchData() {
        Promise.all([globalAPI.enumerateSystemEditions()])
            .then(values => {
                setEditions(values[0])
                setIsLoading(false);
            })
            .catch(genericErrorCallback)
        pageContext[1]("Semestres");
    }

    function handleClick(yS: YearSemesterDTO) {
        appContext.yearSemester.set(yS);
    }

    function renderRow(row: YearSemesterDTO) {
        const s = renderYearSemester(row);
        return row.id === appContext.yearSemester.value.id ?
            <Typography className={classes.italicText}> {s} </Typography>
            : <Typography className={classes.text}>{s}</Typography>;
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <TableContainer component={Paper}>
                    <Table size={"small"}>
                        <TableBody>
                            {editions?.map(row => (
                                <TableRow key={row.id}
                                          onClick={() => handleClick(row)}
                                          className={classes.clickable}>
                                    <TableCell>
                                        {renderRow(row)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}
