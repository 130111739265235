import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    EditionDTO,
    EditionsApi,
    EvaluationsApi,
    SubjectExtendedDTO,
    SubjectsApi
} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {Table, TableBody, TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {genericErrorCallback, getErrorMessage, Scopes} from "../util/util";
import {useSnackbar} from "notistack";
import DeleteSubjectButton from "./DeleteSubjectButton";
import EditionDetails from "../editions/EditionDetails";
import {AppContext} from "../contexts/AppContext";
import {PageContext} from "../contexts/PageContext";
import {BasicButton} from "../util/Buttons";
import EvaluationsTable from "../evaluations/EvaluationsTable";
import Typography from "@material-ui/core/Typography";
import {useHistory} from "react-router-dom";

const useStyles = defaultStyles();

export default function SubjectDetailedCard(props: any) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const history = useHistory();

    const subjectsAPI = new SubjectsApi(config);
    const editionsAPI = new EditionsApi(config);
    const evaluationAPI = new EvaluationsApi(config);

    const subjectId = parseInt(props.match.params.subject_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [subject, setSubject] = React.useState<SubjectExtendedDTO>({});
    const [currentEdition, setCurrentEdition] = React.useState<EditionDTO>({})
    const [editionsTableState, setEditionsTableState] = React.useState(true);
    const [canDelete, setCanDelete] = React.useState(false);
    const [canCreateEdition, setCanCreateEdition] = React.useState(false);
    const [canCreateEvaluation, setCanCreateEvaluation] = React.useState(false);

    const appContext = React.useContext(AppContext);
    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(fetchData, [appContext.yearSemester.value, editionsTableState])

    function fetchData() {
        subjectsAPI.getOneSubjectExtended(subjectId).then(
            s => {
                setSubject(s);

                editionsAPI
                    .getSpecificEdition(subjectId, appContext.yearSemester.value.year!!, appContext.yearSemester.value.semester!!)
                    .then(e => {
                        setCurrentEdition(e);
                        setIsLoading(false);
                        evaluationAPI.canAddNewEvaluation(e.id!!)
                            .then(_ => setCanCreateEvaluation(true))
                            .catch(_ => setCanCreateEvaluation(false));
                    }).catch(() => {
                    setCurrentEdition({});
                    setIsLoading(false);
                });

            }).catch(genericErrorCallback)


        subjectsAPI.canDeleteOneSubject(subjectId)
            .then(_ => setCanDelete(true))
            .catch(_ => setCanDelete(false));

        editionsAPI.canAddNewEditionForCurrentSemester(subjectId)
            .then(_ => setCanCreateEdition(true))
            .catch(_ => setCanCreateEdition(false));

        pageContext[1]("Unidade Curricular");
    }

    function currentEditionExists(): boolean {
        return currentEdition.id !== undefined;
    }

    function createEditionHandle() {
        editionsAPI
            .addNewEditionForCurrentSemester(subjectId)
            .then(() => {
                setEditionsTableState(!editionsTableState);
                successCallback();
            })
            .catch(errorCallback)
    }

    function successCallback() {
        snackbar.enqueueSnackbar("Edição criada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    return (
        <div className={classes.centeredWithMaxWidth}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>
                    <TableContainer component={Paper}>
                        <Table size={"small"}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Nome:</TableCell>
                                    <TableCell
                                        align={"right"}>{subject.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Departamento:</TableCell>
                                    <TableCell
                                        align={"right"}>{subject.department?.name}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {!currentEditionExists() && canCreateEdition ?
                        <BasicButton
                            onClick={createEditionHandle}
                        >
                            Abrir edição para o semestre mais recente
                        </BasicButton> : null
                    }

                    {currentEditionExists() ?
                        <EditionDetails editionId={currentEdition.id!!}/> : null}

                    <Typography component="h3" variant="h3" className={classes.tertiaryTitle}>
                        Avaliações
                    </Typography>

                    <EvaluationsTable
                        scope={Scopes.DEPARTMENT}
                        yearSemester={appContext.yearSemester.value}
                        subjectId={subjectId}
                    />

                    {canCreateEvaluation ?

                        <BasicButton
                            onClick={_ => history.push(`/editions/${currentEdition.id!!}/addEvaluation`)}
                        >
                            Criar nova avaliação
                        </BasicButton> : null
                    }


                    {canDelete ?
                        <DeleteSubjectButton subjectId={subjectId}/> : null
                    }

                </div>
            )}
        </div>
    );
}
