import React from 'react';
import {Box, CardContent, CardHeader, Grid, IconButton} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {EvaluationExtendedDTO, VigilanceRequestMinimalDTO} from "../../typescript-fetch-client-generated";
import {defaultStyles} from "../../styles";
import {DateComponent, TimeComponent} from "../util/DatetimeComponents";
import Collapse from '@material-ui/core/Collapse';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {CustomLink, DTYPE, renderDtype} from "../util/util";
import {useHistory} from "react-router";
import StyledCard from "../util/StyledCard";
import CenteredCircularProgress from "../util/CenteredCircularProgress";
import {ExpandMore} from "@material-ui/icons";

const useStyles = defaultStyles();

export default function EvaluationCard(props: { evaluation: EvaluationExtendedDTO }) {
    const classes = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(true);
    const [expanded, setExpanded] = React.useState(false);
    const [typeCounter, setTypeCounter] = React.useState({accepted: 0, rejected: 0, pending: 0});
    const [filteredVigilances, setFilteredVigilances] = React.useState<Array<VigilanceRequestMinimalDTO>>([])
    React.useEffect(init, [props.evaluation]);

    function incrementCounter(dtype: string | undefined) {
        switch (dtype) {
            case DTYPE.ACCEPTED:
                typeCounter.accepted++;
                break;
            case DTYPE.REJECTED:
                typeCounter.rejected++;
                break;
            case DTYPE.PENDING:
                typeCounter.pending++;
                break;
        }
        setTypeCounter(typeCounter);
    }

    function init() {
        props.evaluation.vigilances!!.map(v => incrementCounter(v.dtype!!))

        setFilteredVigilances(
            props.evaluation
                .vigilances!!
                .filter(v => (
                        v.dtype === DTYPE.ACCEPTED || v.dtype === DTYPE.PENDING
                    )
                ))

        setIsLoading(false);
        console.log(props.evaluation.id)

    }


    return (
        <div>
            {isLoading ? (
                    <CenteredCircularProgress/>
                ) :
                (
                    <StyledCard
                        onClick={() => history.push(`evaluations/${props.evaluation.id}`)}>
                        <CardHeader
                            title={props.evaluation.subject?.name}
                            titleTypographyProps={{variant: 'body1'}}
                            subheader={`${DateComponent(new Date(props.evaluation.datetime!!))} às ${TimeComponent(new Date(props.evaluation.datetime!!))}`}
                        >
                        </CardHeader>


                        <Grid container direction={"row"} justify={"space-between"} alignItems={"baseline"}>
                            <Grid item>
                                <CardContent style={{paddingTop: 0, paddingBottom: 0}}>
                                    <Typography variant="body1" component={'span'}>
                                        {"Vigilantes: "}
                                        <Box fontWeight="bold" display='inline'>
                                            {`${typeCounter.accepted} de ${typeCounter.pending + typeCounter.accepted}`}
                                        </Box>
                                    </Typography>
                                </CardContent>
                            </Grid>
                            <Grid item>
                                {filteredVigilances.length > 0 ?
                                    <IconButton
                                        className={expanded ? classes.expandOpen : classes.expand}
                                        onClick={(e) => {
                                            setExpanded(!expanded);
                                            e.stopPropagation();
                                        }}
                                        aria-expanded={expanded}
                                        aria-label="show more"
                                    >
                                        <ExpandMore/>
                                    </IconButton>
                                    : null}
                            </Grid>
                        </Grid>

                        <Collapse in={expanded} timeout="auto" unmountOnExit={true}>
                            <CardContent>
                                <TableContainer>
                                    <Table aria-label="simple table" size={"small"}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableHead}>Docente</TableCell>
                                                <TableCell className={classes.tableHead}>Estado</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredVigilances.map(v => (
                                                <TableRow key={v.lecturer?.name}>
                                                    <TableCell>
                                                        <CustomLink href={`mailto:${v.lecturer?.email}`}>
                                                            {v.lecturer?.name}
                                                        </CustomLink>
                                                    </TableCell>
                                                    <TableCell>{renderDtype(v.dtype!!)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardContent>
                        </Collapse>


                    </StyledCard>
                )}
        </div>

    );
}
