import React from "react";
import { makeStyles } from "@material-ui/core";
import {defaultStyles} from "../../styles";


const DividerWithText : any = ({ children } : any) => {
    const useStyles = defaultStyles();
    const classes = useStyles()
    return (
        <div className={classes.container}>
            <div className={classes.border} />
            <span className={classes.content}>{children}</span>
            <div className={classes.border} />
        </div>
    );
};
export default DividerWithText;