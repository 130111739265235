import React from 'react';
import ProfileInfo from "./ProfileInfo";
import {PageContext} from "../contexts/PageContext";
import {AppContext} from "../contexts/AppContext";

export default function MyProfilePage() {
    const [isLoading, setIsLoading] = React.useState(true);
    const pageContext = React.useContext(PageContext) as Array<any>;
    const appContext = React.useContext(AppContext);

    React.useEffect(fetchData, [])

    function fetchData() {
        setIsLoading(false);
        pageContext[1]("Perfil");

    }

    return (
        <div>
            {isLoading ? null : <ProfileInfo userId={appContext.me.value.id!!}/>}
        </div>
    )
}
