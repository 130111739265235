import React from 'react';
import {VigilanceRequestsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {ButtonWithConfirmation} from "../util/Buttons";
import {defaultStyles} from "../../styles";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";

const useStyles = defaultStyles();

export default function CancelVigilanceButton(props: { vigilanceId: number }) {
    const classes = useStyles();

    const vigilanceAPI: VigilanceRequestsApi = new VigilanceRequestsApi(config);
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    function cancelVigilance(): Promise<Response> {
        return vigilanceAPI.cancelOneVigilanceRequest(props.vigilanceId)
            .then(v => {
                notifyUpdateContext.notify();
                return Promise.resolve(v);
            })
            .catch(_ => Promise.reject());
    }

    return (
        <ButtonWithConfirmation
            buttonText={"Cancelar vigilância"}
            dialogDescription={""}
            dialogTitle={"Cancelar esta vigilância?"}
            buttonClassName={classes.deleteButton}
            submit={cancelVigilance}/>
    );

}
