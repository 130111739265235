import GenericVigilancesPage from "./GenericVigilancesPage";
import {ConditionalDepartmentText, genericErrorCallback, Scopes} from "../util/util";
import React from "react";
import {PageContext} from "../contexts/PageContext";
import {DepartmentDTO, DepartmentsApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {defaultStyles} from "../../styles";
import {CircularProgress} from "@material-ui/core";

const useStyles = defaultStyles();

export default function DepartmentVigilancesPage(props: any) {
    const classes = useStyles();

    const departmentAPI = new DepartmentsApi(config);

    const departmentId = parseInt(props.match.params.department_id);

    const [department, setDepartment] = React.useState<DepartmentDTO>({});
    const [isLoading, setIsLoading] = React.useState(true);

    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(updateContext, []);

    function updateContext() {
        pageContext[1]("Vigilâncias");

        departmentAPI.getOneDepartment(departmentId)
            .then(d => {
                setDepartment(d);
                setIsLoading(false);
            }).catch(genericErrorCallback);
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? <CircularProgress/> : (
                <div className={classes.centeredWithMaxWidth}>

                    <ConditionalDepartmentText department={department}/>

                    <GenericVigilancesPage scope={Scopes.DEPARTMENT} departmentId={departmentId}/>
                </div>
            )}

        </div>
    );
}
