import React from 'react';
import {CardActions, CardContent, CardHeader, Grid, Tooltip} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {VigilanceRequestExtendedDTO} from "../../typescript-fetch-client-generated";
import {defaultStyles} from "../../styles";
import {DateComponent, TimeComponent} from "../util/DatetimeComponents";
import CenteredCircularProgress from "../util/CenteredCircularProgress";
import StyledCard from "../util/StyledCard";
import {useHistory} from "react-router";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useStyles = defaultStyles();

export default function RejectionCard(props: { vigilance: VigilanceRequestExtendedDTO }) {

    const classes = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(fetchData, [props.vigilance])

    function fetchData() {
        setIsLoading(false);
    }

    return (
        <div>
            {
                isLoading ? (
                        <CenteredCircularProgress/>
                    ) :
                    (
                        <StyledCard
                            onClick={() => history.push(`${"unconfirmed/" + props.vigilance.id}`)}
                        >
                            <CardHeader

                                title={props.vigilance.subject?.name}
                                titleTypographyProps={{variant: 'body1'}}
                                subheader={`${DateComponent(new Date(props.vigilance.evaluationDatetime!!))} às ${TimeComponent(new Date(props.vigilance.evaluationDatetime!!))}`}
                            >

                            </CardHeader>
                            <CardContent style={{paddingTop: 0, paddingBottom: 0}}>
                                <Grid container direction={"row"} justify={"space-between"} alignItems={"center"}>
                                    <Grid item>
                                        <Typography>
                                            {props.vigilance.lecturer?.name}
                                        </Typography>
                                        <Typography variant="body1" component={'span'} style={{fontWeight: "bold"}}>
                                            {props.vigilance.rejectionType?.description}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {
                                            props.vigilance.delegated ? <span>
                                                <Tooltip title={"Passado ao presidente do departamento"}>
                                                    <ErrorOutlineIcon/>
                                                </Tooltip> "Passado ao presidente do departamento"</span>: null
                                        }
                                    </Grid>
                                </Grid>


                            </CardContent>
                            <CardActions>

                            </CardActions>

                        </StyledCard>
                    )

            }
        </div>

    );
}
