import React from 'react';
import ProfileInfo from "./ProfileInfo";
import {PageContext} from "../contexts/PageContext";

export default function ProfilePage(props: any) {
    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(updateContext);

    function updateContext(){
        pageContext[1]("Perfil");
    }
    const userId = parseInt(props.match.params.user_id);
    return <ProfileInfo userId={userId}/>;
}
