import React from 'react';

import {defaultStyles} from '../../styles';
import EvaluationsTable from "./EvaluationsTable";
import {Scopes} from "../util/util";
import {CircularProgress} from "@material-ui/core";
import {AppContext, AppContextInterface} from "../contexts/AppContext";
import {PageContext} from "../contexts/PageContext";

const useStyles = defaultStyles();

export default function MyEvaluationsPage() {
    const classes = useStyles();

    const [isLoading, setIsLoading] = React.useState(true);

    const appContext = React.useContext(AppContext) as AppContextInterface;
    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(fetchData, []);

    function fetchData() {
        setIsLoading(false);
        pageContext[1]("Avaliações");
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? <CircularProgress/> :
                <div className={classes.centeredWithMaxWidth}>

                    <EvaluationsTable
                        scope={Scopes.ME}
                        yearSemester={appContext.yearSemester.value}
                    />
                </div>
            }
        </div>
    );
}
