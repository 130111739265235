import React from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {getErrorMessage} from "./util";
import {BasicButton} from "./Buttons";

export default function GenericPrompt(
    props: {
        buttonStyle?: React.CSSProperties | undefined;
        buttonText: string, title: string, description?: string, children: Array<JSX.Element>,
        submit: () => Promise<any>, validate?: () => boolean, successCallback: () => void,
        parseError?: (e: Response) => string, disabled?: boolean,
        confirmButtonText?: string
    }) {

    const snackbar = useSnackbar();

    const [open, setOpen] = React.useState(false);
    const [buttonsDisabled, disableButtons] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        resetForm();
    };

    const handleCloseAndSubmit = () => {
        disableButtons(true);

        if (props.validate && !props.validate()) {
            disableButtons(false);
            errorCallback();
            return;
        }

        props.submit()
            .then(_ => {
                successCallback();
                setOpen(false);
                resetForm();
                props.successCallback();
            })
            .catch(e => {
                disableButtons(false);
                props.parseError ? errorCallbackString(props.parseError(e)) : errorCallback(e);
            });
    };

    function resetForm() {
        disableButtons(false);
    }

    function successCallback() {
        snackbar.enqueueSnackbar(
            "Operação efetuada com sucesso.",
            {variant: "success"})
    }

    function errorCallback(e?: Response) {
        console.log(e)
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    function errorCallbackString(msg: string) {
        snackbar.enqueueSnackbar(msg, {variant: "error"})
    }


    return (
        <div>
            <BasicButton style={props.buttonStyle} onClick={handleClickOpen} disabled={props.disabled}>
                {props.buttonText}
            </BasicButton>
            <Dialog fullWidth
                    maxWidth="xs" open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    {props.description ?
                        <DialogContentText>
                            {props.description}
                        </DialogContentText>
                        : null}
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <BasicButton onClick={handleClose} disabled={buttonsDisabled}>
                        Cancelar
                    </BasicButton>
                    <BasicButton onClick={handleCloseAndSubmit} disabled={buttonsDisabled}>
                        {props.confirmButtonText ? props.confirmButtonText : "Submeter"}
                    </BasicButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}