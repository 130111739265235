import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {CapabilitiesApi, CapabilityDTO, RoleDTO} from "../../typescript-fetch-client-generated";
import {genericErrorCallback} from "../util/util";


const useStyles = defaultStyles();

let capabilitiesAPI: CapabilitiesApi = new CapabilitiesApi(config);

export default function CapabilitiesTable(props: {role: undefined | RoleDTO}) {
    const classes = useStyles();

    const [rows, setRows] = React.useState<Array<CapabilityDTO>>(new Array<CapabilityDTO>())
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(fetchData, [])

    function fetchData() {
        capabilitiesAPI.getAllCapabilities().then(
            capabilities => {
                if (props.role !== undefined)
                    setRows(
                        capabilities.filter(c => (props.role?.capabilities?.includes(c.id!!)))
                    );
                else {
                    setRows(capabilities);
                }
                setIsLoading(false);
            }
        ).catch(genericErrorCallback)
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <TableContainer
                    component={Paper}
                >
                    <Table aria-label="simple table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead}>Id</TableCell>
                                <TableCell className={classes.tableHead}>Descrição</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.description}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}
