import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    DepartmentDTO,
    DepartmentsApi,
    LecturerDTO,
    LecturersApi,
    UserDTO,
    UsersApi,
    VigilantRecordsApi
} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {Box, Grid, Paper, Table, TableBody, Typography} from "@material-ui/core";
import UserRolesTable from "../roles/UserRolesTable";
import VigilantRecord from "./VigilantRecord";
import AddRolePrompt from "./AddRolePrompt";
import RemoveRolePrompt from "./RemoveRolePrompt";
import ToggleVigilantStatus from "./ToggleVigilantStatus";
import {useHistory} from "react-router-dom";
import {genericErrorCallback} from "../util/util";
import RemoveLecturerComponent from "./RemoveLecturerComponent";
import {AppContext} from "../contexts/AppContext";
import {BasicButton} from "../util/Buttons";


const useStyles = defaultStyles();

let usersAPI = new UsersApi(config);
let lecturersAPI = new LecturersApi(config);
let departmentsAPI = new DepartmentsApi(config);
let vigilantRecordAPI = new VigilantRecordsApi(config);

export default function ProfileInfo(props: { userId: number }) {
    const classes = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(true);
    const [user, setUser] = React.useState<UserDTO>({});
    const [lecturer, setLecturer] = React.useState<LecturerDTO | undefined>(undefined);
    const [department, setDepartment] = React.useState<DepartmentDTO>({});

    const [canSetRoles, setCanSetRoles] = React.useState(false);
    const [canToggleStatus, setCanToggleStatus] = React.useState(false);
    const [canRemoveUser, setCanRemoveUser] = React.useState(false);
    const appContext = React.useContext(AppContext);

    React.useEffect(fetchData, [])

    function fetchData() {
        usersAPI.getOneUser(props.userId)
            .then(u => {
                setUser(u);

                Promise.all([departmentsAPI.getOneDepartment(u.department!!)])
                    .then(results => {
                        const [d] = results;
                        setDepartment(d);
                        setIsLoading(false);

                    })
                    .catch(genericErrorCallback);

                lecturersAPI.getOneLecturer(props.userId)
                    .then(l => setLecturer(l))
                    .catch(() => {
                    })

                lecturersAPI.canToggleVigilantStatus(u.id!!)
                    .then(_ => setCanToggleStatus(true))
                    .catch(_ => setCanToggleStatus(false));

                usersAPI.canDeleteOneUser(u.id!!)
                    .then(_ => setCanRemoveUser(true))
                    .catch(_ => setCanRemoveUser(false));

                usersAPI.canSetRoles(u.id!!)
                    .then(_ => setCanSetRoles(true))
                    .catch(_ => setCanSetRoles(false));

            })
            .catch(genericErrorCallback);
    }

    function dataChanged() {
        fetchData();
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centered}>
                    <div className={classes.centered}>
                        {user?.removed ?
                            <Typography className={classes.tertiaryTitleMarginBot}>
                                UTILIZADOR REMOVIDO
                            </Typography> : null
                        }

                        <Paper>
                            <Table size={"small"}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>
                                            Nome:
                                        </TableCell>
                                        <TableCell align="right">
                                            {user.name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>
                                            Email:
                                        </TableCell>
                                        <TableCell align="right">
                                            {user.email}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>
                                            Departamento:
                                        </TableCell>
                                        <TableCell align="right">
                                            {department.name}
                                        </TableCell>
                                    </TableRow>
                                    {lecturer ?
                                        <TableRow>
                                            <TableCell className={classes.tableHead}>
                                                Estado de vigilante:
                                            </TableCell>
                                            <TableCell align="right">
                                                {lecturer.vigilantStatus ? "Ativo" : "Dispensado"}
                                            </TableCell>
                                        </TableRow> : null
                                    }
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>
                                            Roles:
                                        </TableCell>
                                        <TableCell align="right">
                                            <UserRolesTable user={user}/>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Paper>

                    </div>

                    {canSetRoles ? <>
                        <AddRolePrompt user={user} callback={dataChanged}/>
                        <RemoveRolePrompt user={user} callback={dataChanged}/>
                    </> : null}

                    {lecturer && canToggleStatus ?
                        <ToggleVigilantStatus lecturer={lecturer} callback={dataChanged}/>
                        : null}
                    {user && !user.removed && canRemoveUser ?
                        <RemoveLecturerComponent user={user} callback={dataChanged}/>
                        : null}


                    <VigilantRecord
                        lecturerId={props.userId}
                        completeTable={canRemoveUser || canSetRoles || canToggleStatus}
                        canModify={canRemoveUser || canSetRoles || canToggleStatus}
                    />

                    {appContext.me.value.id === props.userId ?
                        <Box mt={1} className={classes.paperNoMargin}>

                            <Grid container direction={"row"} spacing={2}>
                                {/*<Grid item>*/}
                                {/*    <BasicButton*/}
                                {/*        onClick={() => history.push("/editProfile")}*/}
                                {/*    >*/}
                                {/*        Editar dados pessoais*/}
                                {/*    </BasicButton>*/}
                                {/*</Grid>*/}
                                {appContext.me.value.hasLocalPassword ?
                                    <Grid item>
                                        <BasicButton
                                            onClick={() => history.push("/changePassword")}
                                        >
                                            Alterar password
                                        </BasicButton>
                                    </Grid> : null
                                }


                            </Grid>
                        </Box>
                        : null
                    }


                </div>

            )}
        </div>
    );
}
