import React from 'react';
import {
    DepartmentMinimalDTO,
    DepartmentsApi,
    LogLevelDTO,
    LogsApi,
    PageLogEntryDTO,
    UserExtendedDTO
} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {genericErrorCallback, Scopes} from "../util/util";
import {defaultStyles} from "../../styles";
import {CircularProgress, FormControl, IconButton, Link, Select, TextField} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DatetimeComponent from "../util/DatetimeComponents";
import Grid from "@material-ui/core/Grid";
import {Autocomplete} from "@material-ui/lab";
import {ArrowBack, ArrowForward, Search} from "@material-ui/icons";

const useStyles = defaultStyles();

export default function LogsTable(props: { scope: number, me: UserExtendedDTO }) {
    const classes = useStyles();

    const initialPageSize = 10;

    let logsAPI = new LogsApi(config);
    let departmentsAPI = new DepartmentsApi(config);
    const [isLoading, setIsLoading] = React.useState(true);
    const [pageNumber, setPageNumber] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(initialPageSize);
    const [pageLogEntryDTO, setPageLogEntryDTO] = React.useState<PageLogEntryDTO>({});
    const [logLevels, setLogLevels] = React.useState<Array<LogLevelDTO>>([]);
    const [departments, setDepartments] = React.useState<Array<DepartmentMinimalDTO>>([]);
    const [departmentsFiltered, setDepartmentsFiltered] = React.useState<Array<DepartmentMinimalDTO>>([]);
    const [levelsFiltered, setLevelsFiltered] = React.useState<Array<LogLevelDTO>>([]);
    const [messageFilter, setMessageFilter] = React.useState<string>("");

    React.useEffect(fetchData, [pageNumber, pageSize]);

    function fetchData() {
        let res;
        const body = {
            filterLevels: levelsFiltered,
            filterDepartments: departmentsFiltered.map(d => d.id!!),
            descriptionContains: messageFilter
        };

        if (props.scope === Scopes.ALL) {
            res = logsAPI.getLogs(pageSize, body, pageNumber)
        } else {
            // Scopes.DEPARTMENT
            res = logsAPI.getDepartmentLogs(props.me.department?.id!!, pageSize, body, pageNumber)
        }

        Promise.all([res, logsAPI.getLogLevels(), departmentsAPI.getAllMinimalDepartments()])
            .then(values => {
                setPageLogEntryDTO(values[0]);
                setLogLevels(values[1]);
                setDepartments(values[2]);
                setIsLoading(false);
            })
            .catch(genericErrorCallback)
    }


    function nextPage() {
        setPageNumber(pageNumber + 1);
    }

    function previousPage() {
        if (pageNumber > 0)
            setPageNumber(pageNumber - 1);
    }

    return (
        <div className={classes.paperNoMargin}>

            {isLoading ? <CircularProgress/> :
                <>
                    <Grid container spacing={1}>
                        <Grid item>
                            <Autocomplete
                                multiple
                                // value={levelsFiltered}
                                size={"medium"}
                                options={logLevels}
                                getOptionLabel={(option: LogLevelDTO) => option.text!!}
                                disableCloseOnSelect
                                onChange={(_, v) => setLevelsFiltered(v)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label="Log levels"
                                    />
                                )}
                                fullWidth={true}
                                className={classes.textFieldSpaced}
                            />
                        </Grid>
                        {props.scope === Scopes.ALL ?
                            <Grid item>
                                <Autocomplete
                                    multiple
                                    fullWidth={true}
                                    style={{minWidth: "250px"}}
                                    options={departments}
                                    getOptionLabel={(option: DepartmentMinimalDTO) => option.name!!}
                                    disableCloseOnSelect
                                    onChange={(_, v) => setDepartmentsFiltered(v)}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label="Departments"
                                            fullWidth
                                        />
                                    )}
                                    className={classes.textFieldSpaced}
                                />
                            </Grid> : null}
                        <Grid item>
                            <TextField
                                variant={"outlined"}
                                fullWidth={true}
                                onChange={e => {
                                    setMessageFilter(e.target.value);
                                }}
                                className={classes.textFieldSpaced}
                            />
                        </Grid>
                        <IconButton color="secondary" aria-label="search" onClick={fetchData}>
                            <Search/>
                        </IconButton>
                    </Grid>


                    <TableContainer
                        component={Paper}
                    >
                        <Table size={"small"}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Id</TableCell>
                                    <TableCell className={classes.tableHead}>Nível</TableCell>
                                    <TableCell className={classes.tableHead}>Data</TableCell>
                                    <TableCell className={classes.tableHead}>Descrição</TableCell>
                                    <TableCell className={classes.tableHead}>Autor</TableCell>
                                    <TableCell className={classes.tableHead}>Departamento</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageLogEntryDTO.content?.map(row => (
                                    <TableRow key={row.id}>
                                        <TableCell>{row.id}</TableCell>
                                        <TableCell>{row.level?.text}</TableCell>
                                        <TableCell>{DatetimeComponent(new Date(row.datetime!!))}</TableCell>
                                        <TableCell>{row.description}</TableCell>
                                        <TableCell>{row.user ? `${row.user?.name}(id:${row.user?.id})` : "Sistema"}</TableCell>
                                        <TableCell>{`${row.department?.name}(id:${row.department?.id})`}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container>
                        <Grid item>
                            <FormControl>
                                <Select
                                    native
                                    value={pageSize}
                                    onChange={(e) => setPageSize(e.target.value as number)}
                                >
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </Select>

                            </FormControl>
                        </Grid>

                        <Grid item>
                            <IconButton onClick={previousPage} disabled={pageNumber === 0}>
                                <ArrowBack/>
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={nextPage} disabled={pageLogEntryDTO.empty}>
                                <ArrowForward/>
                            </IconButton>
                        </Grid>

                    </Grid>
                    <Link
                        href={`data:text/json;charset=utf-8,${encodeURIComponent(
                            JSON.stringify(pageLogEntryDTO.content)
                        )}`}
                        download="logs.json"
                        className={classes.noColorLink}
                    >
                        {`Download Json`}
                    </Link>
                </>
            }
        </div>

    );
}