export default function DatetimeComponent(date: Date) {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    const H = date.getHours();
    const M = date.getMinutes();
    return '' + y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d) +
        ' ' + (H < 10 ? '0' + H : H) + ":" + (M < 10 ? '0' + M : M);
}

export function DateComponent(date: Date) {
    const d = date.getDate();
    const m = date.getMonth() + 1; //Month from 0 to 11
    const y = date.getFullYear();
    return '' + y + '-' + (m < 10 ? '0' + m : m) + '-' + (d < 10 ? '0' + d : d);
}

export function TimeComponent(date: Date) {
    const H = date.getHours();
    const M = date.getMinutes();
    return ' ' + (H < 10 ? '0' + H : H) + ":" + (M < 10 ? '0' + M : M);
}