import React from 'react';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {EvaluationExtendedDTO, EvaluationsApi, VigilanceRequestsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import DatetimeComponent from "../util/DatetimeComponents";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import VigilancesTable from "../vigilances/VigilancesTable";
import AttendanceComponent from "../vigilances/AttendanceComponent";
import CreateVigilance from "../vigilances/CreateVigilance";
import DeleteEvaluationButton from "./DeleteEvaluationButton";
import {CustomLink, genericErrorCallback} from "../util/util";
import CancelSomeVigilantsPrompt from "./CancelSomeVigilantsPrompt";
import AskVigilantsPrompt from "./AskVigilantsPrompt";
import {Grid} from "@material-ui/core";
import {PageContext} from "../contexts/PageContext";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";
import {useHistory} from "react-router-dom";
import ChangeEvaluationDatePrompt from "./ChangeEvaluationDatePrompt";

const useStyles = defaultStyles();

export default function EvaluationDetailedCard(props: any) {
    const evaluationAPI = new EvaluationsApi(config);
    const vigilancesAPI = new VigilanceRequestsApi(config);

    const classes = useStyles();
    const history = useHistory();

    const evaluationId = parseInt(props.match.params.evaluation_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [evaluation, setEvaluation] = React.useState<EvaluationExtendedDTO>({});
    const [presencePermission, setPresencePermission] = React.useState(false);
    const [canDelete, setCanDelete] = React.useState(false);
    const [canCancelSome, setCanCancelSome] = React.useState(false);
    const [canAskVigilants, setCanAskVigilants] = React.useState(false);
    const [canAddVigilant, setCanAddVigilant] = React.useState(false);
    const [canGetVigilances, setCanGetVigilances] = React.useState(false);

    const pageContext = React.useContext(PageContext) as Array<any>;
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    React.useEffect(fetchData, [notifyUpdateContext.state])
    React.useEffect(fetchPermissions, [])

    function fetchData() {
        pageContext[1]("Avaliação");
        evaluationAPI.getOneEvaluationExtended(evaluationId).then(
            e => {
                setEvaluation(e);
                if (e.vigilances && e.vigilances.length > 0) {
                    setCanDelete(false);
                } else {
                    evaluationAPI.canDeleteOneEvaluation(evaluationId)
                        .then(_ => setCanDelete(true))
                        .catch(_ => setCanDelete(false));
                }
                setIsLoading(false);
            }
        ).catch(genericErrorCallback)
    }

    function fetchPermissions() {


        vigilancesAPI.canAskVigilants(evaluationId)
            .then(_ => setCanAskVigilants(true))
            .catch(_ => setCanAskVigilants(false));

        vigilancesAPI.canGetAllVigilanceRequests(undefined, evaluationId, undefined)
            .then(_ => setCanGetVigilances(true))
            .catch(_ => setCanGetVigilances(false));

        vigilancesAPI.canAddNewVigilanceRequest(evaluationId)
            .then(_ => setCanAddVigilant(true))
            .catch(_ => setCanAddVigilant(false));

        vigilancesAPI.canCancelSomeVigilanceRequest(evaluationId)
            .then(_ => setCanCancelSome(true))
            .catch(_ => setCanCancelSome(false));

        evaluationAPI.testAttendancePermissions(evaluationId)
            .then(t => setPresencePermission(t))
            .catch(genericErrorCallback);
    }


    return (
        <div className={classes.paper}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div>
                    <div className={classes.centeredWithMaxWidth}>
                        <TableContainer
                            component={Paper}>
                            <Table aria-label="simple table">
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Unidade Curricular:</TableCell>
                                        <TableCell
                                            onClick={() => history.push(`/subjects/${evaluation.subject?.id}`)}
                                            className={classes.clickable}
                                            align={"right"}>
                                            {evaluation.subject?.name}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Data:</TableCell>
                                        <TableCell
                                            align={"right"}>{DatetimeComponent(new Date(evaluation.datetime!!))}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Duração:</TableCell>
                                        <TableCell align={"right"}>{evaluation.duration} minutos</TableCell>
                                    </TableRow>
                                    {/*<TableRow>*/}
                                    {/*    <TableCell>Salas</TableCell>*/}
                                    {/*    <TableCell align="right">*/}
                                    {/*        {evaluation.rooms!!.map(l =>*/}
                                    {/*            <p key={l.id}>*/}
                                    {/*                {l.building + " " + l.number}*/}
                                    {/*            </p>*/}
                                    {/*        )}*/}
                                    {/*    </TableCell>*/}
                                    {/*</TableRow>*/}
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Requisitante:</TableCell>
                                        <TableCell align="right">
                                            <CustomLink href={`mailto:${evaluation.applicant?.email}`}
                                                        variant={"body1"}>
                                                {evaluation.applicant?.name}
                                            </CustomLink>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    {canGetVigilances ?
                        <div className={classes.centered}>
                            <Typography className={classes.tertiaryTitle}>
                                Vigilantes
                            </Typography>

                            <VigilancesTable evaluationId={evaluation.id} scope={-1} compact={true}
                                             yearSemester={undefined}/>

                            <Grid container direction={"column"}>

                                <Grid item>
                                    <AskVigilantsPrompt evaluationId={evaluationId}
                                                        disabled={!canAskVigilants}/>
                                </Grid>

                                <Grid item>
                                    <CancelSomeVigilantsPrompt
                                        evaluationId={evaluationId}
                                        disabled={!canCancelSome}
                                        maxValue={5}
                                    />
                                </Grid>

                                <Grid item>
                                    <ChangeEvaluationDatePrompt
                                        evaluation={evaluation}
                                        disabled={!canDelete || evaluation.vigilances?.length!=0}
                                    />
                                </Grid>

                                {canAddVigilant ? <Grid item>
                                        <CreateVigilance evaluationId={evaluationId}/>
                                    </Grid>
                                    : null}

                            </Grid>
                        </div> : null
                    }
                    {(presencePermission) ? (
                            <div className={classes.centeredWithMaxWidth}>
                                <Typography className={classes.tertiaryTitle}>
                                    Presenças
                                </Typography>
                                <AttendanceComponent evaluationId={evaluation.id}/>
                            </div>)
                        : null}

                    {canDelete ?
                        <DeleteEvaluationButton evaluationId={evaluationId}/>
                        : null
                    }


                </div>
            )}
        </div>
    );
}
