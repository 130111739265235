import React from "react";
import {Box, Grid, Table, TableContainer, TableFooter, TablePagination, TableRow,} from "@material-ui/core";

export default function PaginatedCardList(props: { cards: Array<JSX.Element>, rowsPerPage?: number }) {
    const defaultRowsPerPage = 3;

    const [rowsPerPage, setRowsPerPage] = React.useState(defaultRowsPerPage);
    const [page, setPage] = React.useState(0);

    React.useEffect(init, []);

    function init() {
        if (props.rowsPerPage && props.rowsPerPage > 0) {
            setRowsPerPage(props.rowsPerPage)
        }
    }

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Grid container direction={"column"} spacing={2}>
            {props.cards
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((card, index) =>
                    <Grid item key={page * rowsPerPage + index} xs={12} style={{width: "100%"}}>
                        {card}
                    </Grid>
                )}
            <Grid item xs={12}>
                <Box display="flex" alignItems={"center"} justifyContent={"flex-end"}>

                    <TableContainer>
                        <Table>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[3, 5, 10]}
                                        count={props.cards.length}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        labelRowsPerPage={"Itens por pág."}
                                        rowsPerPage={rowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>


                </Box>
            </Grid>
        </Grid>
    );
}
