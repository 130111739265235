import React from 'react';
import {UserExtendedDTO} from "../../typescript-fetch-client-generated";
import {
    CapabilitiesItem,
    DepartmentLogsItem,
    DepartmentsScoreItem,
    DepartmentUsersItem,
    EvaluationsItem,
    RolesItem,
    SubjectsItem,
    VigilanceRequestsItem
} from "./NavItems";


export default function NavDepartmentAdmin(me: UserExtendedDTO) {

    return [
        <DepartmentUsersItem departmentId={me.department?.id!!}/>,
        <SubjectsItem departmentId={me.department?.id!!}/>,
        <VigilanceRequestsItem departmentId={me.department?.id!!}/>,
        <EvaluationsItem departmentId={me.department?.id!!}/>,
        <RolesItem/>,
        <CapabilitiesItem/>,
        <DepartmentsScoreItem departmentId={me.department?.id!!}/>,
        <DepartmentLogsItem/>
    ]
}






































