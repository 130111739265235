import React from 'react';
import {
    VigilanceRequestExtendedDTO,
    VigilanceRequestsApi,
    YearSemesterDTO
} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import VigilanceCardComponent from "./VigilanceCard";
import {Grid, Typography} from "@material-ui/core";
import {DTYPE, genericErrorCallback, Scopes, vigilanceRequestSorter} from "../util/util";
import PaginatedCardList from "../util/PaginatedCardList";
import {AppContext} from "../contexts/AppContext";
import CenteredCircularProgress from "../util/CenteredCircularProgress";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";


const useStyles = defaultStyles();

let vigilancesAPI: VigilanceRequestsApi = new VigilanceRequestsApi(config);


export default function VigilanceCardList(props: { scope: number, yearSemester: YearSemesterDTO }) {
    const classes = useStyles();


    const [rows, setRows] = React.useState<Array<VigilanceRequestExtendedDTO>>(new Array<VigilanceRequestExtendedDTO>());
    const [isLoading, setIsLoading] = React.useState(true);
    const appContext = React.useContext(AppContext);
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);


    React.useEffect(fetchRows, [props.scope, props.yearSemester, notifyUpdateContext.state])

    function filterByDate(rows: Array<VigilanceRequestExtendedDTO>) {
        return rows
            .filter(r => new Date(r.evaluationDatetime!!) >= new Date())
            .sort(vigilanceRequestSorter);
    }

    function filterByDateAndType(rows: Array<VigilanceRequestExtendedDTO>) {
        return filterByDate(rows)
            .filter(r => r.dtype === DTYPE.ACCEPTED || r.dtype === DTYPE.PENDING || (r.dtype === DTYPE.REJECTED && r.rejectionType?.needsConfirmation && r.confirmed == null))
    }

    function fetchRows() {
        switch (props.scope) {
            case Scopes.ME:
                vigilancesAPI.getAllVigilanceRequestsExtendedForEdition(
                    props.yearSemester.year!!, props.yearSemester.semester!!,
                    undefined, undefined, appContext.me.value.id)
                    .then(rows => {
                        setRows(filterByDateAndType(rows));
                        setIsLoading(false);
                    }).catch(genericErrorCallback);
                break;
            case Scopes.DEPARTMENT:
                vigilancesAPI.getAllVigilanceRequestsExtendedForEdition(
                    props.yearSemester.year!!, props.yearSemester.semester!!, appContext.me.value.department?.id!!
                )
                    .then(vs => {
                        setRows(filterByDate(vs));
                        setIsLoading(false);
                    }).catch(genericErrorCallback);
                break;
            case Scopes.ALL:
                vigilancesAPI.getAllVigilanceRequestsExtendedForEdition(
                    props.yearSemester.year!!, props.yearSemester.semester!!
                )
                    .then(vs => {
                        setRows(filterByDate(vs));
                        setIsLoading(false);
                    }).catch(genericErrorCallback);
                break;
            default:
                break;
        }


    }

    return (
        <div className={classes.paddedCard}>
            {isLoading ? (
                <CenteredCircularProgress/>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.cardListTitle}>Próximas vigilâncias</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        {rows.length === 0 ? (
                            <Typography className={classes.centeredText}>
                                Não tem vigilâncias marcadas.
                            </Typography>
                        ) : <PaginatedCardList
                            cards={rows?.map(vigilance => (
                                <VigilanceCardComponent vigilance={vigilance}/>))}
                        />
                        }
                    </Grid>

                </Grid>
            )}

        </div>
    );
}
