import React from 'react';

import {defaultStyles} from '../../styles';
import {List, ListItem} from "@material-ui/core";
import {config} from "../../configuration";
import {GlobalApi} from "../../typescript-fetch-client-generated";
import {ButtonWithConfirmation} from "../util/Buttons";
import {getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";
import GlobalParametersTable from "./GlobalParametersTable";
import {PageContext} from "../contexts/PageContext";
import {AppContext} from "../contexts/AppContext";

const useStyles = defaultStyles();

export default function AdminPage() {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const globalAPI = new GlobalApi(config);

    const pageContext = React.useContext(PageContext) as Array<any>;
    const appContext = React.useContext(AppContext);

    React.useEffect(updateContext, []);

    function updateContext() {
        pageContext[1]("Administração");
    }

    function nextSemester() {
        return globalAPI.moveToNextSemester()
    }

    function dialogDescription() {
        return "O sistema vai avançar para o próximo semestre para todos as departamentos, criando novas fichas de vigilante para todos os docentes. " +
            "Não são criadas edições para quaisquer unidades curriculares. O estado de vigilante de cada docente não é alterado. Proceder?"
    }

    function successCallback() {
        appContext.yearSemester.reset();
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    return (
        <div className={classes.paper}>
            <GlobalParametersTable/>
            <List>
                <ListItem>
                    <ButtonWithConfirmation
                        buttonText={"Avançar para o semestre seguinte"}
                        buttonClassName={classes.deleteButton}
                        dialogTitle={"Avançar para o próximo semestre?"}
                        dialogDescription={dialogDescription()}
                        submit={nextSemester}
                        successCallback={successCallback}
                        errorCallback={errorCallback}
                    />
                </ListItem>
            </List>
        </div>
    );
}
