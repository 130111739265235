import React, {createContext, useState} from "react";

// Create Context Object
export const NotifyUpdateContext = createContext<NotifyUpdateContextInterface>({
    state: true,
    notify: () => {
        //If this shows up
        // console.log("Uninitialized context");
        //
        // You are missing <NotifyUpdateContextProvider>...</NotifyUpdateContextProvider> around your components
    }
});

export interface NotifyUpdateContextInterface {
    state: boolean,
    notify: () => void
}


// Create a provider for components to consume and subscribe to changes
export const NotifyUpdateContextProvider = (props: { children: React.ReactNode; }) => {

    const [state, setState] = useState(true);

    function notify() {
        setState(!state);
    }

    const context: NotifyUpdateContextInterface = {
        state: state,
        notify: notify
    }

    return (
        <NotifyUpdateContext.Provider value={context}>
            {props.children}
        </NotifyUpdateContext.Provider>
    );
};