import React from 'react';
import {defaultStyles} from "../../styles";
import {CircularProgress, Grid, TextField, Typography} from "@material-ui/core";
import {
    EditionExtendedDTO,
    EditionsApi,
    EvaluationDTO,
    EvaluationsApi,
    RoomsApi,
    SimpleDateDTO,
    SimpleEvaluationDTO,
} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {DateTimePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {genericErrorCallback, getErrorMessage, renderYearSemester} from "../util/util";
import {useSnackbar} from "notistack";
import {BackButton, BasicButton} from "../util/Buttons";
import {useHistory} from "react-router";
import {PageContext} from "../contexts/PageContext";


const useStyles = defaultStyles();

export default function NewEvaluationComponent(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const snackbar = useSnackbar();

    const evaluationsApi = new EvaluationsApi(config);
    const roomsAPI = new RoomsApi(config);
    const editionsAPI = new EditionsApi(config);

    //form data
    const [date, setDate] = React.useState<Date | null>();
    const [duration, setDuration] = React.useState(-1);
    const [edition, setEdition] = React.useState<EditionExtendedDTO>({});
    const [selectedRooms, setSelectedRooms] = React.useState<Array<number>>([]);
    // const [availableRooms, setAvailableRooms] = React.useState<Array<RoomDTO>>([]);
    const [errorMessages, setErrorMessages] = React.useState({date: "", duration: ""});
    const [isLoading, setIsLoading] = React.useState(true);

    const editionId = props.match.params.edition_id;
    React.useEffect(fetchData, [])
    const pageContext = React.useContext(PageContext) as Array<any>;

    function fetchData() {
        Promise.all([editionsAPI.getOneExtendedEdition(editionId), roomsAPI.getAllRooms()])
            .then(results => {
                const [e, rooms] = results;
                setEdition(e);
                // setAvailableRooms(rooms);
                setIsLoading(false);
            })
            .catch(genericErrorCallback);
        pageContext[1]("Nova Avaliação");
    }

    function handleChangeDuration(event: React.ChangeEvent<HTMLInputElement>) {
        setDuration(parseInt(event.target.value));
    }

    // function handleRoomsChange(event: React.ChangeEvent<{}>, values: Array<RoomDTO>) {
    //     setSelectedRooms(values.map(r => r.id!!));
    // }

    function handleSubmit() {
        if (!validate()) return;

        const simpleDateDTO: SimpleDateDTO = {
            year: date?.getFullYear(),
            month: date?.getMonth()!! + 1,
            day: date?.getDate(),
            hour: date?.getHours(),
            minute: date?.getMinutes(),
        };

        const simpleDTO: SimpleEvaluationDTO = {
            date: simpleDateDTO,
            duration: duration,
            edition: editionId,
            rooms: selectedRooms
        };

        evaluationsApi.addNewSimpleEvaluation(simpleDTO)
            .then(successCallback)
            .catch(errorCallback);
    }


    function successCallback(evaluationDTO: EvaluationDTO) {
        history.push(`/evaluations/${evaluationDTO.id}`);
        snackbar.enqueueSnackbar("Avaliação criada com sucesso.",
            {
                variant: "success",
                // action: _ =>
                //     <Button onClick={() => history.push(`/evaluations/${evaluationDTO.id}`)}>
                //         Ver
                //     </Button>
            })
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }


    function validate(): boolean {
        let valid = true;
        let dateMessage = "";
        let durationMessage = "";

        if (!date) {
            dateMessage = "Por favor escolha uma data"
            valid = false;
        }
        if (!duration || duration === -1) {
            durationMessage = "Insira uma duração";
            valid = false;
        } else if (duration < 0) {
            durationMessage = "Duração inválida";
            valid = false;
        }
        setErrorMessages({
            date: dateMessage,
            duration: durationMessage
        });
        return valid;
    }

    return (
        <div>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>
                    <Typography className={classes.tertiaryTitleMarginTop}>
                        {edition.subject?.name} - {renderYearSemester({year: edition.year, semester: edition.semester})}
                    </Typography>

                    <form className={classes.form} noValidate>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DateTimePicker value={date}
                                            required
                                            fullWidth
                                            onChange={setDate}
                                            helperText="Selecione a data e hora"
                                            className={classes.textFieldSpaced}
                                            ampm={false}
                            />
                        </MuiPickersUtilsProvider>
                        {errorMessages["date"] === "" ? null :
                            <Typography className={classes.errorMessage}>{errorMessages["date"]}</Typography>}
                        <TextField
                            required
                            variant={"outlined"}
                            label="Duração (minutos)"
                            fullWidth
                            onChange={handleChangeDuration}
                            className={classes.textFieldSpaced}
                        />
                        {errorMessages["duration"] === "" ?
                            null :
                            <Typography className={classes.errorMessage}>{errorMessages["duration"]}</Typography>}
                        {/*<Autocomplete*/}
                        {/*    multiple*/}
                        {/*    options={availableRooms}*/}
                        {/*    fullWidth*/}
                        {/*    size={"medium"}*/}
                        {/*    groupBy={(option: RoomDTO) => option.building!!}*/}
                        {/*    getOptionLabel={(option: RoomDTO) => option.number!!}*/}
                        {/*    disableCloseOnSelect*/}
                        {/*    onChange={handleRoomsChange}*/}
                        {/*    className={classes.textFieldSpaced}*/}
                        {/*    renderInput={(params) => (*/}
                        {/*        <TextField*/}
                        {/*            {...params}*/}
                        {/*            variant="outlined"*/}
                        {/*            label="Salas"*/}
                        {/*            placeholder="Salas"*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*    renderTags={(params: RoomDTO[]) => (*/}
                        {/*        params.map(room => room.building + "-" + room.number).join(" ")*/}
                        {/*    )}*/}
                        {/*/>*/}

                        <Grid container spacing={1}>
                            <Grid item>
                                <BackButton/>
                            </Grid>
                            <Grid item>
                                <BasicButton
                                    onClick={handleSubmit}
                                >
                                    Submeter
                                </BasicButton>
                            </Grid>
                        </Grid>

                    </form>
                </div>
            )}
        </div>
    );
}
