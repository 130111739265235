import React from 'react';
import {Backdrop, Box, CardActions, CardContent, CardHeader} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {VigilanceRequestExtendedDTO, VigilanceRequestsApi} from "../../typescript-fetch-client-generated";
import {defaultStyles} from "../../styles";
import {DateComponent, TimeComponent} from "../util/DatetimeComponents";
import {DTYPE, renderDtype} from "../util/util";
import {useHistory} from "react-router";
import VigilanceButtons from "./VigilanceButtons";
import {config} from "../../configuration";
import StyledCard from "../util/StyledCard";
import CenteredCircularProgress from "../util/CenteredCircularProgress";

const useStyles = defaultStyles();

export default function VigilanceCard(props: { vigilance: VigilanceRequestExtendedDTO }) {
    const vigilanceAPI: VigilanceRequestsApi = new VigilanceRequestsApi(config);

    const classes = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = React.useState(true);
    const [canAnswer, setCanAnswer] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    React.useEffect(fetchData, [props.vigilance])


    function fetchData() {
        vigilanceAPI.canAnswer(props.vigilance.id!!)
            .then(_ => setCanAnswer(true))
            .catch(_ => setCanAnswer(false))
            .finally(() => setIsLoading(false));
    }

    function styleFromDtype(dtype: string) {
        switch (dtype) {
            case DTYPE.PENDING:
                return "blue";
            case DTYPE.DONE:
                return "gray";
            case DTYPE.ACCEPTED:
                return "green";
            case DTYPE.REJECTED:
                return "red";
            case DTYPE.SWAPPED:
                return "darkOrange";
            case DTYPE.EXEMPT:
                return "gray";
            case DTYPE.CANCELLED:
                return "red";
            default:
                return "black";
        }
    }

    return (
        <div>
            {
                isLoading ? (
                        <CenteredCircularProgress/>
                    ) :
                    (
                        <StyledCard
                            onClick={() => history.push(`${"vigilances/" + props.vigilance.id}`)}
                        >

                            <CardHeader
                                title={props.vigilance.subject?.name}
                                titleTypographyProps={{variant: 'body1'}}
                                subheader={`${DateComponent(new Date(props.vigilance.evaluationDatetime!!))} às ${TimeComponent(new Date(props.vigilance.evaluationDatetime!!))}`}
                            >

                            </CardHeader>
                            <CardContent style={{paddingTop: 0, paddingBottom: 0}}>
                                <Typography variant="body1" component={'span'}>
                                    {"Estado: "}
                                    <Box fontWeight="bold" display='inline'>
                                        {renderDtype(props.vigilance.dtype!!)}
                                        {props.vigilance.replacementFor!=null && props.vigilance.replacementFor.dtype=="swapped"?
                                            <span> (transferida) </span> : null}
                                    </Box>
                                </Typography>

                            </CardContent>
                            <CardActions>
                                {canAnswer ?
                                    <VigilanceButtons vigilance={props.vigilance}
                                                      setOpenBackdrop={setOpenBackdrop}/> : null
                                }
                            </CardActions>


                            <Backdrop className={classes.backdrop} open={openBackdrop}>
                                <CircularProgress color="inherit"/>
                            </Backdrop>
                        </StyledCard>
                    )}
        </div>

    );
}
