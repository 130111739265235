import React from "react";
import {TextField,} from "@material-ui/core";
import {config} from "../../configuration";
import {RoleDTO, RolesApi, UserDTO, UsersApi} from "../../typescript-fetch-client-generated";
import GenericPrompt from "../util/GenericPrompt";
import {genericErrorCallback} from "../util/util";

const rolesAPI = new RolesApi(config);
const usersAPI = new UsersApi(config);

export default function RemoveRolePrompt(props: { user: UserDTO, callback: Function }) {
    const [roleSelected, setSelectedRole] = React.useState("");
    const [userRoles, setUserRoles] = React.useState<Array<RoleDTO>>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(fetchData, [props.user]);

    function fetchData() {
        rolesAPI.getAllRoles()
            .then(rs => {
                setUserRoles(rs.filter(r => props.user.roles?.includes(r.id!!)));
                setIsLoading(false);
            })
            .catch(genericErrorCallback)
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault()
        setSelectedRole(event.target.value);
    }

    function submit(): Promise<UserDTO> {
        let roles = [...props.user.roles!!];
        roles.splice(roles.indexOf(roleSelected), 1)

        return usersAPI.setRoles(props.user.id!!, roles)
    }

    function getChildren() {
        return [
            <TextField
                select
                fullWidth
                onChange={handleChange}
                SelectProps={{
                    native: true,
                }}
                variant="outlined"
                key={1}
            >
                <option key={-1} value={""}>
                </option>
                {
                    userRoles.map(r =>
                        <option key={r.id} value={r.id}>
                            {r.description}
                        </option>
                    )
                }
            </TextField>
        ]
    }

    function validate(): boolean {
        return roleSelected !== undefined && roleSelected !== "";
    }

    function successCallback() {
        setSelectedRole("");
        props.callback();
    }

    return (
        <div> {isLoading ? null : (
            <div>
                <GenericPrompt
                    buttonText={"Remover role"}
                    title={"Remover role"}
                    description={"Escolha o role que pretende remover"}
                    submit={submit}
                    children={getChildren()}
                    validate={validate}
                    successCallback={successCallback}/>
            </div>

        )}
        </div>
    );
}