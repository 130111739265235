import React from 'react';
import {Backdrop, Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PenaltyJustificationDTO, VigilanceRequestExtendedDTO, VigilanceRequestsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import DatetimeComponent from "../util/DatetimeComponents";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CancelVigilanceButton from "./CancelVigilanceButton";
import {canAccept, canReject, CustomLink, genericErrorCallback, renderDtype} from "../util/util";
import VigilanceButtons from "./VigilanceButtons";
import {PageContext} from "../contexts/PageContext";
import {useHistory} from "react-router-dom";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";

const useStyles = defaultStyles();

export default function VigilanceDetailedCard(props: any) {
    const classes = useStyles();
    const history = useHistory();

    const vigilanceAPI: VigilanceRequestsApi = new VigilanceRequestsApi(config);

    const vigilanceId = parseInt(props.match.params.vigilance_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [vigilance, setVigilance] = React.useState<VigilanceRequestExtendedDTO>({});
    const [penaJust, setPenaJust] = React.useState<PenaltyJustificationDTO>({})

    const [canAnswer, setCanAnswer] = React.useState(false);
    const [canCancelOne, setCanCancelOne] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const pageContext = React.useContext(PageContext) as Array<any>;
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    React.useEffect(fetchData, [notifyUpdateContext.state])

    function fetchData() {
        vigilanceAPI.getOneExtended(vigilanceId).then(
            v => {
                setVigilance(v);
                if (v.confirmed!=null)
                    vigilanceAPI.getPenaltyJustification(vigilanceId).then(
                                    v => {
                                        setPenaJust(v);
                                    }
                                )
                setIsLoading(false);
            }
        ).catch(genericErrorCallback);

        if (vigilance.confirmed!=null)
            vigilanceAPI.getPenaltyJustification(vigilanceId).then(
                v => {
                    setPenaJust(v);
                }
            ).catch(genericErrorCallback);

        vigilanceAPI.canAnswer(vigilanceId)
            .then(_ => setCanAnswer(true))
            .catch(_ => setCanAnswer(false));

        vigilanceAPI.canCancelOneVigilanceRequest(vigilanceId)
            .then(_ => setCanCancelOne(true))
            .catch(_ => setCanCancelOne(false));

        pageContext[1]("Vigilância");
    }

    return (
        <div className={classes.paper}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>

                    <TableContainer
                        component={Paper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Unidade Curricular:</TableCell>
                                    <TableCell
                                        align="right"
                                        onClick={() => history.push(`/subjects/${vigilance.subject?.id}`)}
                                        className={classes.clickable}
                                    >
                                        {vigilance.subject?.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Data:</TableCell>
                                    <TableCell
                                        align="right">{DatetimeComponent(new Date(vigilance.evaluationDatetime!!))}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Duração:</TableCell>
                                    <TableCell align="right">{vigilance.evaluationDuration} minutos</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Docente convocado:</TableCell>
                                    <TableCell align="right">
                                        {`${vigilance.lecturer?.name} (`}
                                        <CustomLink href={`mailto:${vigilance.lecturer?.email}`}>
                                            {vigilance.lecturer?.email}
                                        </CustomLink>
                                        {")"}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Regentes:</TableCell>
                                    <TableCell align="right">
                                        {vigilance.headLecturers!!.map(l =>
                                            <p key={l.id}>
                                                {`${l.name} (`}
                                                <CustomLink href={`mailto:${l.email}`}>{l.email}</CustomLink>
                                                )
                                            </p>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.tableHead}>Estado:</TableCell>
                                    <TableCell align="right">
                                        {renderDtype(vigilance.dtype!!)}{vigilance.replacedBy ? ` para ${vigilance.replacedBy.lecturer?.name}` : null}
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                     <TableCell className={classes.tableHead}>obs:</TableCell>
                                     <TableCell>
                                        {vigilance.replacementFor != null ?
                                                <p>
                                                    Vigilância criada por troca a pedido de {vigilance.replacementFor.lecturer?.name}.
                                                </p> : null
                                        }

                                        {vigilance.rejectionType != null ?
                                            <span>
                                                {vigilance.rejectionType.description}
                                                {vigilance.justification!=null && vigilance.justification!= ""?
                                                        <p>
                                                        +  {vigilance.justification}
                                                        </p>: null
                                                }
                                                {vigilance.confirmed == null ?
                                                  <Typography component="p" className={classes.text}>
                                                 Penalização pendente de decisão.
                                                 </Typography>: null
                                                }
                                                {vigilance.confirmed != null ?
                                                    <span>
                                                    <Typography component="p" className={classes.text}>
                                                        {vigilance.confirmed ?
                                                            "Não foi aplicada penalização.": "Foi aplicada uma penalização."}
                                                    </Typography>
                                                    <Typography component="p" className={classes.text}>
                                                     +  {penaJust.text}
                                                    </Typography>
                                                    </span>
                                                     : null
                                                }
                                            </span> : null
                                         }

                                </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box margin={"5%"}>
                        {vigilance.replacementFor != null && canAnswer && (canAccept(vigilance) || canReject(vigilance)) ?
                                <Typography component="p" className={classes.text}>
                                    Se aceitar receberá os pontos correspondentes, se rejeitar não há penalização.
                                </Typography> : null
                        }
                        {canAnswer ? <VigilanceButtons vigilance={vigilance} setOpenBackdrop={setOpenBackdrop}/>
                            : null}

                        {canCancelOne ?
                            <CancelVigilanceButton vigilanceId={vigilanceId}/> : null
                        }
                    </Box>
                    <Backdrop className={classes.backdrop} open={openBackdrop}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </div>
            )}
        </div>
    );
}
