import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from "@material-ui/core/CircularProgress";
import {customRound} from "../util/util";

import {
    DepartmentDTO,
    DepartmentsApi,
    ScoreTableEntryDTO,
    VigilantRecordsApi
} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from '../../styles';
import {genericErrorCallback} from "../util/util";
import {Paper, Typography} from "@material-ui/core";
import {PageContext} from "../contexts/PageContext";
import {AppContext} from "../contexts/AppContext";
import {useHistory} from "react-router-dom";

const useStyles = defaultStyles();

export default function DepartmentScoreTable(props: any) {

    const classes = useStyles();

    const vigilantRecordAPI = new VigilantRecordsApi(config);
    const departmentAPI = new DepartmentsApi(config);

    const [rows, setRows] = React.useState<Array<ScoreTableEntryDTO>>([]);
    const [department, setDepartment] = React.useState<DepartmentDTO>({})
    const appContext = React.useContext(AppContext);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(fetchData, [appContext.yearSemester.value])
    const pageContext = React.useContext(PageContext) as Array<any>;
    const departmentId = props.match.params.department_id;

    function fetchData() {
        Promise.all([departmentAPI.getOneDepartment(departmentId), vigilantRecordAPI.getScoreList(departmentId, appContext.yearSemester.value)])
            .then(results => {
                const [d, scoreList] = results;
                setRows(scoreList);
                setDepartment(d)
                pageContext[1](`Pontuações`);
                setIsLoading(false);
            }).catch(genericErrorCallback);
    }

    return (
        <div>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>

                    {appContext.me.value.department?.name !== department?.name ? <Typography
                        className={classes.tertiaryTitleMarginBot}>{`Departamento: ${department?.name}`}</Typography> : null}


                    <div style={{textAlign: "center"}}>
                        <ScoreTable rows={rows.filter(s => s.lecturer?.vigilantStatus)}/>

                        <Typography className={classes.tertiaryTitleMarginTop}>
                            Dispensados
                        </Typography>

                        <ScoreTable rows={rows.filter(s => !s.lecturer?.vigilantStatus)}/>
                    </div>

                </div>
            )}
        </div>
    );
}

function ScoreTable(props: {rows: Array<ScoreTableEntryDTO>}) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Paper>
        <TableContainer>
        <Table aria-label="simple table" size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell className={classes.tableHead}>Posição</TableCell>
                    <TableCell className={classes.tableHead}>Nome</TableCell>
                    <TableCell className={classes.tableHead}>Pontuação</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.rows?.map((row, index) => (
                    <TableRow key={row.lecturer?.id}>
                        <TableCell>{`${index + 1}º`}</TableCell>
                        <TableCell
                            className={classes.clickable}
                            onClick={() => history.push(`/users/${row.lecturer?.id}`)}
                        >
                            {row.lecturer?.name}
                        </TableCell>
                        <TableCell align={"right"}>{customRound(row.score!!, 2)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
        </Paper>
);
}
