import React from "react";
import {Backdrop, TextField} from "@material-ui/core";
import {VigilanceRequestsApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import GenericPrompt from "../util/GenericPrompt";
import CircularProgress from "@material-ui/core/CircularProgress";
import {defaultStyles} from "../../styles";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";

const useStyles = defaultStyles();

export default function CancelSomeVigilantsPrompt(props: { evaluationId: number, maxValue: number, disabled: boolean }) {
    const classes = useStyles();

    const vigilancesAPI = new VigilanceRequestsApi(config);

    const [numberAsked, setNumberAsked] = React.useState(0);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let n = parseInt(event.target.value);
        if (!n) {
            n = 0;
        }
        setNumberAsked(n);
    }

    function submit(): Promise<any> {
        setOpenBackdrop(true)
        return vigilancesAPI.cancelSomeVigilanceRequests(props.evaluationId, numberAsked)
            .then(_ => Promise.resolve())
            .catch(_ => Promise.reject())
            .finally(() => setOpenBackdrop(false));
    }

    function validate(): boolean {
        return numberAsked !== undefined && numberAsked > 0 && numberAsked <= props.maxValue;
    }

    function successCallback() {
        setNumberAsked(0);
        notifyUpdateContext.notify();
    }

    function getChildren() {
        return [
            <TextField
                autoFocus
                margin="dense"
                label="Número"
                onChange={handleNumberChange}
                fullWidth
                key={1}
            />
        ]
    }

    return (
        <div>
            <GenericPrompt
                buttonText={"Cancelar vigilantes"}
                title={"Cancelar vigilantes"}
                description={"Insira o número de vigilâncias que quer cancelar."}
                submit={submit}
                children={getChildren()}
                validate={validate}
                disabled={props.disabled}
                successCallback={successCallback}/>


            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
}