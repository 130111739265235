import React from 'react';
import {AdminItem, CapabilitiesItem, DepartmentsItem, LogsItem, RolesItem} from "./NavItems";

export default function NavAdminItems() {
    return [
        <DepartmentsItem/>,
        <RolesItem/>,
        <CapabilitiesItem/>,
        <AdminItem/>,
        <LogsItem/>
    ]
}






































