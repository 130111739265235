import React from 'react';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {RoleDTO, RolesApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import CapabilitiesTable from "./CapabilitiesTable";
import {genericErrorCallback} from "../util/util";
import {PageContext} from "../contexts/PageContext";

const useStyles = defaultStyles();

export default function EvaluationDetailedCard(props: any) {
    let roleControllerAPI = new RolesApi(config);

    const classes = useStyles();
    const roleId = props.match.params.role_id;
    const [isLoading, setIsLoading] = React.useState(true);
    const [role, setRole] = React.useState<RoleDTO>({});
    React.useEffect(fetchData, [])
    const pageContext = React.useContext(PageContext) as Array<any>;

    function fetchData() {
        roleControllerAPI.getOneRole(roleId).then(
            r => {
                setRole(r);
                setIsLoading(false);
            }
        ).catch(genericErrorCallback)
        pageContext[1]("Role");
    }

    return (
        <div className={classes.paper}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>
                    <Typography variant="h2" component="h2" className={classes.secondaryTitle}>
                        Role
                    </Typography>
                    <TableContainer
                        component={Paper}>
                        <Table aria-label="simple table" size={"small"}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Id</TableCell>
                                    <TableCell
                                        align={"right"}>{role.id}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Descrição</TableCell>
                                    <TableCell align={"right"}>{role.description}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography variant="h2" component="h2" className={classes.tertiaryTitle}>
                        Competências
                    </Typography>
                    <CapabilitiesTable role={role}/>
                </div>
            )}
        </div>
    );
}
