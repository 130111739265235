import GenericVigilancesPage from "./GenericVigilancesPage";
import {Scopes} from "../util/util";
import React from "react";
import {PageContext} from "../contexts/PageContext";

export default function MyVigilancesPage() {
    const pageContext = React.useContext(PageContext) as Array<any>;
    React.useEffect(updateContext);
    function updateContext(){
        pageContext[1]("Vigilâncias");
    }
    return GenericVigilancesPage({scope: Scopes.ME})
}
