import {CircularProgressProps} from "@material-ui/core/CircularProgress/CircularProgress";
import React from "react";
import {CircularProgress} from "@material-ui/core";


export default function CenteredCircularProgress(props: CircularProgressProps): JSX.Element {
    return (
        <CircularProgress {...props} style={{margin: "50%"}} color={"secondary"}/>
    );
}
