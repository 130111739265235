import React, {useEffect} from 'react';
import {defaultStyles} from '../../styles';
import VigilancesTable from "./VigilancesTable";
import {AppContext} from "../contexts/AppContext";
import {Scopes} from "../util/util";

const useStyles = defaultStyles();

export default function GenericVigilancesPage(props: { scope: number, departmentId?: number }) {

    const classes = useStyles();
    const appContext = React.useContext(AppContext);

    useEffect(checkProps)

    function checkProps() {
        if (props.departmentId && props.scope !== Scopes.DEPARTMENT) {
            throw new Error("Make sure u pass 'Scopes.DEPARTMENT' if you are providing a departmentId");
        }
    }

    return (

        <div className={classes.paperNoMargin}>
            <div className={classes.centeredWithMaxWidth}>
                <VigilancesTable scope={props.scope}
                                 yearSemester={appContext.yearSemester.value}
                                 compact={false}
                                 departmentId={props.departmentId}/>
            </div>
        </div>
    );
}
