import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import {UserExtendedDTO} from "../../typescript-fetch-client-generated";
import logoLight from "../../Logos/logo_nova_light.png";
import logoDark from "../../Logos/logo_nova_dark.png";
import {Link} from "react-router-dom";
import {GOOGLE_CLIENT_ID, GOOGLE_COOKIE_POLICY, renderYearSemester} from "../util/util";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {ExitToApp, Help, Settings} from "@material-ui/icons";
import {PageContext} from "../contexts/PageContext";
import {AppContext} from "../contexts/AppContext";
import {useGoogleLogout} from 'react-google-login'
import MenuBookIcon from "@material-ui/icons/MenuBook";


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
            },
            backgroundColor: "rgb(0,128,255)",
        },
        menuButton: {
            position: "absolute",
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(1),
        },
        logo: {
            objectFit: "contain",
            marginLeft: "5%",
            maxWidth: "15em",
        },
        departmentText: {
            whiteSpace: "nowrap",
            color: "inherit"
        },
        semesterText: {
            textAlign: "center",
        },
        userText: {
            marginTop: 10,
            textAlign: "center",
        },
        title: {
            margin: "0 auto",
            paddingRight: theme.spacing(5),
            paddingLeft: theme.spacing(5),
            color: "white",
            fontWeight: "bold",
            fontSize: 35,
            fontFamily: "Trebuchet MS, sans-serif",
        },
        welcome: {
            position: "absolute",
            right: 10,
            textAlign: "center",
            color: "white"
        },
        profileLink: {
            color: "inherit",
            textDecoration: "none",
            '&:hover': {
                textDecoration: 'underline',
            },

        }

    }),
);

interface Props {
    me?: UserExtendedDTO;
    page?: JSX.Element;
    tabs: JSX.Element;
}

export default function ResponsiveDrawer(props: Props) {
    const theme = useTheme();

    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const pageContext = React.useContext(PageContext) as Array<any>;
    const appContext = React.useContext(AppContext);

    const {signOut} = useGoogleLogout({
        clientId: GOOGLE_CLIENT_ID,
        cookiePolicy: GOOGLE_COOKIE_POLICY,
        onLogoutSuccess: () => {
        },
        onFailure: () => {
        }
    })

    function logout() {
        fetch('/api/logout', {
            method: 'GET',
        }).finally(() => {
            signOut(); // Google sign out
            appContext.clearLocalStorage();
            window.location.href = "/login";
        });
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <img
                src={useTheme().palette.type === 'dark' ? logoDark : logoLight}
                alt={"FCT logo"}
                className={classes.logo}/>
            <div className={classes.semesterText}>
                {appContext.me.value.department ?
                    <Typography className={classes.departmentText}>
                        Departamento: {appContext.me.value.department?.name}
                    </Typography>
                    : null
                }

            </div>
            <Typography className={classes.semesterText}>
                {renderYearSemester(appContext.yearSemester.value)}
            </Typography>
            <Divider/>

            <Hidden mdUp>
                <div className={classes.userText}>
                    <Typography>
                        Bem-vindo:
                    </Typography>
                    <Typography>
                        <Link to={"/me"} className={classes.profileLink}>
                            {appContext.me.value.name}
                        </Link>
                    </Typography>

                </div>
                <Divider/>
            </Hidden>


            <List>
                {
                    props.tabs
                }
            </List>
            <Divider/>

            <List>
                <ListItem button component={Link} to={"/old_semesters"}>
                    <ListItemIcon><DateRangeIcon/></ListItemIcon>
                    <ListItemText primary={"Outros Semestres"}/>
                </ListItem>
            </List>

            <Divider/>
            <List>
                <ListItem button component={Link} to={"/me"}>
                    <ListItemIcon><AccountBoxIcon/></ListItemIcon>
                    <ListItemText primary={"Perfil"}/>
                </ListItem>

                <ListItem button component={Link} to={"/settings"}>
                    <ListItemIcon><Settings/></ListItemIcon>
                    <ListItemText primary={"Definições"}/>
                </ListItem>

                <ListItem button key={1} component={Link} to={"/help"}>
                    <ListItemIcon><Help/></ListItemIcon>
                    <ListItemText primary={"Ajuda"}/>
                </ListItem>

                <ListItem button onClick={logout}>
                    <ListItemIcon><ExitToApp/></ListItemIcon>
                    <ListItemText primary={"Sair"}/>
                </ListItem>

            </List>

        </div>
    );

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="absolute" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" noWrap className={classes.title}>
                        {pageContext[0]}
                    </Typography>
                    <Hidden smDown>
                        <Typography className={classes.welcome}>
                            Bem-vindo:<br/>
                            <Link to={"/me"} className={classes.profileLink}>
                                {appContext.me.value.name}
                            </Link>
                        </Typography>
                    </Hidden>

                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        onClick={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <div className={classes.content}>
                    {props.page}
                </div>

            </main>
        </div>
    );

}
