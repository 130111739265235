import React from 'react';
import {defaultStyles} from "../../styles";
import GenericPrompt from "../util/GenericPrompt";
import {Backdrop, TextField} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    EvaluationsApi,
    EvaluationDTO,
    EvaluationExtendedDTO
} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";
import DateFnsUtils from "@date-io/date-fns";


const useStyles = defaultStyles();

export default function ChangeEvaluationDatePrompt(props : {evaluation: EvaluationExtendedDTO, disabled: boolean}) {
    const classes = useStyles();
    const evaluationsAPI = new EvaluationsApi(config);
    const [date, setDate] = React.useState<Date | null>();
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);


    function submit(){
        setOpenBackdrop(true);
        let evaluationDTO : EvaluationDTO =
            {
                id: props.evaluation.id,
                datetime: props.evaluation.datetime,
                duration: props.evaluation.duration,
                edition: props.evaluation.edition!!.id,
                rooms: props.evaluation.rooms!!.map(r=>r.id!!),
                applicant: props.evaluation.applicant!!.id,
                vigilances: props.evaluation.vigilances!!.map(v=>v.id!!)
            }

        //evaluationDTO.datetime = date!!;
        // timezone parece ser ignorado (incorporar na hoar)  TODO: procurar uma melhor solucao
        evaluationDTO.datetime = new Date(date!!.getTime()-date!!.getTimezoneOffset()*60000);
        return evaluationsAPI.modifyOneEvaluation(evaluationDTO.id!!, evaluationDTO)
            .then(_ => Promise.resolve())
            .catch(e => Promise.reject(e))
            .finally(() => setOpenBackdrop(false));
    }

    function validate(): boolean {
        let valid = true;
        if (!date) {
            valid = false;
        }
        return valid;
    }

    function successCallback() {
        notifyUpdateContext.notify();
    }

    function getChildren() {
        return [
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker value={date}
                                required
                                fullWidth
                                onChange={setDate}
                                helperText="Selecione a data e hora"
                                className={classes.textFieldSpaced}
                                ampm={false}
                />
            </MuiPickersUtilsProvider>
        ]
    }

    function parseError(r: Response) {
        return "Erro";
    }

    return (
        <div>
            <GenericPrompt
                buttonText={"Mudar data"}
                title={"Mudar data da avaliação"}
                description={"Escolha a nova data da avaliação"}
                submit={submit}
                children={getChildren()}
                validate={validate}
                successCallback={successCallback}
                parseError={parseError}
                disabled={props.disabled}
            />


            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
}

