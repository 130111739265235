import React from 'react';

import {defaultStyles} from '../../styles';
import EvaluationsTable from "./EvaluationsTable";
import {ConditionalDepartmentText, genericErrorCallback, Scopes} from "../util/util";
import {CircularProgress} from "@material-ui/core";
import {AppContext, AppContextInterface} from "../contexts/AppContext";
import {PageContext} from "../contexts/PageContext";
import {DepartmentDTO, DepartmentsApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";


const useStyles = defaultStyles();

export default function EvaluationsPage(props: any) {
    const classes = useStyles();

    const departmentId = parseInt(props.match.params.department_id);

    const departmentAPI = new DepartmentsApi(config);

    const [isLoading, setIsLoading] = React.useState(true);
    const [department, setDepartment] = React.useState<DepartmentDTO>({});

    const appContext = React.useContext(AppContext) as AppContextInterface;
    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(fetchData, []);

    function fetchData() {
        departmentAPI.getOneDepartment(departmentId)
            .then(d => {
                setDepartment(d);
                setIsLoading(false);
            }).catch(genericErrorCallback);

        pageContext[1]("Avaliações");
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? <CircularProgress/> :
                <div className={classes.centeredWithMaxWidth}>

                    <ConditionalDepartmentText department={department}/>

                    <EvaluationsTable
                        scope={Scopes.DEPARTMENT}
                        yearSemester={appContext.yearSemester.value}
                        departmentId={departmentId}/>
                </div>
            }
        </div>
    );
}
