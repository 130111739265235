import React from 'react';
import NavLecturerItems from "./NavLecturerItems";
import NavDepartmentAdmin from "./NavDepartmentAdminItems";
import NavAdminItems from "./NavAdminItems";
import NavSecretaryItems from "./NavSecretaryItems";
import {Roles} from "../util/util";
import NewNav2 from "./NewNav2";
import {AppContext} from "../contexts/AppContext";
import {List} from "@material-ui/core";
import {HomeItem} from "./NavItems";
import NavDepartmentPresidentItems from "./NavDepartmentPresidentItems";

// const useStyles = defaultStyles();

export default function SimpleTabs(props: { page: JSX.Element }) {

    const [isLoading, setIsLoading] = React.useState(true);
    const appContext = React.useContext(AppContext);

    React.useEffect(getData, [])

    function getData() {
        setIsLoading(false);
    }

    function getNavBar(): JSX.Element {
        const me = appContext.me.value;
        const items: JSX.Element[] = [<HomeItem/>];
        if (me?.roles?.includes(Roles.ROLE_DEPARTMENT_PRESIDENT)) {
            items.push(...NavDepartmentPresidentItems(me));
        }
        if (me?.roles?.includes(Roles.ROLE_LECTURER)) {
            items.push(...NavLecturerItems(me));
        }
        if (me?.roles?.includes(Roles.ROLE_SECRETARY)) {
            items.push(...NavSecretaryItems(me));
        }
        if (me?.roles?.includes(Roles.ROLE_DEPARTMENT_ADMIN)) {
            items.push(...NavDepartmentAdmin(me));
        }
        if (me?.roles?.includes(Roles.ROLE_SYSTEM_ADMIN)) {
            items.push(...NavAdminItems());
        }
        items.filter((x, pos) => items.indexOf(x) === pos);

        return <NewNav2 me={me} page={props.page} tabs={<List>{items}</List>}/>;
    }

    return <div>
        {isLoading ? null : getNavBar()}
    </div>;
}
