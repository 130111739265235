import React from 'react';

import {defaultStyles} from '../../styles';
import {LecturerDTO, LecturersApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {genericErrorCallback} from "../util/util";
import {BasicButton} from "../util/Buttons";

const useStyles = defaultStyles();

export default function ToggleVigilantStatus(props: { lecturer: LecturerDTO, callback: Function }) {
    const classes = useStyles();
    const lecturersAPI = new LecturersApi(config);

    let buttonText;
    if (props.lecturer.vigilantStatus) {
        buttonText = "Marcar como dispensado";
    } else {
        buttonText = "Marcar como ativo";
    }

    function toggleVigilantStatus() {
        lecturersAPI.toggleVigilantStatus(props.lecturer.id!!)
            .then(_ => {
                props.callback();
            })
            .catch(genericErrorCallback);
    }

    return (
        <BasicButton
            onClick={toggleVigilantStatus}
        >
            {buttonText}
        </BasicButton>
    );
}
