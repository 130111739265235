import React from 'react';
import {UserExtendedDTO} from "../../typescript-fetch-client-generated";
import {MyDepartmentItem} from "./NavItems";

export default function NavDepartmentPresidentItems(me: UserExtendedDTO) {

    return [
        <MyDepartmentItem departmentId={me.department?.id!!}/>
    ];
}







































