import React from "react";
import TextField from "@material-ui/core/TextField";
import {defaultStyles} from "../../styles";
import {
    RejectionDTO,
    RejectionTypeDTO,
    RejectionTypesApi,
    VigilanceRequestExtendedDTO,
    VigilanceRequestsApi
} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration"
import {Backdrop, Typography} from "@material-ui/core";
import DatetimeComponent from "../util/DatetimeComponents";
import CircularProgress from "@material-ui/core/CircularProgress";
import {BackSubmitButtons} from "../util/Buttons";
import {genericErrorCallback, getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";
import {PageContext} from "../contexts/PageContext";

const useStyles = defaultStyles();

export default function RejectVigilanceForm(props: any) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const idRequest: number = parseInt(props.match.params.vigilance_id);
    const rejectionTypesApi: RejectionTypesApi = new RejectionTypesApi(config);
    const vigilanceRequestsApi: VigilanceRequestsApi = new VigilanceRequestsApi(config);

    const [rejectionTypes, setRejectionTypes] = React.useState(Array<RejectionTypeDTO>());
    const [vigilance, setVigilance] = React.useState<VigilanceRequestExtendedDTO>({});
    const [rejectionType, setRejectionType] = React.useState<number | undefined>(undefined);
    const [reason, setReason] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState(true);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    React.useEffect(getRejectionTypes, []);

    const pageContext = React.useContext(PageContext) as Array<any>;

    function getRejectionTypes() {
        rejectionTypesApi.getAllRejectionTypes()
            .then(r => setRejectionTypes(r))
            .catch(genericErrorCallback);

        vigilanceRequestsApi.getOneExtended(idRequest)
            .then(v => {
                setVigilance(v);
                setIsLoading(false);
            })
            .catch(genericErrorCallback);
        pageContext[1]("Rejeição de Vigilância");
    }

    function validateForm() {
        return (
            rejectionType !== undefined
            && !isNaN(rejectionType)
            && rejectionType > 0
            && (!(rejectionType >= 0 && rejectionTypes.filter(r => r.id === rejectionType)[0].needsJustification) || reason !== undefined)
        );
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!validateForm()) return;


        setOpenBackdrop(true)

        const obj: RejectionDTO = {
            idType: rejectionType,
            justification: reason
        };

        vigilanceRequestsApi.reject(idRequest, obj)
            .then(successCallback)
            .catch(rejectErrorCallback)
            .finally(() => setOpenBackdrop(false));

    }

    function resetForm() {
        setReason("");
    }

    function successCallback() {
        resetForm();
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
        window.location.href = `/vigilances/${vigilance.id}`;
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    function rejectErrorCallback(e: Response) {
        let msg = getErrorMessage(e);
        console.log(e.status)
        if (e.status === 403) {
            msg = "Limite de rejeições atingido";
        } else if (e.status === 409) {
            msg = "Não há docentes disponíveis";
        }
        snackbar.enqueueSnackbar(msg, {variant: "error"})
    }


    return (
        <div>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.paper}>
                    <Typography className={classes.secondaryTitle}>
                        Formulário de rejeição de vigilância
                    </Typography>
                    <Typography className={classes.text}>{vigilance.subject?.name}</Typography>
                    <Typography className={classes.text}>
                        {DatetimeComponent(new Date(vigilance.evaluationDatetime!!))}
                    </Typography>

                    <form className={classes.form} onSubmit={handleSubmit}>
                        <TextField
                            select
                            onChange={e => {
                                const idT: number = parseInt(e.target.value);
                                setRejectionType(idT);
                            }}
                            SelectProps={{
                                native: true,
                            }}
                            required
                            value={rejectionType}
                            helperText="Selecione o motivo"
                            variant="outlined"
                        >
                            <option value={-1}/>
                            {
                                rejectionTypes.map(t =>
                                    <option key={t.id} value={t.id}>
                                        {t.description}
                                    </option>
                                )
                            }
                        </TextField>
                        {rejectionType != undefined && rejectionType >= 0 && rejectionTypes.filter(r => r.id === rejectionType)[0].needsJustification ?
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                required
                                value={reason}
                                name="reason"
                                autoComplete={"off"}
                                label="Justificação"
                                id="reason"
                                onChange={e => setReason(e.target.value)}
                            /> : null
                        }


                        <BackSubmitButtons/>

                    </form>

                    <Backdrop className={classes.backdrop} open={openBackdrop}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>

                </div>
            )}
        </div>
    );
}