import React from 'react';
import Typography from "@material-ui/core/Typography";
import {DepartmentDTO, DepartmentsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {Button, Grid, TextField} from '@material-ui/core';
import {BackButton, ButtonWithConfirmation} from "../util/Buttons";
import {useHistory} from "react-router-dom";
import {useSnackbar} from 'notistack';
import {getErrorMessage} from "../util/util";
import {PageContext} from "../contexts/PageContext";

const useStyles = defaultStyles();

export default function AddDepartmentPage() {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const history = useHistory();

    const departmentsAPI = new DepartmentsApi(config);

    const [name, setName] = React.useState("");

    const [errorMessages, setErrorMessages] = React.useState({name: ""});
    const pageContext = React.useContext(PageContext) as Array<any>;
    React.useEffect(updateContext, []);

    function updateContext() {
        pageContext[1]("Criar Departamento");
    }
    function validate() {
        let valid = true;
        let nameMessage = "";

        if (!name || name === "") {
            nameMessage = "Por favor insira um nome";
            valid = false;
        }

        setErrorMessages({
            name: nameMessage
        });
        return valid;
    }

    function submit() {
        if (!validate()) return Promise.reject();

        const data: DepartmentDTO = {
            id: 0,
            name: name,
            subjects: [],
            users: []
        }

        return departmentsAPI.addNewDepartment(data)
    }

    function resetForm() {
        setName("");
    }

    function successCallback(departmentDTO: DepartmentDTO) {
        resetForm();
        snackbar.enqueueSnackbar("Departamento criado com sucesso.",
            {
                variant: "success",
                action: _ =>
                    <Button onClick={() => history.push(`/departments/${departmentDTO.id}`)}>
                        Ver
                    </Button>
            })
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    return (
            <div className={classes.centeredWithMaxWidth}>
                <form className={classes.form}>
                    <TextField
                        autoComplete="off"
                        variant="outlined"
                        required
                        fullWidth
                        label="Nome"
                        value={name}
                        autoFocus
                        onChange={e => setName(e.target.value)}
                        className={classes.textFieldSpaced}

                    />
                    {errorMessages["name"] === "" ? null :
                        <Typography className={classes.errorMessage}>{errorMessages["name"]}</Typography>}

                    <Grid container>
                        <Grid item xs={6} style={{paddingRight: "2%"}}>
                            <BackButton/>
                        </Grid>
                        <Grid item xs={6} style={{paddingLeft: "2%"}}>
                            <ButtonWithConfirmation
                                dialogTitle={"Criar departamento?"}
                                dialogDescription={""}
                                buttonText={"Criar"}
                                submit={submit}
                                successCallback={successCallback}
                                errorCallback={errorCallback}
                            />
                        </Grid>
                    </Grid>
                </form>
            </div>
    );
}
