import React from 'react';
import {DepartmentsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {ButtonWithConfirmation} from "../util/Buttons";
import {getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";

const useStyles = defaultStyles();

export default function DeleteDepartmentButton(props: { departmentId: number }) {
    const classes = useStyles();
    const departmentsAPI = new DepartmentsApi(config);
    const snackbar = useSnackbar();

    function deleteDepartment(): Promise<Response> {
        return departmentsAPI.deleteOneDepartment(props.departmentId)
    }

    function successCallback() {
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"});
    }

    return (
        <ButtonWithConfirmation
            buttonText={"Eliminar departamento"}
            dialogDescription={""}
            dialogTitle={"Eliminar este departamento?"}
            successCallback={successCallback}
            errorCallback={errorCallback}
            buttonClassName={classes.deleteButton}
            submit={deleteDepartment}/>
    );

}
