import React, {createContext, useState} from "react";
import {GlobalApi, UserExtendedDTO, YearSemesterDTO} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {DARK_THEME_VAR, genericErrorCallback, YEAR_SEMESTER_VAR} from "../util/util";

// Create Context Object
export const AppContext = createContext<AppContextInterface>({
    yearSemester: {
        value: {},
        set: () => {
        },
        reset: () => {
        }
    },
    me: {
        value: {},
        set: () => {
        }
    },
    clearLocalStorage: () => {
    }
});

interface YearSemesterContextInterface {
    value: YearSemesterDTO,
    set: React.Dispatch<React.SetStateAction<YearSemesterDTO>>,
    reset: () => void
}

interface MeContextInterface {
    value: UserExtendedDTO,
    set: React.Dispatch<React.SetStateAction<UserExtendedDTO>>
}

export interface AppContextInterface {
    yearSemester: YearSemesterContextInterface,
    me: MeContextInterface,
    clearLocalStorage: () => void
}

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = (props: { currentYearSemester: YearSemesterDTO, me: UserExtendedDTO, children: React.ReactNode }) => {

    const [yearSemester, setYearSemester] = useState<YearSemesterDTO>(props.currentYearSemester);
    const [me, setMe] = useState<UserExtendedDTO>(props.me);
    const globalService = new GlobalApi(config);

    // Load Edition
    React.useEffect(() => {
        if (localStorage.getItem('yearSemesterId')) {
            let yearSemesterId = localStorage.getItem(YEAR_SEMESTER_VAR)
            globalService.enumerateSystemEditions()
                .then(editions => {
                    let i = editions.findIndex(x => x.id === yearSemesterId);
                    if (i >= 0) {
                        setYearSemester(editions[i]);
                    }

                }).catch(genericErrorCallback);
        }
    }, []);

    React.useEffect(() => {
        localStorage.setItem(YEAR_SEMESTER_VAR, yearSemester.id!!);
    }, [yearSemester]);

    /**
     * Maintains palette theme
     */
    function clearLocalStorage() {
        const theme = localStorage.getItem(DARK_THEME_VAR);
        localStorage.clear();
        if (theme) {
            localStorage.setItem(DARK_THEME_VAR, theme);
        }
    }

    function resetYearSemester() {
        globalService.getYearAndSemester()
            .then(setYearSemester)
            .catch(genericErrorCallback)
    }

    const context: AppContextInterface = {
        yearSemester: {value: yearSemester, set: setYearSemester, reset: resetYearSemester},
        me: {value: me, set: setMe},
        clearLocalStorage: clearLocalStorage
    }

    return (
        <AppContext.Provider value={context}>
            {props.children}
        </AppContext.Provider>
    );
};