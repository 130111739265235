import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {defaultStyles} from "../../styles";
import {BackSubmitButtons} from "../util/Buttons";
import {UsersApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Backdrop} from "@material-ui/core";

const useStyles = defaultStyles();


export default function ResetPasswordPage() {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const usersAPI = new UsersApi(config);

    const [email, setEmail] = React.useState(String);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);


    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setOpenBackdrop(true)

        usersAPI.resetPassword(email)
            .then(_ => successCallback())
            .catch(e => errorCallback(e))
            .finally(() => setOpenBackdrop(false))
    }

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value)
    }

    function successCallback() {
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    return (
        <div className={classes.paper}>
            <Typography component="h1" variant="h5">
                Reset da password
            </Typography>
            <Typography className={classes.text}>
                Será enviado um email com um link para recuperar a sua password.
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={handleEmailChange}
                />

                <BackSubmitButtons/>

            </form>

            <Backdrop className={classes.backdrop} open={openBackdrop}>
                <CircularProgress color="inherit"/>
            </Backdrop>
        </div>
    );
}
