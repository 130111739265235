import React from 'react';

import {Scopes} from "../util/util";
import LogsTable from "./LogsTable";
import {LinearProgress} from "@material-ui/core";
import {PageContext} from "../contexts/PageContext";
import {AppContext, AppContextInterface} from "../contexts/AppContext";


export default function DepartmentLogsPage() {

    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(fetchData, []);
    const pageContext = React.useContext(PageContext) as Array<any>;
    const appContext = React.useContext(AppContext) as AppContextInterface;

    function fetchData() {
        pageContext[1]("Logs");
        setIsLoading(false);
    }

    return (
        <div>
            {isLoading ? <LinearProgress/> :
                <LogsTable scope={Scopes.DEPARTMENT} me={appContext.me.value}/>
            }
        </div>
    );
}
