import React from "react";
import {UsersApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {genericErrorCallback, Roles, Scopes} from "../util/util";
import VigilanceCardList from "../vigilances/VigilanceCardList";
import {Grid} from "@material-ui/core";
import EvaluationList from "../evaluations/EvaluationCardList";
import RejectionCardList from "../vigilances/RejectionCardList";
import {defaultStyles} from "../../styles";
import {PageContext} from "../contexts/PageContext";
import {AppContext, AppContextInterface} from "../contexts/AppContext";

const useStyles = defaultStyles();

export default function Home() {
    const classes = useStyles();

    const [roles, setRoles] = React.useState(Array<String>());
    const [shouldRenderRejectionCardList, setShouldRenderRejectionCardList] = React.useState(false);
    const [shouldRenderVigilanceCardList, setShouldRenderVigilanceCardList] = React.useState(false);
    React.useEffect(getData, []);
    const appContext = React.useContext(AppContext) as AppContextInterface;
    const pageContext = React.useContext(PageContext) as Array<any>;

    function getData() {
        let usersApi: UsersApi = new UsersApi(config);

        usersApi.getMyRoles().then(r => {
                setRoles(r);
            }
        ).catch(genericErrorCallback);

        usersApi.canManageDepartmentJustifications().then(_ => {
                setShouldRenderRejectionCardList(true);
            }
        ).catch(()=>{
            setShouldRenderRejectionCardList(false);
        });


        setShouldRenderVigilanceCardList(appContext.me.value.roles?.includes(Roles.ROLE_LECTURER) || false);
        pageContext[1]("Início");
    }

    function renderVigilanceCardList() {
        if (roles.includes(Roles.ROLE_LECTURER)) {
            return <VigilanceCardList scope={Scopes.ME} yearSemester={appContext.yearSemester.value}/>;
        }

        // if (roles.includes(Roles.ROLE_SYSTEM_ADMIN)) {
        //     return <VigilanceCardList scope={Scopes.ALL} yearSemester={appContext.yearSemester.value}/>;
        // }
        // if (roles.includes(Roles.ROLE_LECTURER)) {
        //     return <VigilanceCardList scope={Scopes.ME} yearSemester={appContext.yearSemester.value}/>;
        // }
        // if (roles.includes(Roles.ROLE_SECRETARY) || roles.includes(Roles.ROLE_DEPARTMENT_ADMIN)) {
        //     return <VigilanceCardList scope={Scopes.DEPARTMENT} yearSemester={appContext.yearSemester.value}/>;
        // }
        return null;
    }

    function renderEvaluationsCardList() {
        if (roles.includes(Roles.ROLE_SYSTEM_ADMIN)) {
            return <EvaluationList scope={Scopes.ALL} yearSemester={appContext.yearSemester.value}/>;
        }
        if (roles.includes(Roles.ROLE_LECTURER)) {
            return <EvaluationList scope={Scopes.ME} yearSemester={appContext.yearSemester.value}/>;
        }
        if (roles.includes(Roles.ROLE_SECRETARY) || roles.includes(Roles.ROLE_DEPARTMENT_ADMIN)) {
            return <EvaluationList scope={Scopes.DEPARTMENT} yearSemester={appContext.yearSemester.value}/>;
        }
        return null;
    }

    function renderRejectionCardList() {
        if (roles.includes(Roles.ROLE_SYSTEM_ADMIN)) {
            return <RejectionCardList scope={Scopes.ALL} yearSemester={appContext.yearSemester.value}/>;
        }
        return <RejectionCardList scope={Scopes.DEPARTMENT} yearSemester={appContext.yearSemester.value}/>;
    }

    function getLg() {
        if (shouldRenderVigilanceCardList && shouldRenderRejectionCardList)
            return 4;
        return 6;
    }

    return (
        <div className={classes.home}>
            <Grid container>

                {shouldRenderVigilanceCardList ?
                    <Grid item xs={12} sm={12} lg={getLg()}>
                        {renderVigilanceCardList()}
                    </Grid> : null
                }

                {shouldRenderRejectionCardList ?
                    <Grid item xs={12} sm={12} lg={getLg()}>
                        {renderRejectionCardList()}
                    </Grid> : null
                }

                <Grid item xs={12} sm={12} lg={getLg()}>
                    {renderEvaluationsCardList()}
                </Grid>
            </Grid>
        </div>
    );
}