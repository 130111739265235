import React from "react";
import {TextField,} from "@material-ui/core";
import {config} from "../../configuration";
import {RoleDTO, RolesApi, UserDTO, UsersApi} from "../../typescript-fetch-client-generated";
import GenericPrompt from "../util/GenericPrompt";
import {genericErrorCallback} from "../util/util";

const rolesAPI = new RolesApi(config);
const usersAPI = new UsersApi(config);

export default function AddRolePrompt(props: { user: UserDTO, callback: Function }) {
    const [newRole, setNewRole] = React.useState("");
    const [availableRoles, setAvailableRoles] = React.useState<Array<RoleDTO>>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(fetchData, [props.user]);

    function fetchData() {
        rolesAPI.getAllRoles()
            .then(rs => {
                setAvailableRoles(rs.filter(r => !props.user.roles?.includes(r.id!!)));
                setIsLoading(false);
            })
            .catch(genericErrorCallback)
    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault()
        setNewRole(event.target.value);
    }

    function submit(): Promise<UserDTO> {
        return usersAPI.setRoles(props.user.id!!, [...props.user.roles!!, newRole])
    }

    function getChildren() {
        return [
            <TextField
                select
                fullWidth
                onChange={handleChange}
                SelectProps={{
                    native: true,
                }}
                variant="outlined"
                key={1}
            >
                <option key={-1} value={""}>
                </option>
                {
                    availableRoles.map(r =>
                        <option key={r.id} value={r.id}>
                            {r.description}
                        </option>
                    )
                }
            </TextField>
        ]
    }

    function validate(): boolean {
        return newRole !== undefined && newRole !== "";
    }

    function successCallback() {
        setNewRole("");
        props.callback();
    }

    return (
        <div> {isLoading ? null : (
            <GenericPrompt
                buttonText={"Adicionar role"}
                title={"Adicionar role"}
                description={"Escolha o role que pretende dar"}
                submit={submit}
                children={getChildren()}
                validate={validate}
                successCallback={successCallback}/>
        )}
        </div>
    );
}