import {ButtonWithConfirmation} from "../util/Buttons";
import React from "react";
import {TextField} from "@material-ui/core";
import {VigilantRecordDTO, VigilantRecordsApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";
import {getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";


export default function ModifyVigilantRecord(props: { initialVigilantRecord: VigilantRecordDTO }) {
    const vigilantRecordAPI = new VigilantRecordsApi(config);
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);
    const snackbar = useSnackbar();

    const [points, setPoints] = React.useState(props.initialVigilantRecord.points);
    const [penalties, setPenalties] = React.useState(props.initialVigilantRecord.penalties);

    function getForm() {
        return [
            <TextField
                autoFocus
                type={"number"}
                margin="dense"
                value={points}
                label="Pontos"
                onChange={(e) =>
                    setPoints(parseFloat(e.target.value))}
                fullWidth
                key={1}
            />,
            <TextField
                type={"number"}
                margin="dense"
                value={penalties}
                label="Penalizações"
                onChange={(e) =>
                    setPenalties(parseFloat(e.target.value))}
                fullWidth
                key={1}
            />
        ]
    }

    function resetForm() {
        setPenalties(props.initialVigilantRecord.penalties)
        setPoints(props.initialVigilantRecord.points)
    }


    function successCallback() {
        notifyUpdateContext.notify()
        snackbar.enqueueSnackbar("Ficha modificada com sucesso.", {variant: "success"})
    }

    function errorCallBack(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
        resetForm()
    }

    function submit() {
        console.log(points)
        console.log(penalties)
        if (points != undefined && !isNaN(points) && penalties != undefined && !isNaN(penalties)) {
            return vigilantRecordAPI.modifyOneVigilantRecord(
                props.initialVigilantRecord.id!!,
                {...props.initialVigilantRecord, points: points, penalties: penalties}
            )
        }
        return Promise.reject()
    }

    return (
        <ButtonWithConfirmation
            buttonText={"Modificar ficha"}
            dialogTitle={"Modificar ficha do docente"}
            children={getForm()}
            submit={submit}
            successCallback={successCallback}
            errorCallback={errorCallBack}
            onClose={resetForm}
        />
    )
}