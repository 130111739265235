import React from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {defaultStyles} from "../../styles";
import {SubmitButton} from "../util/Buttons";
import {UsersApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";

const useStyles = defaultStyles();


export default function ChangePasswordAfterResetPage(props: any) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const params = new URLSearchParams(props.location.search);
    const token = params.get("token");


    const usersAPI = new UsersApi(config);

    const [newPass1, setNewPass1] = React.useState(String);
    const [newPass2, setNewPass2] = React.useState(String);
    const [passDoesNotMatch, setPassDoesNotMatch] = React.useState(false);

    if (!token)
        return (<p>Invalid token</p>)

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        if (!validate()) return

        usersAPI.changePasswordAfterReset(newPass1, token!!)
            .then(_ => successCallback())
            .catch(e => errorCallback(e));

    }

    function validate() {
        if (newPass1 === newPass2) {
            setPassDoesNotMatch(false);
            return true;
        } else {
            setPassDoesNotMatch(true);
            return false;
        }
    }

    function successCallback() {
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    return (
        <div className={classes.paper}>
            <Typography component="h1" variant="h5">
                Defina a nova password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="New password"
                    type="password"
                    autoComplete="off"
                    onChange={e => setNewPass1(e.target.value)}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    label="Repeat new password"
                    type="password"
                    autoComplete="off"
                    onChange={e => setNewPass2(e.target.value)}
                />
                <Typography
                    className={classes.errorMessage}>{passDoesNotMatch ? "Passwords do not match" : ""}</Typography>


                <SubmitButton/>

            </form>
        </div>
    );
}
