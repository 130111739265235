import React from 'react';
import {EditionExtendedDTO, EditionsApi, LecturersApi, UserMinimalDTO} from "../../typescript-fetch-client-generated";
import {defaultStyles} from "../../styles";
import SmallUserTable from "./SmallUserTable";
import {config} from "../../configuration";
import AddLecturerToEdition from "./AddLecturerToEdition";
import {getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";

const useStyles = defaultStyles();

export default function EditionLecturersTable(props: { edition: EditionExtendedDTO, lecturers: Array<UserMinimalDTO>, headLecturers: Array<UserMinimalDTO> }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const lecturersAPI = new LecturersApi(config);
    const editionsAPI = new EditionsApi(config);

    const [canModifyEdition, setCanModifyEdition] = React.useState(false);
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    React.useEffect(fetchData, [props.edition, props.lecturers, props.headLecturers]);

    function fetchData() {
        editionsAPI.canModifyOneEdition(props.edition.id!!)
            .then(_ => setCanModifyEdition(true))
            .catch(_ => setCanModifyEdition(false));
    }

    function successCallback() {
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    function handleRemove(lecturerId: number) {
        if (props.headLecturers.findIndex(x => x.id === lecturerId) >= 0) {
            lecturersAPI.deleteHeadEditionFromLecturer(lecturerId, props.edition.id!!)
                .then(_ => {
                    successCallback()
                    notifyUpdateContext.notify();
                })
                .catch(errorCallback);
        }

        lecturersAPI.deleteEditionFromLecturer(lecturerId, props.edition.id!!)
            .then(_ => {
                successCallback()
                notifyUpdateContext.notify();
            })
            .catch(errorCallback);
    }

    return (
        <div className={classes.centered}>
            {canModifyEdition ?
                <div>
                    <SmallUserTable users={props.lecturers} deleteOne={handleRemove} editionId={props.edition.id}
                                    headLecturers={props.headLecturers} canModifyEdition={canModifyEdition}/>
                    <AddLecturerToEdition edition={props.edition}/>
                </div>
                :
                <SmallUserTable users={props.lecturers} headLecturers={props.headLecturers} editionId={props.edition.id}
                                canModifyEdition={canModifyEdition}/>
            }
        </div>
    );
}
