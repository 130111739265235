import React from "react";
import {TextField,} from "@material-ui/core";
import {config} from "../../configuration";
import {DepartmentsApi, EditionExtendedDTO, LecturerDTO, LecturersApi} from "../../typescript-fetch-client-generated"
import {genericErrorCallback} from "../util/util";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";
import GenericPrompt from "../util/GenericPrompt";

export default function AddLecturerToEdition(props: { edition: EditionExtendedDTO }) {
    const departmentAPI = new DepartmentsApi(config);
    const lecturersAPI = new LecturersApi(config);

    const [lecturerId, setLecturerId] = React.useState(-1);
    const [availableLecturers, setAvailableLecturers] = React.useState<Array<LecturerDTO>>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    React.useEffect(fetchData, [props.edition])

    function fetchData() {

        departmentAPI.getDepartmentLecturers(props.edition.subject?.department!!)
            .then(ls => {
                setAvailableLecturers(ls
                    .filter(available => !props.edition.lecturers
                        ?.map(l => l.id)
                        ?.includes(available.id)));
                setIsLoading(false);
            })
            .catch(genericErrorCallback)

    }

    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.preventDefault();
        setLecturerId(parseInt(event.target.value, 10));
    }

    function submit(): Promise<LecturerDTO> {
        return lecturersAPI.addEditionToLecturer(lecturerId, props.edition.id!!);
    }

    function getChildren() {
        return [
            <TextField
                select
                onChange={handleChange}
                SelectProps={{
                    native: true,
                }}
                variant="outlined"
                key={1}
            >
                <option/>
                {
                    availableLecturers.map(l =>
                        <option key={l.id} value={l.id}>
                            {l.name}
                        </option>
                    )
                }
            </TextField>
        ]
    }

    function validate(): boolean {
        return lecturerId !== undefined && lecturerId > 0;
    }

    function successCallback() {
        setLecturerId(-1);
        notifyUpdateContext.notify();
    }

    return (
        <div> {isLoading ? null : (
            <GenericPrompt
                buttonText={"Adicionar docente"}
                title={"Adicionar docente"}
                description={"Selecione o docente que quer adicionar"}
                submit={submit}
                children={getChildren()}
                validate={validate}
                successCallback={successCallback}/>

        )}
        </div>
    );
}