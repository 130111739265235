import React, {createContext} from "react";
import {DARK_THEME_VAR} from "./util";
import {ThemeOptions} from "@material-ui/core";
import {darkTheme, lightTheme} from "../../styles";
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme} from "@material-ui/core/styles";


interface CustomThemeInterface {
    darkMode: boolean,
    setDarkMode: (b: boolean) => void
}

export const CustomThemeContext = createContext<CustomThemeInterface>(
    {
        darkMode: false,
        setDarkMode: () => {
        }
    });

function CustomThemeProvider(props: { children: React.ReactNode }) {
    const savedDarkMode = localStorage.getItem(DARK_THEME_VAR) === "1";

    const [darkMode, setLocalDarkMode] = React.useState(savedDarkMode);
    const [theme, setTheme] = React.useState<ThemeOptions | undefined>({});

    React.useEffect(initialSetup, [savedDarkMode]);

    function initialSetup() {
        setDarkMode(savedDarkMode);
    }

    function setDarkMode(bool: boolean) {
        localStorage.setItem(DARK_THEME_VAR, bool ? "1" : "0");
        setLocalDarkMode(bool);
        setTheme(bool ? darkTheme : lightTheme);
    }

    const contextValue: CustomThemeInterface = {
        darkMode: darkMode,
        setDarkMode: setDarkMode
    };

    const muiTheme = createMuiTheme(theme);

    return (
        <CustomThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={muiTheme}>{props.children}</ThemeProvider>
        </CustomThemeContext.Provider>
    );
}

export default CustomThemeProvider;