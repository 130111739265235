import React from 'react';

import {defaultStyles} from '../../styles';
import {UserDTO, UsersApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {getErrorMessage} from "../util/util";
import {ButtonWithConfirmation} from "../util/Buttons";
import {useSnackbar} from "notistack";

const useStyles = defaultStyles();

export default function RemoveUserComponent(props: { user: UserDTO, callback: Function }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const usersAPI = new UsersApi(config);

    function removeUser() {
        return usersAPI.deleteOneUser(props.user.id!!);
    }


    function successCallback() {
        props.callback();
        snackbar.enqueueSnackbar("Utilizador removido.");
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"});
    }

    return (
        <ButtonWithConfirmation dialogTitle={"Remover utilizador"}
                                dialogDescription={"De certeza que pretende remover este utilizador?"}
                                buttonText={"Remover"}
                                buttonClassName={classes.deleteButton}
                                submit={removeUser} successCallback={successCallback}
                                errorCallback={errorCallback}/>
    );

}
