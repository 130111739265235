import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import DatetimeComponent from "../util/DatetimeComponents";
import React from "react";
import {EvaluationExtendedDTO} from "../../typescript-fetch-client-generated";
import {defaultStyles} from "../../styles";
import {useHistory} from "react-router-dom";

const useStyles = defaultStyles();

export default function EvaluationsTableView(props: { rows: Array<EvaluationExtendedDTO> }) {
    const classes = useStyles();
    const history = useHistory();

    return (
        props.rows.length === 0 ?
            <Typography className={classes.text}>Não existem avaliações</Typography> : (
                <TableContainer
                    component={Paper}
                >
                    <Table aria-label="simple table" size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead}>Unidade Curricular</TableCell>
                                <TableCell className={classes.tableHead}>Data</TableCell>
                                <TableCell className={classes.tableHead}>Duração (min)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.rows?.map(row => (
                                <TableRow onClick={() => history.push(`/evaluations/${row.id}`)} key={row.id}
                                          className={classes.clickable}>
                                    <TableCell>{row.subject?.name}</TableCell>
                                    <TableCell>{DatetimeComponent(new Date(row.datetime!!))}</TableCell>
                                    <TableCell>{row.duration}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )
    )
}