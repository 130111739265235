import React from 'react';

import {defaultStyles} from '../../styles';
import DepartmentsTable from "./DepartmentsTable";
import {useHistory} from "react-router";
import {DepartmentsApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import {PageContext} from "../contexts/PageContext";
import {BasicButton} from "../util/Buttons";

const useStyles = defaultStyles();

export default function DepartmentsPage() {
    const classes = useStyles();
    const history = useHistory();

    const departmentsAPI = new DepartmentsApi(config);

    const [canCreate, setCanCreate] = React.useState(false);
    React.useEffect(fetchData, []);
    const pageContext = React.useContext(PageContext) as Array<any>;


    function fetchData() {
        departmentsAPI.canAddNewDepartment()
            .then(() => setCanCreate(true))
            .catch(() => setCanCreate(false));
        pageContext[1]("Departamentos");
    }

    return (
        <div className={classes.paperNoMargin}>
            <DepartmentsTable/>

            {canCreate ?
                <BasicButton
                    onClick={_ => history.push(window.location.pathname + "/addDepartment")}>
                    Criar Departamento
                </BasicButton> : null
            }


        </div>
    );
}
