import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {getErrorMessage} from "./util";
import {useSnackbar} from "notistack";

export default function ConfirmDialog(props: { title: string, open: boolean, setOpen: Function, onConfirm: (() => Promise<Response>) }) {
    return CustomConfirmDialog({
        title: props.title,
        open: props.open,
        setOpen: props.setOpen,
        onConfirm: props.onConfirm,
        noText: "Não",
        yesText: "Sim",
        successCallback: () => {
        }
    });
}


export function CustomConfirmDialog(props: { title: string, open: boolean, setOpen: Function, onConfirm: (() => Promise<Response>), noText: string, yesText: string, successCallback: () => void }) {
    const snackbar = useSnackbar();

    const [buttonsDisabled, disableButtons] = React.useState(false);

    function reset() {
        disableButtons(false);
    }

    function handleClose() {
        props.setOpen(false);
        reset();
    }

    function handleConfirmAndClose() {
        disableButtons(true);
        props.onConfirm()
            .then(() => {
                successCallback();
                props.successCallback();
            })
            .catch(e => {
                disableButtons(false);
                errorCallback(e);
            })
        props.setOpen(false);
    }

    function successCallback() {
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle id="confirm-dialog">{props.title}</DialogTitle>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleClose}
                    color="default"
                    disabled={buttonsDisabled}
                >
                    {props.noText}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleConfirmAndClose}
                    color="secondary"
                    disabled={buttonsDisabled}
                >
                    {props.yesText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}