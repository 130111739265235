import {
    DepartmentDTO,
    EvaluationExtendedDTO,
    RoleDTO,
    SubjectExtendedDTO,
    UserMinimalDTO,
    VigilanceRequestExtendedDTO,
    YearSemesterDTO
} from "../../typescript-fetch-client-generated";
import {ProviderContext} from "notistack";
import {RadioProps} from "@material-ui/core/Radio/Radio";
import {Link, LinkProps, Radio, Typography} from "@material-ui/core";
import React from "react";
import {defaultStyles} from "../../styles";
import {AppContext} from "../contexts/AppContext";

export const QUERY_PARAM_YEAR_SEMESTER = "ys";
export const QUERY_PARAM_FROM = "from";
export const GOOGLE_CLIENT_ID = "986332080178-jirq51icruc5qni47nhg3o7oltd10238.apps.googleusercontent.com"
export const GOOGLE_COOKIE_POLICY = "single_host_origin";
export const DARK_THEME_VAR = "darkTheme";
export const YEAR_SEMESTER_VAR = "yearSemesterId";

export const Scopes = {
    ME: 1,
    DEPARTMENT: 2,
    ALL: 3
}

export const DTYPE = {
    ACCEPTED: "accepted",
    PENDING: "pending",
    REJECTED: "rejected",
    SWAPPED: "swapped",
    EXEMPT: "exempt",
    CANCELLED: "cancelled",
    DONE: "done"
}

export const Roles = {
    ROLE_LECTURER: "ROLE_LECTURER",
    ROLE_SECRETARY: "ROLE_SECRETARY",
    ROLE_DEPARTMENT_ADMIN: "ROLE_DEPARTMENT_ADMIN",
    ROLE_SYSTEM_ADMIN: "ROLE_SYSTEM_ADMIN",
    ROLE_JUSTIFICATION_MANAGER: "ROLE_JUSTIFICATION_MANAGER",
    ROLE_DEPARTMENT_PRESIDENT: "ROLE_DEPARTMENT_PRESIDENT"
}

const useStyles = defaultStyles();

export function renderYearSemester(yearSemester: YearSemesterDTO) {
    // 2019/20 - 1º Semestre
    if (!yearSemester) return ""

    const year = yearSemester.year + "/" + ((yearSemester.year!! + 1) % 100);
    const semester = yearSemester.semester + "º Semestre";
    return `${year} - ${semester}`
}

/**
 *
 * @param semester 1 or 2
 */
export function renderSemester(semester: number) {
    return semester === 1 ? "1º" : "2ª"
}

export function getErrorMessage(e?: Response): string {
    let message = "Ocorreu um erro"
    if (e?.status)
        message += "(" + e?.status + ").";
    else
        message += ".";

    if (e) {
        if (e.status === 400)
            message = "Houve um problema a fazer o seu pedido.";
        else if (e.status === 403)
            message = "Não tem permissões para isso.";
        else if (e.status === 404)
            message = "O recurso que pediu não existe.";
        else if (e.status === 409)
            message = "Ocorreu um conflito ao executar o seu pedido.";
        else if (e.status === 422)
            message = "Não foi possível processar o seu pedido.";
        else if (e.status === 500)
            message = "O servidor teve um problema.";
    }

    return message;
}

export function genericErrorCallback(e: Error) {
    // TODO remove log in production
    console.log(e);
}

export function isPrimaryRole(role: RoleDTO): boolean {
    return [Roles.ROLE_DEPARTMENT_ADMIN, Roles.ROLE_SYSTEM_ADMIN, Roles.ROLE_SECRETARY, Roles.ROLE_LECTURER]
        .includes(role.id!!);
}

export function isSecondaryRole(role: RoleDTO): boolean {
    return [Roles.ROLE_JUSTIFICATION_MANAGER]
        .includes(role.id!!);
}


export function renderDtype(dtype: string) {
    switch (dtype) {
        case DTYPE.PENDING:
            return "Pendente";
        case DTYPE.DONE:
            return "Confirmado";
        case DTYPE.ACCEPTED:
            return "Aceite";
        case DTYPE.REJECTED:
            return "Rejeitado";
        case DTYPE.SWAPPED:
            return "Transferido";
        case DTYPE.EXEMPT:
            return "Dispensado";
        case DTYPE.CANCELLED:
            return "Cancelado";
        default:
            return dtype;
    }
}

export function canSwap(vigilance: VigilanceRequestExtendedDTO) {
    return (vigilance.dtype === DTYPE.PENDING && vigilance.replacementFor == null) || vigilance.dtype === DTYPE.ACCEPTED;
}

export function canReject(vigilance: VigilanceRequestExtendedDTO) {
    return vigilance.dtype === DTYPE.PENDING || vigilance.dtype === DTYPE.ACCEPTED;
}

export function canAccept(vigilance: VigilanceRequestExtendedDTO) {
    return vigilance.dtype === DTYPE.PENDING;
}

export function successCallback(snackbar: ProviderContext) {
    snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
}

export function errorCallback(snackbar: ProviderContext, e: Response) {
    snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
}

export function addToQueryParams(key: string, value: string) {
    if ('URLSearchParams' in window) {
        let searchParams = new URLSearchParams(window.location.search)
        searchParams.set(key, value);
        let newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
        window.history.replaceState(null, '', newRelativePathQuery);
    }
}

export function getFromQueryParams(key: string): string | undefined {
    let searchParams, value;

    if ('URLSearchParams' in window) {
        searchParams = new URLSearchParams(window.location.search)
    }

    if (searchParams !== undefined && searchParams.has(key)) {
        value = searchParams.get(key)!!
    }

    return value;
}

export function vigilanceRequestSorter(a: VigilanceRequestExtendedDTO, b: VigilanceRequestExtendedDTO) {
    if (!a.evaluationDatetime)
        return -1;
    if (!b.evaluationDatetime)
        return 1;

    return new Date(a.evaluationDatetime).getTime() - new Date(b.evaluationDatetime).getTime();
}


export function evaluationSorter(a: EvaluationExtendedDTO, b: EvaluationExtendedDTO) {
    if (!a.datetime)
        return -1;
    if (!b.datetime)
        return 1;

    return new Date(a.datetime).getTime() - new Date(b.datetime).getTime();
}

export function userSorter(a: UserMinimalDTO, b: UserMinimalDTO) {
    if (!a.name)
        return -1;
    if (!b.name)
        return 1;

    return a.name.localeCompare(b.name);
}

export function subjectSorter(a: SubjectExtendedDTO, b: SubjectExtendedDTO) {
    if (!a.name)
        return -1;
    if (!b.name)
        return 1;

    return a.name.localeCompare(b.name);
}

export function CustomRadio(props: RadioProps) {

    return (
        <Radio
            style={{
                height: 30,
                width: 30,
            }}
            {...props}
        />
    );
}

export function CustomLink(props: LinkProps) {
    const classes = useStyles();


    return (
        <Link
            {...props}
            onClick={(e: any) => {
                e.stopPropagation()
            }}
            className={classes.link}
        >
            {props.children}
        </Link>
    )
}


export function ConditionalDepartmentText(props: { department: DepartmentDTO }) {
    const classes = useStyles();
    const appContext = React.useContext(AppContext);

    return appContext.me.value.department?.id !== props.department?.id ?
        <Typography
            className={classes.tertiaryTitleMarginBot}>{`Departamento: ${props.department?.name}`}
        </Typography> : null

}

export function customRound(n: number, decimals: number) {
    if (decimals > 0 && Number.isInteger(decimals)) {
        let x = Math.pow(10, decimals);
        return Math.round((n + Number.EPSILON) * x) / x
    } else {
        return n;
    }
}