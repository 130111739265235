import React from "react";
import TextField from "@material-ui/core/TextField";
import {defaultStyles} from "../../styles";
import {LecturerDTO, LecturersApi, VigilanceRequestsApi} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration"
import {BackSubmitButtons} from "../util/Buttons";
import {genericErrorCallback, getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";
import {Backdrop} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import {PageContext} from "../contexts/PageContext";
import {useHistory} from "react-router-dom";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";

const useStyles = defaultStyles();

export default function SwapVigilanceForm(props: any) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const vigilanceAPI = new VigilanceRequestsApi(config);
    const lecturersAPI = new LecturersApi(config);
    const idRequest: number = parseInt(props.match.params.vigilance_id);
    const history = useHistory();
    const [lecturers, setLecturers] = React.useState<Array<LecturerDTO>>([]);
    const [lecturerId, setLecturerId] = React.useState(-1);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const pageContext = React.useContext(PageContext) as Array<any>;
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    React.useEffect(getData, []);

    function getData() {
        vigilanceAPI.getOneExtended(idRequest)
            .then(v => {
                lecturersAPI.getAvailableForEvaluation(v.evaluation!!).then(
                    ls => setLecturers(ls)
                ).catch(genericErrorCallback);
            }).catch(genericErrorCallback);
        pageContext[1]("Transferir vigilância");
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        vigilanceAPI.swap(idRequest, lecturerId)
            .then(successCallback)
            .catch(errorCallback)
            .finally(() => setOpenBackdrop(false));
    }

    function successCallback() {
        window.location.href = `vigilances/${idRequest}`
        snackbar.enqueueSnackbar("Pedido efetuado. Aguarda confirmação pelo destinatário.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    return (
        <div className={classes.paper}>
            <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                    select
                    onChange={e => {
                        const selectedId: number = parseInt(e.target.value);
                        setLecturerId(selectedId);
                    }}
                    SelectProps={{
                        native: true,
                    }}
                    required
                    helperText="Selecione o docente"
                    variant="outlined"
                >
                    <option/>
                    {
                        lecturers.map(l =>
                            <option key={l.id} value={l.id}>
                                {l.name + " (" + l.email + ")"}
                            </option>
                        )
                    }
                </TextField>

                <BackSubmitButtons/>

                <Backdrop className={classes.backdrop} open={openBackdrop}>
                    <CircularProgress color="inherit"/>
                </Backdrop>
            </form>
        </div>
    )
}