import React from 'react';
import {Link} from 'react-router-dom';
import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import EventIcon from '@material-ui/icons/Event';
import WorkIcon from '@material-ui/icons/Work';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PeopleIcon from '@material-ui/icons/People';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import BusinessIcon from "@material-ui/icons/Business";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

/* Home */
export function HomeItem() {
    return <ListItem button key={0} component={Link} to={'/'}>
        <ListItemIcon><HomeIcon/></ListItemIcon>
        <ListItemText primary={"Início"}/>
    </ListItem>;
}


/* Subjects */
export function SubjectsItem(props: { departmentId: number }) {
    return <ListItem button key={1} component={Link} to={`/departments/${props.departmentId}/subjects`}>
        <ListItemIcon><MenuBookIcon/></ListItemIcon>
        <ListItemText primary={"Unidades Curriculares"}/>
    </ListItem>
}

export function MySubjectsItem() {
    return <ListItem button key={1} component={Link} to={`/my_subjects`}>
        <ListItemIcon><MenuBookIcon/></ListItemIcon>
        <ListItemText primary={"Minhas Unidades Curriculares"}/>
    </ListItem>
}


/* Vigilance requests */
export function MyVigilanceRequestsItem() {
    return <ListItem button key={2} component={Link} to={'/my_vigilances'}>
        <ListItemIcon><WorkIcon/></ListItemIcon>
        <ListItemText primary={"Minhas Vigilâncias"}/>
    </ListItem>

}

export function VigilanceRequestsItem(props: { departmentId: number }) {
    return <ListItem button key={2} component={Link} to={`/departments/${props.departmentId}/vigilances`}>
        <ListItemIcon><WorkIcon/></ListItemIcon>
        <ListItemText primary={"Vigilâncias"}/>
    </ListItem>

}

/* Evaluations */
export function EvaluationsItem(props: { departmentId: number }) {
    return <ListItem button key={3} component={Link} to={`/departments/${props.departmentId}/evaluations`}>
        <ListItemIcon><EventIcon/></ListItemIcon>
        <ListItemText primary={"Avaliações"}/>
    </ListItem>
}

export function MyEvaluationsItem() {
    return <ListItem button key={3} component={Link} to={`/my_evaluations`}>
        <ListItemIcon><EventIcon/></ListItemIcon>
        <ListItemText primary={"Minhas Avaliações"}/>
    </ListItem>
}


/* Score */
export function DepartmentsScoreItem(props: { departmentId: number }) {

    return <ListItem button key={4} component={Link} to={`/departments/${props.departmentId}/scores`}>
        <ListItemIcon><TrendingUpIcon/></ListItemIcon>
        <ListItemText primary={"Pontuações"}/>
    </ListItem>
}


/* Users */
export function DepartmentUsersItem(props: { departmentId: number }) {

    return <ListItem button key={1} component={Link} to={`/departments/${props.departmentId}/users`}>
        <ListItemIcon><PeopleIcon/></ListItemIcon>
        <ListItemText primary={"Utilizadores"}/>
    </ListItem>
}

/* Departments */
export function DepartmentsItem() {

    return <ListItem button key={1} component={Link} to={'/departments'}>
        <ListItemIcon><BusinessIcon/></ListItemIcon>
        <ListItemText primary={"Departamentos"}/>
    </ListItem>
}

export function MyDepartmentItem(props: { departmentId: number }) {

    return <ListItem button key={1} component={Link} to={`/departments/${props.departmentId}`}>
        <ListItemIcon><BusinessIcon/></ListItemIcon>
        <ListItemText primary={"Meu Departamento"}/>
    </ListItem>
}


/* Roles and Capabilities */
export function RolesItem() {

    return <ListItem button key={4} component={Link} to={'/roles'}>
        <ListItemIcon><PersonIcon/></ListItemIcon>
        <ListItemText primary={"Roles"}/>
    </ListItem>
}

export function CapabilitiesItem() {

    return <ListItem button key={5} component={Link} to={'/capabilities'}>
        <ListItemIcon><PersonOutlineIcon/></ListItemIcon>
        <ListItemText primary={"Competências"}/>
    </ListItem>
}

/* Logs */
export function LogsItem() {

    return <ListItem button key={7} component={Link} to={'/logs'}>
        <ListItemIcon><LibraryBooksIcon/></ListItemIcon>
        <ListItemText primary={"Logs"}/>
    </ListItem>
}

export function DepartmentLogsItem() {

    return <ListItem button key={8} component={Link} to={'/dep_logs'}>
        <ListItemIcon><LibraryBooksIcon/></ListItemIcon>
        <ListItemText primary={"Logs"}/>
    </ListItem>
}

/* Admin */
export function AdminItem() {

    return <ListItem button key={6} component={Link} to={'/admin'}>
        <ListItemIcon><SettingsIcon/></ListItemIcon>
        <ListItemText primary={"Administração"}/>
    </ListItem>
}









