import React from 'react';

import {defaultStyles} from '../../styles';
import {Scopes} from "../util/util";
import LogsTable from "./LogsTable";
import {LinearProgress} from "@material-ui/core";
import {PageContext} from "../contexts/PageContext";
import {AppContext} from "../contexts/AppContext";


const useStyles = defaultStyles();

export default function AllLogsPage() {

    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(fetchData, []);
    const pageContext = React.useContext(PageContext) as Array<any>;
    const appContext = React.useContext(AppContext);


    function fetchData() {
        setIsLoading(false);
        pageContext[1]("Logs");
    }

    return (
        <div>
            {isLoading ? <LinearProgress/> :
                <div className={classes.centeredWithMaxWidth}>
                    <LogsTable scope={Scopes.ALL} me={appContext.me.value}/>
                </div>
            }
        </div>
    );
}
