import React from 'react';

import {defaultStyles} from '../../styles';
import SubjectsTable from "./SubjectsTable";
import {CircularProgress} from "@material-ui/core";
import {Scopes} from "../util/util";
import {PageContext} from "../contexts/PageContext";
import {AppContext} from "../contexts/AppContext";

const useStyles = defaultStyles();


export default function MySubjectsPage() {

    const classes = useStyles();

    const [isLoading, setIsLoading] = React.useState(true);
    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(fetchData, []);

    const appContext = React.useContext(AppContext);


    function fetchData() {
        pageContext[1]("Unidades Curriculares");
        setIsLoading(false);
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? <CircularProgress/> : (
                <div className={classes.centeredWithMaxWidth}>

                    <SubjectsTable
                        scope={Scopes.ME}
                        yearSemester={appContext.yearSemester.value}
                    />

                </div>
            )}
        </div>
    );
}
