import React from 'react';
import {useHistory} from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from "@material-ui/core/CircularProgress";
import {SubjectExtendedDTO, SubjectsApi, YearSemesterDTO} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import Paper from "@material-ui/core/Paper";
import {genericErrorCallback, Scopes, subjectSorter} from "../util/util";
import {AppContext} from "../contexts/AppContext";


const useStyles = defaultStyles();


export default function SubjectsTable(props: { scope: number, yearSemester?: YearSemesterDTO, departmentId?: number }) {
    const classes = useStyles();
    const history = useHistory();

    let subjectsAPI = new SubjectsApi(config);

    const [rows, setRows] = React.useState<Array<SubjectExtendedDTO>>([]);
    const [sortedRows, setSortedRows] = React.useState<Array<SubjectExtendedDTO>>([]);
    const [isLoading, setIsLoading] = React.useState(true);
    React.useEffect(fetchTableRows, [props.scope, props.yearSemester]);
    React.useEffect(sortRows, [rows]);
    const appContext = React.useContext(AppContext);

    function fetchTableRows() {
        setRows([]);

        switch (props.scope) {
            case Scopes.ME:
                if (props.yearSemester === undefined) {
                    subjectsAPI.getMySubjectsForCurrentEdition().then(s => {
                        setRows(s);
                        setIsLoading(false);
                    }).catch(e => {
                        setIsLoading(false);
                        genericErrorCallback(e);
                    });
                } else {
                    subjectsAPI.getMySubjectsForSpecificEdition(props.yearSemester.year!!, props.yearSemester.semester!!).then(s => {
                        setRows(s);
                        setIsLoading(false);
                    }).catch(e => {
                        setIsLoading(false);
                        genericErrorCallback(e);
                    });
                }
                break;
            case Scopes.ALL:
                if (props.yearSemester === undefined) {
                    subjectsAPI.getAllSubjectsExtended().then(
                        r => {
                            setRows(r);
                            setIsLoading(false);
                        }
                    ).catch(genericErrorCallback)
                } else {
                    subjectsAPI.getAllSubjectsExtendedWithEditionsOn(props.yearSemester.year!!, props.yearSemester.semester!!).then(r => {
                        setRows(r);
                        setIsLoading(false);
                    }).catch(genericErrorCallback);
                }
                break;
            case Scopes.DEPARTMENT:
                if (props.yearSemester === undefined) {
                    subjectsAPI.getAllSubjectsExtended().then(s => {
                        setRows(props.departmentId ?
                            s.filter(s => s.department?.id === props.departmentId) :
                            s.filter(s => s.department?.id === appContext.me.value.department?.id)
                        );
                        setIsLoading(false);
                    }).catch(genericErrorCallback);
                } else {
                    subjectsAPI.getAllSubjectsExtendedWithEditionsOn(props.yearSemester.year!!, props.yearSemester.semester!!).then(s => {
                        setRows(props.departmentId ?
                            s.filter(s => s.department?.id === props.departmentId) :
                            s.filter(s => s.department?.id === appContext.me.value.department?.id)
                        );
                        setIsLoading(false);
                    }).catch(genericErrorCallback);
                }

                break;
            default:
                break;
        }
    }

    function sortRows() {
        setSortedRows(rows.sort(subjectSorter));
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHead}>Nome</TableCell>
                                {props.scope === Scopes.ALL ?
                                    <TableCell className={classes.tableHead}>Departamento</TableCell>
                                    : null
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedRows!!.map(row => (
                                <TableRow key={row.id} onClick={() => history.push(`/subjects/${row.id}`)}
                                          className={classes.clickable}>
                                    <TableCell>{row.name}</TableCell>
                                    {props.scope === Scopes.ALL ?
                                        <TableCell>{row.department!!.name}</TableCell>
                                        : null
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
}
