import React, {createContext, useState} from "react";

// Create Context Object
export const PageContext = createContext({});

// Create a provider for components to consume and subscribe to changes
export const PageContextProvider = (props: { initialState: String, children: React.ReactNode; }) => {

    const [page, setPage] = useState<String>(props.initialState);

    return (
        <PageContext.Provider value={[page, setPage]}>
            {props.children}
        </PageContext.Provider>
    );
};