import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {DepartmentDTO, DepartmentExtendedDTO, DepartmentsApi, UsersApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import SmallUserTable from "../users/SmallUserTable";
import {useHistory} from "react-router-dom";
import {ConditionalDepartmentText, genericErrorCallback} from "../util/util";
import {PageContext} from "../contexts/PageContext";
import {BasicButton} from "../util/Buttons";


const useStyles = defaultStyles();

export default function UsersPage(props: any) {
    const classes = useStyles();
    const history = useHistory();

    const departmentAPI: DepartmentsApi = new DepartmentsApi(config);
    const usersAPI = new UsersApi(config);

    const departmentId = parseInt(props.match.params.department_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [department, setDepartment] = React.useState<DepartmentExtendedDTO>({});
    const [canCreate, setCanCreate] = React.useState(false);
    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(fetchData, [])

    function fetchData() {
        departmentAPI.getOneExtendedDepartment(departmentId).then(
            e => {
                setDepartment(e);
                setIsLoading(false);
            }
        ).catch(genericErrorCallback)

        usersAPI.canAddNewUser(departmentId)
            .then(_ => setCanCreate(true))
            .catch(_ => setCanCreate(false));
        pageContext[1]("Utilizadores");
    }

    return (
        <div className={classes.paperNoMargin}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>

                    <ConditionalDepartmentText department={department as DepartmentDTO}/>

                    <SmallUserTable users={department!!.users!!}/>

                    {
                        canCreate ?
                            <BasicButton
                                onClick={_ => history.push(`/departments/${departmentId}/addUser`)}>
                                Criar Utilizador
                            </BasicButton> : null
                    }

                </div>
            )}
        </div>
    );
}
