import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from "@material-ui/core/CircularProgress";
import {RoleDTO, RolesApi, UserDTO} from "../../typescript-fetch-client-generated";
import {defaultStyles} from "../../styles";
import {Table, TableBody} from "@material-ui/core";
import {config} from "../../configuration";
import {genericErrorCallback} from "../util/util";

const useStyles = defaultStyles();

export default function UserRolesTable(props: { user: UserDTO }) {
    // const classes = useStyles();
    // const history = useHistory();

    const rolesAPI = new RolesApi(config);

    const [isLoading, setIsLoading] = React.useState(true);
    const [roles, setRoles] = React.useState<Array<RoleDTO>>([]);
    React.useEffect(fetchData, [props.user])

    function fetchData() {
        rolesAPI.getAllRoles()
            .then(rs => {
                setRoles(rs.filter(r => props.user.roles!!.includes(r.id!!)));
                setIsLoading(false);
            })
            .catch(genericErrorCallback);
    }

    return (
        <div>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div>
                    <Table size={"small"}>
                        <TableBody>
                            {roles.map(role => (
                                <TableRow key={role.id}>
                                    <TableCell>
                                        {role.description}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            )}
        </div>
    );
}
