import React from 'react';
import {EvaluationsApi} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {ButtonWithConfirmation} from "../util/Buttons";
import {useHistory} from "react-router";

const useStyles = defaultStyles();

export default function DeleteEvaluationButton(props: { evaluationId: number }) {
    const classes = useStyles();
    const history = useHistory();
    const evaluationAPI = new EvaluationsApi(config);

    function deleteEvaluation(): Promise<Response> {
        return evaluationAPI.deleteOneEvaluation(props.evaluationId)
    }


    return (
        <ButtonWithConfirmation
            buttonText={"Eliminar avaliação"}
            dialogDescription={""}
            dialogTitle={"Eliminar esta avaliação?"}
            buttonClassName={classes.deleteButton}
            submit={deleteEvaluation}
            successCallback={history.goBack}
        />
    );

}
