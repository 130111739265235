import React from 'react';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {DepartmentDTO, DepartmentsApi, SubjectDTO, SubjectsApi,} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import {Backdrop, Button, Grid, TextField} from '@material-ui/core';
import {BackButton, ButtonWithConfirmation} from "../util/Buttons";
import {useHistory} from "react-router-dom";
import {useSnackbar} from 'notistack';
import {genericErrorCallback, getErrorMessage, Roles} from "../util/util";
import {PageContext} from "../contexts/PageContext";
import {AppContext} from "../contexts/AppContext";

const useStyles = defaultStyles();

export default function AddSubjectPage(props: any) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const history = useHistory();

    const departmentsAPI = new DepartmentsApi(config);
    const subjectsAPI = new SubjectsApi(config);

    const loggedUserDepartmentId = parseInt(props.match.params.department_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [availableDepartments, setAvailableDepartments] = React.useState<Array<DepartmentDTO>>([]);
    const [name, setName] = React.useState("");
    const [departmentId, setDepartmentId] = React.useState(-1);
    const [similarNames, setSimilarNames] = React.useState<Array<SubjectDTO>>([]);
    const [errorMessages, setErrorMessages] = React.useState({name: ""});
    const [timeoutId, setTimeoutId] = React.useState<NodeJS.Timeout>();
    const [openBackdrop, setOpenBackdrop] = React.useState(false);

    const appContext = React.useContext(AppContext);
    const pageContext = React.useContext(PageContext) as Array<any>;

    React.useEffect(fetchData, []);

    function fetchData() {
        Promise.all([departmentsAPI.getAllDepartments()])
            .then(
                values => {
                    const [ds] = values;
                    setAvailableDepartments(ds);
                    setDepartmentId(loggedUserDepartmentId);

                    setIsLoading(false);
                }).catch(genericErrorCallback)
        pageContext[1]("Criar Unidade Curricular");
    }

    function checkSimilarNames() {
        setSimilarNames([])
        let trimmedName = name.trim()
        if (trimmedName.length > 3) {
            console.log("QUERY")
            return subjectsAPI.getAllSimilarSubjects(departmentId, trimmedName)
                .then(subjectList => setSimilarNames(subjectList))
                .catch(genericErrorCallback);
        } else {
            return Promise.resolve()
        }
    }
    function handleNameChange(event: React.ChangeEvent<HTMLInputElement>) {
        setName(event.target.value)
    }


    function validate() {
        let valid = true;
        let nameMessage = "";

        if (!name || name.trim() === "") {
            nameMessage = "Por favor escreva um nome";
            valid = false;
        }

        setErrorMessages({
            name: nameMessage
        });
        return valid;
    }

    function submit(): Promise<any> {
        if (!validate()) return Promise.reject();
        setOpenBackdrop(true);
        const data: SubjectDTO = {
            id: 0,
            name: name.trim(),
            department: departmentId,
            editions: []
        }

        return subjectsAPI.addNewSubject(data)
    }

    function getDialogChildren(): Array<JSX.Element> {
        let children: Array<JSX.Element> = [];
        if (similarNames.length === 0) return children;

        children.push(<Typography>Já existem as seguintes unidades curriculares com nome similar:</Typography>);

        similarNames.forEach(s => {
            children.push(<Typography>{s.name}</Typography>)
        });

        return children;
    }

    function resetForm() {
        setName("");
        setSimilarNames([]);
    }

    function successCallback(subjectDTO: SubjectDTO) {
        resetForm();
        setOpenBackdrop(false);
        snackbar.enqueueSnackbar("Unidade curricular criada com sucesso.",
            {
                variant: "success",
                action: _ =>
                    <Button onClick={() => history.push(`/subjects/${subjectDTO.id}`)}>
                        Ver
                    </Button>
            })
    }

    function errorCallback(e: Response) {
        setOpenBackdrop(false);
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"});
    }

    function canSelectDepartment() {
        return appContext.me.value
            .roles?.includes(Roles.ROLE_SYSTEM_ADMIN);
    }

    return (
        <div>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>

                    <form className={classes.form}>
                        <TextField
                            autoComplete="off"
                            variant="outlined"
                            required
                            fullWidth
                            label="Nome"
                            value={name}
                            autoFocus
                            onChange={handleNameChange}
                            className={classes.textFieldSpaced}

                        />
                        {errorMessages["name"] === "" ? null :
                            <Typography className={classes.errorMessage}>{errorMessages["name"]}</Typography>}

                        {canSelectDepartment() ?
                            <TextField
                                select
                                fullWidth
                                onChange={e => setDepartmentId(parseInt(e.target.value, 10))}
                                SelectProps={{
                                    native: true,
                                }}
                                label="Departamento"
                                variant="outlined"
                                required
                                value={departmentId}
                                className={classes.textFieldSpaced}
                            >
                                <option/>
                                {
                                    availableDepartments.map(d =>
                                        <option key={"department" + d.id} value={d.id}>
                                            {d.name}
                                        </option>
                                    )
                                }
                            </TextField> : null
                        }

                        <Grid container spacing={1}>
                            <Grid item>
                                <BackButton/>
                            </Grid>
                            <Grid item>
                                <ButtonWithConfirmation
                                    dialogTitle={"Criar unidade curricular?"}
                                    children={getDialogChildren()}
                                    buttonText={"Criar"}
                                    submit={submit}
                                    successCallback={successCallback}
                                    errorCallback={errorCallback}
                                    beforeSubmit={checkSimilarNames}
                                />
                            </Grid>
                        </Grid>
                    </form>
                    <Backdrop className={classes.backdrop} open={openBackdrop}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                </div>
            )}
        </div>
    );
}
