import {VigilanceRequestExtendedDTO, VigilanceRequestsApi} from "../../typescript-fetch-client-generated";
import {useHistory} from "react-router";
import {useSnackbar} from "notistack";
import {config} from "../../configuration";
import React from "react";
import {canAccept, canSwap, getErrorMessage} from "../util/util";
import {Button, Grid} from "@material-ui/core";
import {CustomConfirmDialog} from "../util/ConfirmDialog";
import {ArrowForward, Check, Close} from "@material-ui/icons";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";


export default function VigilanceButtons(props: {
                                             vigilance: VigilanceRequestExtendedDTO,
                                             setOpenBackdrop: (b: boolean) => void
                                         }
) {

    const history = useHistory();
    const snackbar = useSnackbar();

    const vigilanceAPI: VigilanceRequestsApi = new VigilanceRequestsApi(config);

    const [confirmAcceptDialog, setConfirmAcceptDialog] = React.useState(false);
    const [canReject, setCanReject] = React.useState(false);

    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    React.useEffect(fetchData, []);

    function fetchData() {
        vigilanceAPI.canReject(props.vigilance.id!!).then(_ => {
            setCanReject(true);
        }).catch(_ => {
            setCanReject(false)
        });
    }

    function successCallback() {
        notifyUpdateContext.notify();
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    function rejectVigilance() {
        if (props.vigilance.replacementFor == null) {
            history.push(`/vigilances/${props.vigilance.id}/reject`);
        } else {
            props.setOpenBackdrop(true);
            vigilanceAPI.reject(props.vigilance.id!!)
                .then(_ => successCallback())
                .catch(errorCallback)
                .finally(() => props.setOpenBackdrop(false));
        }
    }

    function tradeVigilance() {
        history.push(`/vigilances/${props.vigilance.id}/swap`);
    }

    return (

        <div style={{flexGrow: 1}} onClick={(e) => {
            e.stopPropagation()
        }}>
            <Grid container justify={"center"} spacing={1}>

                <Grid item lg={4} md={4} xs={10}>
                    <Button
                        variant={"outlined"}
                        onClick={() => setConfirmAcceptDialog(true)}
                        style={{width: "100%", borderWidth: 2}}
                        disabled={!canAccept(props.vigilance)}
                        startIcon={<Check style={{marginBottom: "20%", color: "green"}}/>}>
                        Aceitar
                    </Button>
                </Grid>

                <Grid item lg={4} md={4} xs={10}>
                    <Button
                        variant={"outlined"}
                        disabled={!canReject}
                        onClick={rejectVigilance}
                        style={{width: "100%", borderWidth: 2}}
                        startIcon={<Close style={{marginBottom: "20%", color: "red"}}/>}>
                        Rejeitar
                    </Button>
                </Grid>

                <Grid item lg={4} md={4} xs={10}>
                    <Button
                        variant={"outlined"}
                        onClick={tradeVigilance}
                        disabled={!canSwap(props.vigilance)}
                        style={{width: "100%", borderWidth: 2}}
                        startIcon={<ArrowForward style={{marginBottom: "20%", color: "darkorange"}}/>}>
                        Transferir
                    </Button>
                </Grid>

            </Grid>
            <CustomConfirmDialog
                title={"Aceitar vigilância?"}
                onConfirm={() => vigilanceAPI.accept(props.vigilance.id!!)}
                open={confirmAcceptDialog}
                setOpen={setConfirmAcceptDialog}
                noText={"Cancelar"}
                yesText={"Aceitar"}
                successCallback={notifyUpdateContext.notify}
            />

        </div>
    );


}