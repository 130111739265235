import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    PenaltyJustificationDTO,
    VigilanceRequestExtendedDTO,
    VigilanceRequestsApi
} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import DatetimeComponent from "../util/DatetimeComponents";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Grid, TextField} from "@material-ui/core";
import {genericErrorCallback, getErrorMessage} from "../util/util";
import {useSnackbar} from "notistack";
import {PageContext} from "../contexts/PageContext";
import {BasicButton} from "../util/Buttons";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";
import GenericPrompt from "../util/GenericPrompt";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";

const useStyles = defaultStyles();

export default function RejectionDetailedCard(props: any) {
    const classes = useStyles();
    const snackbar = useSnackbar();
    const history = useHistory();

    const vigilanceAPI = new VigilanceRequestsApi(config);

    const vigilanceId = parseInt(props.match.params.vigilance_id);
    const [isLoading, setIsLoading] = React.useState(true);
    const [vigilance, setVigilance] = React.useState<VigilanceRequestExtendedDTO>({});
    const pageContext = React.useContext(PageContext) as Array<any>;
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);
    const [penaltyReason, setPenaltyReason] = React.useState("");

    React.useEffect(fetchData, [notifyUpdateContext.state])

    function fetchData() {
        vigilanceAPI.getOneRejectedExtended(vigilanceId).then(
            v => {
                setVigilance(v);
                setIsLoading(false);
            }
        ).catch(genericErrorCallback);
        pageContext[1]("Pedido de Rejeição");
    }

    function canAnswer() {
        console.log(vigilance.confirmed)
        return vigilance.confirmed !== null;
    }


    function acceptJustification() {
        vigilanceAPI.acceptJustification(vigilanceId)
            .then(_ => {
                successCallback();
            })
            .catch(errorCallback)
    }

    function rejectJustification() {
        let body: PenaltyJustificationDTO = {
            text: penaltyReason
        }
        return vigilanceAPI.rejectJustification(
            vigilanceId,
            body
        )
    }

    function delegatePenaltyDecision() {
        return vigilanceAPI.delegatePenaltyDecision(vigilanceId)
    }


    function successCallback() {
        notifyUpdateContext.notify();
        history.goBack()
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    function getDialogChildren() {
        return [
            <TextField
                autoFocus
                multiline

                style={{width: "100%"}}
                margin="dense"
                onChange={e => setPenaltyReason(e.target.value)}
                fullWidth
                key={1}
                rows={"5"}

            />
        ]
    }

    return (
        <div className={classes.paper}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>
                    {vigilance.delegated ?
                        <Typography component="p" className={classes.textMarginBottom}>
                            A decisão da penalização foi passada para o Presidente do Departamento
                        </Typography> : null
                    }
                    <TableContainer
                        component={Paper}>
                        <Table aria-label="simple table" size={"small"}>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Unidade Curricular</TableCell>
                                    <TableCell align="right">{vigilance.subject?.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Data</TableCell>
                                    <TableCell
                                        align="right">{DatetimeComponent(new Date(vigilance.evaluationDatetime!!))}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Duração</TableCell>
                                    <TableCell align="right">{vigilance.evaluationDuration} minutos</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Docente convocado</TableCell>
                                    <TableCell align="right">{vigilance.lecturer?.name}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Regentes</TableCell>
                                    <TableCell align="right">
                                        {vigilance.headLecturers!!.map(l =>
                                            <p key={l.id}>
                                                {`${l.name} (${l.email})`}
                                            </p>
                                        )}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Motivo</TableCell>
                                    <TableCell align="right">
                                        {vigilance.rejectionType?.description}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Justificação</TableCell>
                                    <TableCell align="right">{vigilance.justification}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Grid container item xs={12} style={{padding: "2%"}} justify={"space-evenly"}>
                        <Grid item xs={12}>
                            <BasicButton disabled={canAnswer()} onClick={acceptJustification}
                                         style={{color: "green"}}>
                                Não penalizar
                            </BasicButton>
                        </Grid>
                        <Grid item xs={12}>
                            <GenericPrompt
                                buttonText={"Penalizar"}
                                title={"Penalizar docente"}
                                description={"Pode incluir aqui a razão da penalização"}
                                submit={rejectJustification}
                                disabled={canAnswer()}
                                children={getDialogChildren()}
                                buttonStyle={{color: "red"}}
                                successCallback={successCallback}
                                confirmButtonText={"Penalizar"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <GenericPrompt
                                buttonText={"Passar ao Presidente do Dep."}
                                title={"Passar decisão ao Presidente do Dep.?"}
                                submit={delegatePenaltyDecision}
                                disabled={vigilance.delegated}
                                successCallback={successCallback}
                                children={[]}/>
                        </Grid>
                    </Grid>

                </div>
            )}
        </div>
    );
}
