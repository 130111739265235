import React from 'react';
import {EvaluationExtendedDTO, EvaluationsApi, YearSemesterDTO,} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import EvaluationCard from "./EvaluationCard";
import {Grid, Typography} from "@material-ui/core";
import {evaluationSorter, genericErrorCallback, Scopes} from "../util/util";
import PaginatedCardList from "../util/PaginatedCardList";
import {AppContext} from "../contexts/AppContext";
import CenteredCircularProgress from "../util/CenteredCircularProgress";

const useStyles = defaultStyles();
const evaluationsApi: EvaluationsApi = new EvaluationsApi(config);

export default function EvaluationList(props: { scope: number, yearSemester: YearSemesterDTO }) {
    const classes = useStyles();

    const [rows, setRows] = React.useState<Array<EvaluationExtendedDTO>>(new Array<EvaluationExtendedDTO>());
    React.useEffect(fetchRows, [props.scope, props.yearSemester]);
    const [isLoading, setIsLoading] = React.useState(true);
    const appContext = React.useContext(AppContext);

    function filterByDate(rows: Array<EvaluationExtendedDTO>) {
        return rows
            .filter(r => new Date(r.datetime!!) >= new Date())
            .sort(evaluationSorter);
    }

    function fetchRows() {
        switch (props.scope) {
            case Scopes.ME:
                evaluationsApi.getMyEvaluations(props.yearSemester.year, props.yearSemester.semester)
                    .then(r => {
                        setRows(filterByDate(r));
                        setIsLoading(false);
                    }).catch(genericErrorCallback);
                break;
            case Scopes.ALL:
                evaluationsApi.getAllEvaluationsExtended(
                    undefined,
                    props.yearSemester.year, props.yearSemester.semester
                ).then(r => {
                    setRows(filterByDate(r));
                    setIsLoading(false);
                }).catch(genericErrorCallback);
                break;
            case Scopes.DEPARTMENT:
                evaluationsApi.getAllEvaluationsExtended(
                    appContext.me.value.department?.id!!,
                    props.yearSemester.year, props.yearSemester.semester,
                ).then(r => {
                    setRows(filterByDate(r));
                    setIsLoading(false);
                }).catch(genericErrorCallback);

                break;
            default:
                break;
        }
    }

    return (
        <div className={classes.paddedCard}>
            {isLoading ? (
                <CenteredCircularProgress/>
            ) : (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography className={classes.cardListTitle}>Próximas avaliações</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {rows.length === 0 ? (
                                <Typography className={classes.centeredText}>Não tem avaliações marcadas.</Typography>
                            ) :
                            <PaginatedCardList
                                cards={rows?.map(evaluation => (
                                    <EvaluationCard evaluation={evaluation}/>))}/>
                        }
                    </Grid>

                </Grid>
            )}
        </div>
    );
}
