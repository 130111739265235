import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import {genericErrorCallback, getErrorMessage} from "../util/util";

import {
    AttendanceDTO,
    EvaluationsApi,
    VigilanceRequestExtendedDTO,
    VigilanceRequestsApi
} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from '../../styles';
import {Checkbox, Typography} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {ButtonWithConfirmation} from "../util/Buttons";


const useStyles = defaultStyles();

let vigilancesAPI: VigilanceRequestsApi = new VigilanceRequestsApi(config);
let evaluationsApi: EvaluationsApi = new EvaluationsApi(config);

export default function AttendanceComponent(props: { evaluationId: number | undefined }) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [rows, setRows] = React.useState<Array<VigilanceRequestExtendedDTO>>(new Array<VigilanceRequestExtendedDTO>());
    const [attendance, setAttendance] = React.useState(new Array<AttendanceDTO>());
    React.useEffect(fetchTableRows, [])
    const [isLoading, setIsLoading] = React.useState(true);


    function fetchTableRows() {
        vigilancesAPI.getAllVigilanceRequestsExtended(undefined, props.evaluationId).then(
            r => {
                const newRows = r.filter(v => v.dtype === "accepted" || v.dtype === "pending");
                setRows(newRows);
                setAttendance(newRows.map(nr => ({vigilanceRequestId: nr.id, present: true} as AttendanceDTO)))
                setIsLoading(false);
            }
        ).catch(genericErrorCallback);
    }

    function submit() {
        if (props.evaluationId) {
            window.location.reload();
            return evaluationsApi.setAttendance(props.evaluationId, attendance)
        }
        return Promise.reject();
    }

    function successCallback() {
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function errorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    function attendanceDialog(): Array<JSX.Element> {
        // Get selected lecturers
        const presentNames = rows.filter(row =>
            attendance
                .filter(att => att.present)
                .find(att => att.vigilanceRequestId === row.id)
        ).map(row => row.lecturer?.name);

        const notPresentNames = rows.filter(row =>
            attendance
                .filter(att => !att.present)
                .find(att => att.vigilanceRequestId === row.id)
        ).map(row => row.lecturer?.name);

        const arr = [];
        if (presentNames.length > 0) {
            arr.push(<Typography className={classes.tableHead}>Presentes:</Typography>);
            arr.push(...presentNames.map(name => <Typography>{name}</Typography>));
        }

        if (notPresentNames.length > 0) {
            arr.push(<Typography style={{marginTop: "5%"}} className={classes.tableHead}>Não presentes:</Typography>);
            arr.push(...notPresentNames.map(name => <Typography>{name}</Typography>));
        }

        return arr;
    }

    return (
        <div>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div className={classes.centeredWithMaxWidth}>
                    {(attendance.length > 0) ? (
                        <div>
                            <TableContainer
                                component={Paper}
                            >
                                <Table aria-label="simple table" size={"small"}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.tableHead}>Nome</TableCell>
                                            <TableCell className={classes.tableHead}>Presente</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows?.map(row => (
                                            <TableRow key={row.id}>
                                                <TableCell>{row.lecturer?.name}</TableCell>
                                                <TableCell>
                                                    <Checkbox
                                                        checked={attendance.find(element => element.vigilanceRequestId === row.id)!!.present}
                                                        onChange={() => {
                                                            let attendanceClone = [...attendance];
                                                            const i = attendanceClone.findIndex(element => element.vigilanceRequestId === row.id)
                                                            attendanceClone[i].present = !attendanceClone[i].present;
                                                            setAttendance(attendanceClone);
                                                        }}
                                                        inputProps={{'aria-label': 'primary checkbox'}}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <ButtonWithConfirmation
                                buttonText={"Submeter"}
                                dialogDescription={""}
                                successCallback={successCallback}
                                errorCallback={errorCallback}
                                dialogTitle={"Confirmar presenças?"}
                                submit={submit}>
                                {attendanceDialog()}
                            </ButtonWithConfirmation>
                        </div>


                    ) : (<Typography>Nenhuma presença por confirmar</Typography>)}
                </div>
            )}
        </div>
    );
}
