import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {defaultStyles} from "../../styles";
import {Button, Grid, Link} from "@material-ui/core";
import GoogleLogin from "react-google-login";
import DividerWithText from "./DividerWithText";
import {SubmitButton} from "../util/Buttons";
import {getFromQueryParams, GOOGLE_CLIENT_ID, GOOGLE_COOKIE_POLICY, QUERY_PARAM_FROM} from "../util/util";
import {useTheme} from "@material-ui/core/styles";

const useStyles = defaultStyles();


export default function SignIn() {

    const classes = useStyles();

    const [email, setEmail] = React.useState(String);
    const [password, setPassword] = React.useState(String);
    const [credentialsInvalid, setCredentialsInvalid] = React.useState(false);
    const [usePasswordLogin, setUsePasswordLogin] = React.useState(false);

    function loginSuccess() {
        setCredentialsInvalid(false);
        const from = getFromQueryParams(QUERY_PARAM_FROM);
        window.location.href = from ? from : "/";
    }

    function responseGoogle(response: any) {
        // Object { error: "popup_closed_by_user" }
        if (response.error) {
            return;
        }

        const data = {
            "email": "",
            "password": "",
            "token": response.tokenId
        }
        fetch('/api/login', {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(response => {
                switch (response.status) {
                    case 200:
                        loginSuccess();
                        break;
                    default:
                        setCredentialsInvalid(true);
                        break;
                }
            }
        ).catch(_ => {
            setCredentialsInvalid(true);
        });
    }

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        setCredentialsInvalid(false);
        const data = {
            "email": email,
            "password": password,
            "token": ""
        }

        fetch('/api/login', {
            method: 'POST',
            body: JSON.stringify(data),
        }).then(response => {
                switch (response.status) {
                    case 200:
                        loginSuccess();
                        break;
                    default:
                        setCredentialsInvalid(true);
                        break;
                }
            }
        ).catch(_ => {
            setCredentialsInvalid(true);
        });
    }

    return (
        <Container component="main" maxWidth={"sm"}>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Autenticação
                </Typography>

                <form className={classes.form}>
                    <GoogleLogin
                        isSignedIn={true}  // automatic sign in if possible
                        className={classes.OAuthButton}
                        clientId={GOOGLE_CLIENT_ID}
                        buttonText="Login com conta FCT"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        autoLoad={false}
                        cookiePolicy={GOOGLE_COOKIE_POLICY}
                        theme={useTheme().palette.type}
                    />
                </form>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <DividerWithText>ou</DividerWithText>

                    {usePasswordLogin ?
                        <div>
                            <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={e => setEmail(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="dense"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={e => setPassword(e.target.value)}
                            />

                            <SubmitButton buttonText={"Sign In"}/>

                            <Grid container>
                                <Grid item>
                                    <Link href="/resetPassword" variant="body2">
                                        {"Esqueceu-se da password?"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </div>


                        :

                        <Button className={classes.usePasswordLoginText} onClick={() => setUsePasswordLogin(true)}
                                fullWidth>
                            <Typography>Login local</Typography>
                        </Button>}

                    <Typography
                        className={classes.errorMessage}>{credentialsInvalid ? "Credenciais inválidas" : ""}
                    </Typography>


                </form>

            </div>
        </Container>
    );
}
