import React from 'react';
import {defaultStyles} from '../../styles';
import {Grid, Typography} from "@material-ui/core";

const useStyles = defaultStyles();

export default function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.footer}>
            <hr style={{marginTop: "5%", marginBottom: "1%"}}/>
            <Grid container justify={"space-evenly"}>


                {/* Center grid*/}
                <Grid item>

                    <Grid container className="centered">
                        <Grid item>
                            <Typography className={classes.text}>FCT-UNL</Typography>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Typography className={classes.text}>2020</Typography>*/}
                        {/*</Grid>*/}
                    </Grid>

                </Grid>


            </Grid>
        </div>


    );
}






































