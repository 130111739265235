import React from 'react';
import {UserExtendedDTO} from "../../typescript-fetch-client-generated";
import {DepartmentsScoreItem, MyEvaluationsItem, MySubjectsItem, MyVigilanceRequestsItem} from "./NavItems";

export default function NavLecturerItems(me: UserExtendedDTO) {

    return [
        <MySubjectsItem/>,
        <MyVigilanceRequestsItem/>,
        <MyEvaluationsItem/>,
        <DepartmentsScoreItem departmentId={me.department?.id!!}/>,
        // <MyDepartmentItem departmentId={me.department?.id!!}/>
    ];
}







































