import React from "react";
import {TextField} from "@material-ui/core";
import {
    LecturerDTO,
    LecturersApi,
    SimpleVigilanceRequestDTO,
    VigilanceRequestsApi
} from "../../typescript-fetch-client-generated";
import {config} from "../../configuration";
import GenericPrompt from "../util/GenericPrompt";
import {genericErrorCallback} from "../util/util";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";

const lecturersAPI = new LecturersApi(config);
const vigilancesAPI = new VigilanceRequestsApi(config);

export default function CreateVigilance(props: { evaluationId: number }) {
    const [selectedLecturerId, setSelectedLecturerId] = React.useState(-1);
    const [availableLecturers, setAvailableLecturers] = React.useState<Array<LecturerDTO>>([]);

    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    React.useEffect(fetchData, [props.evaluationId, notifyUpdateContext.state]);


    function fetchData() {
        lecturersAPI.getAvailableForEvaluationIncludeRejected(props.evaluationId).then(
            ls => {
                setAvailableLecturers(ls);
            }
        ).catch(genericErrorCallback)
    }

    const handleLecturerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        let n = parseInt(event.target.value);
        setSelectedLecturerId(n);
    }

    function submit(): Promise<SimpleVigilanceRequestDTO> {
        let v: SimpleVigilanceRequestDTO = {
            lecturer: selectedLecturerId,
            evaluation: props.evaluationId,
        }
        return vigilancesAPI.addNewVigilanceRequest(v)
    }

    function validate(): boolean {
        return selectedLecturerId !== undefined && selectedLecturerId >= 0;
    }

    function successCallback() {
        setSelectedLecturerId(-1);
        notifyUpdateContext.notify();
    }

    function getChildren() {
        return [
            <TextField
                select
                autoFocus
                onChange={handleLecturerChange}
                fullWidth
                margin="dense"
                key={1}
                SelectProps={{
                    native: true,
                }}
                variant="outlined"
            >
                <option/>
                {
                    availableLecturers.map(l =>
                        <option key={l.id} value={l.id}>
                            {l.name}
                        </option>
                    )
                }
            </TextField>
        ]
    }

    return (
        <div>
            <GenericPrompt
                buttonText={"Adicionar um vigilante"}
                title={"Adicionar vigilante"}
                description={"Selecione o docente"}
                submit={submit}
                children={getChildren()}
                validate={validate}
                successCallback={successCallback}/>
        </div>
    );
}