import React from "react";
import {Backdrop, Button, DialogContent, Grid, Typography} from "@material-ui/core";
import {defaultStyles} from "../../styles";
import {useHistory} from "react-router";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import {ButtonProps} from "@material-ui/core/Button/Button";
import {getErrorMessage} from "./util";
import {useSnackbar} from "notistack";

const useStyles = defaultStyles();

export function BasicButton(props: ButtonProps) {

    const classes = useStyles();

    return (
        <Button
            {...props}
            variant="contained"
            className={`${classes.baseButton} ${props.className}`}
        >
            {props.children}
        </Button>
    );
}


export function BackButton() {
    const history = useHistory();

    return (
        <BasicButton
            onClick={() => history.goBack()}
        >
            Voltar
        </BasicButton>
    );
}

export function SubmitButton(props: { buttonText?: string }) {

    return (
        <BasicButton
            type="submit"
        >
            {props.buttonText || "Submeter"}
        </BasicButton>
    );
}

export function BackSubmitButtons() {
    return (
        <Grid container direction={"row"} spacing={1}>
            <Grid item>
                <BackButton/>
            </Grid>
            <Grid item>
                <SubmitButton/>
            </Grid>
        </Grid>
    )
}


export function ButtonWithConfirmation(props: {
    dialogTitle: string, dialogDescription?: string, children?: Array<JSX.Element>, buttonText: string,
    submit: () => Promise<any>, successCallback?: Function, errorCallback?: Function, buttonClassName?: string,
    disabled?: boolean, beforeSubmit?: () => Promise<any>, onClose?: () => void
}) {
    const classes = useStyles();
    const snackbar = useSnackbar();

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [openBackdrop, setBackdropOpen] = React.useState(false);

    function handleClose() {
        setDialogOpen(false);
        setBackdropOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    }


    function defaultSuccessCallback() {
        snackbar.enqueueSnackbar("Operação efetuada com sucesso.", {variant: "success"})
    }

    function defaultErrorCallback(e: Response) {
        snackbar.enqueueSnackbar(getErrorMessage(e), {variant: "error"})
    }

    function handleConfirmAndClose() {
        setBackdropOpen(true);
        props.submit()
            .then(x => {
                if (props.successCallback) {
                    props.successCallback(x);
                } else {
                    defaultSuccessCallback();
                }
            })
            .catch(e => {
                if (props.errorCallback) {
                    props.errorCallback(e);
                } else {
                    defaultErrorCallback(e);
                }
            })
            .finally(() => {
                setBackdropOpen(false);
                setDialogOpen(false);
            })


    }

    function handleOnClick() {
        if (props.beforeSubmit) {
            props.beforeSubmit()
                .finally(() => setDialogOpen(true))
        } else {
            setDialogOpen(true)
        }
    }

    return (
        <>
            <BasicButton
                onClick={handleOnClick}
                variant={"contained"}
                disabled={props.disabled}
                className={props.buttonClassName}>
                {props.buttonText}
            </BasicButton>


            <Dialog
                open={dialogOpen}
                onClose={handleClose}
            >
                <DialogTitle>{props.dialogTitle}</DialogTitle>
                <DialogContent>
                    <Typography className={classes.text}>{props.dialogDescription}</Typography>
                    {props.children}
                </DialogContent>
                <DialogActions>
                    <BasicButton
                        onClick={handleClose}
                    >
                        Voltar
                    </BasicButton>
                    <BasicButton
                        onClick={handleConfirmAndClose}
                    >
                        Confirmar
                    </BasicButton>
                </DialogActions>
            </Dialog>

            <Backdrop open={openBackdrop}/>
        </>
    );
}
