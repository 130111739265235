import React from 'react';
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {EditionExtendedDTO, EditionsApi,} from "../../typescript-fetch-client-generated";
import {config} from '../../configuration';
import {defaultStyles} from "../../styles";
import EditionLecturersTable from "../users/EditionLecturersTable";
import {genericErrorCallback} from "../util/util";
import {NotifyUpdateContext} from "../contexts/NotifyUpdateContext";

const useStyles = defaultStyles();

export default function EditionDetails(props: { editionId: string }) {
    const classes = useStyles();

    const editionAPI = new EditionsApi(config);

    const [isLoading, setIsLoading] = React.useState(true);
    const [edition, setEdition] = React.useState<EditionExtendedDTO>({});
    const notifyUpdateContext = React.useContext(NotifyUpdateContext);

    React.useEffect(fetchData, [props.editionId, notifyUpdateContext.state])

    function fetchData() {
        editionAPI.getOneExtendedEdition(props.editionId).then(
            e => {
                setEdition(e);
                setIsLoading(false);
            }
        ).catch(genericErrorCallback)

    }

    return (
        <div className={classes.centered}>
            {isLoading ? (
                <CircularProgress/>
            ) : (
                <div>

                    <Typography component="h3" variant="h3" className={classes.tertiaryTitle}>
                        Docentes
                    </Typography>

                    {/* passing only edition does not work for some reason */}
                    <EditionLecturersTable edition={edition} lecturers={edition.lecturers!!}
                                           headLecturers={edition.headLecturers!!}/>

                </div>
            )}
        </div>
    );
}
